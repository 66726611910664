export function countSocietyShares(society) {
  if (!society) return society;
  const newSociety = { ...society };

  // Count shares per user
  const shares = society.shares.reduce((acc, operation) => {
    const {
      partner,
      from, to, phantomShareDate, stockOptionDate,
    } = operation;
    const isFuture = phantomShareDate || stockOptionDate;

    if (acc[partner.cif]) acc[partner.cif][isFuture ? 'future' : 'actual'] += (to - from + 1);
    else {
      acc[partner.cif] = {
        [isFuture ? 'future' : 'actual']: (to - from + 1),
        [isFuture ? 'actual' : 'future']: 0,
      };
    }

    return acc;
  }, {});

  // All society shares and futureShares
  const societyTotalShares = Object.values(shares).reduce((acc, { actual, future }) => {
    acc.actual += actual;
    acc.future += future;
    return acc;
  }, { actual: 0, future: 0 });
  newSociety.sharesCount = societyTotalShares;

  // Create partners array with shares
  const updatedPartners = society.partners.map((societyPartner) => {
    const newPartner = { ...societyPartner };
    newPartner.sharesCount = { actual: 0, future: 0 };
    Object.entries(shares).forEach(([partner, sharesCount]) => {
      if (partner === newPartner['cif']) newPartner.sharesCount = sharesCount;
    });

    return newPartner;
  });

  newSociety.partners = updatedPartners;
  return newSociety;
}

export function countUserSocietiesShares(user, isUserWithAccess) {
  if (!user) return user;
  const userToUpdate = { ...user };

  const administrated = user.societies.administrated.map(countSocietyShares);
  const participated = user.societies.participated.map(countSocietyShares);

  let usersWithAccess;
  if (!isUserWithAccess) {
    usersWithAccess = user.usersWithAccess.map(
      (society) => countUserSocietiesShares(society, true),
    );
  }

  userToUpdate.usersWithAccess = usersWithAccess;
  userToUpdate.societies.administrated = administrated;
  userToUpdate.societies.participated = participated;

  return userToUpdate;
}
