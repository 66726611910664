import { combineReducers } from 'redux';
import user from './userReducer';
import partnerBook from './partnerBookReducer';
import menu from './menuReducer';
import sideNav from './sideNavReducer';
import alerts from './alertReducer';
import document from './documentReducer';
import modal from './modalReducer';
import society from './societyReducer';

const rootReducer = combineReducers({
  user,
  partnerBook,
  menu,
  sideNav,
  alerts,
  document,
  modal,
  society,
});

export default rootReducer;
