/* eslint-disable no-case-declarations */
import { countUserSocietiesShares } from 'utils/getShareCount';
import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(user = initialState.user, action) {
  let result;

  switch (action.type) {
    case types.SET_USER:
      result = action.user;
      break;

    case types.CREATE_SOCIETY:
      const administrated = [...user.societies.administrated, action.society];
      const societies = { ...user.societies, administrated };
      result = { ...user, societies };
      break;

    case types.UPDATE_SOCIETY:
    case types.SET_SOCIETY:
      const administratedIndex = user.societies.administrated.findIndex((society) => society['_id'] === action.society['_id']);
      const participatedIndex = user.societies.participated.findIndex((society) => society['_id'] === action.society['_id']);

      const administratedUpdated = [...user.societies.administrated];
      if (administratedIndex !== -1) administratedUpdated[administratedIndex] = action.society;

      const participatedUpdated = [...user.societies.participated];
      if (participatedIndex !== -1) participatedUpdated[participatedIndex] = action.society;

      result = {
        ...user,
        societies: { participated: participatedUpdated, administrated: administratedUpdated },
      };

      break;
    default:
      result = null;
      break;
  }
  return result ? countUserSocietiesShares(result) : user;
}
