/* eslint-disable react/jsx-curly-newline */
import UserDropdown from 'components/Modals/UserDropdown';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety as updateSocietyAction } from 'redux/actions/societyActions';

import { yearNmonthDay } from 'utils/formatDate';
import adjustImageSize from 'utils/images';

import './Modal.scss';

function EditModal({ society, dispatch }) {
  const [page, setPage] = useState(false);

  const [socialDenomination, setSocialDenomination] = useState(society.name);
  const [CIF, setCIF] = useState(society.cif);
  const [web, setWeb] = useState(society.web);
  const [phone, setPhone] = useState(society.phone);
  const [logo, setLogo] = useState(society.additional.logo);
  const [mainAdmin, setMainAdmin] = useState(JSON.stringify(society.mainAdmin));
  const [description, setDescription] = useState(
    society.additional?.description
  );

  const [constitutionDate] = useState(
    yearNmonthDay(society.creationDate) || ''
  );

  const [line1, setLine1] = useState(society.legalAddress?.line1 || '');
  const [line2, setLine2] = useState(society.legalAddress?.line2 || '');
  const [zip, setZip] = useState(society.legalAddress?.zip || '');
  const [city, setCity] = useState(society.legalAddress?.city || '');
  const [state, setState] = useState(society.legalAddress?.state || '');
  const [country, setCountry] = useState(society.legalAddress?.country || '');

  async function fileSelected(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setLogo(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function updateSociety(event) {
    event.preventDefault();
    const newSocietyData = {
      name: socialDenomination,
      cif: CIF,
      web,
      phone,
      additional: {
        ...(logo && { logo }),
        ...(description && { description }),
        description,
      },
      ...(mainAdmin && { mainAdmin: JSON.parse(mainAdmin) }),
      legalAddress: {
        line1,
        line2,
        zip,
        city,
        state,
        country,
      },
    };

    dispatch(updateSocietyAction(society['_id'], newSocietyData));
    dispatch(setModal(null));
  }

  useEffect(() => {
    const resizedImage = adjustImageSize(
      'society-canvas',
      'society-image',
      200
    );
    setLogo(resizedImage);
  }, [logo]);

  return (
    <>
      <Modal.Header>
        <h5 className="title">Actualizar Sociedad</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <p
              className={`nav-link ${!page ? 'active' : ''}`}
              onClick={() => setPage(false)}
            >
              Sociedad
            </p>
          </li>
          <li className="nav-item">
            <p
              className={`nav-link ${page ? 'active' : ''}`}
              onClick={() => setPage(true)}
            >
              Dirección
            </p>
          </li>
        </ul>
        <div className="tab-content">
          <form onSubmit={updateSociety}>
            {!page ? (
              <div className={`tab-pane ${!page ? 'active' : ''}`}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="full-name">
                        Denominación Social
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        value={socialDenomination}
                        onChange={(event) =>
                          setSocialDenomination(event.target.value)
                        }
                        placeholder="Denominación Social"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="display-name">
                        CIF
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        value={CIF}
                        onChange={(event) => setCIF(event.target.value)}
                        placeholder="Enter display name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Web
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg date-picker"
                        value={web}
                        onChange={(event) => setWeb(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone-no">
                        Teléfono
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="phone-no"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Fecha Constitución
                      </label>
                      <input
                        type="date"
                        className="form-control form-control-lg date-picker"
                        value={constitutionDate}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-8">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Logo
                      </label>
                      <input
                        type="file"
                        className="form-control-file form-control-lg cursor-pointer"
                        accept="image/*"
                        onChange={fileSelected}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-4">
                    <canvas
                      className="bg-primary"
                      id="society-canvas"
                      width="200"
                      height="200"
                    >
                      {logo ? (
                        <img id="society-image" src={logo} alt={society.name} />
                      ) : (
                        <span>{society.name.slice(0, 2).toUpperCase()}</span>
                      )}
                    </canvas>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Administrador seleccionado
                      </label>
                      <UserDropdown
                        users={society.administrators.map((admin) => ({
                          id: admin,
                        }))}
                        action={setMainAdmin}
                        user={mainAdmin}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Descripción
                      </label>
                      <textarea
                        rows="2"
                        maxLength="150"
                        className="form-control form-control-lg date-picker"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`tab-pane ${page ? 'active' : ''}`}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l1">
                        Dirección línea 1
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-l1"
                        value={line1}
                        onChange={(event) => setLine1(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l2">
                        Dirección línea 2
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-l2"
                        value={line2}
                        onChange={(event) => setLine2(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        Codigo postal
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-st"
                        value={zip}
                        onChange={(event) => setZip(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        Ciudad
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-st"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        Pais
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-st"
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        Estado
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-st"
                        value={state}
                        onChange={(event) => setState(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-12">
                <button type="submit" className="btn btn-lg btn-primary">
                  Actualizar información
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-light ml-3"
                  onClick={() => dispatch(setModal(null))}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ societies }) {
  return { societies };
}

export default connect(mapStateToProps)(EditModal);
