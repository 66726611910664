import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import toggleSideNav from 'redux/actions/sideNavActions';

import '../../assets/styles/Layouts.scss';
import './SideNav.scss';

function MainMenu({ dispatch }) {
  return (
    <Accordion>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-layers" />
            </span>
            <span className="nk-menu-text">Participadas</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link to="/cuadro-participadas" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
              <span className="nk-menu-text">Cuadro Participadas </span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link to="/lista-participadas" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
              <span className="nk-menu-text">Lista Participadas</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link to="/kanban-participadas" className="nk-menu-link is-disable">
              <span className="nk-menu-text">Kanban Participadas</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-opt" />
            </span>
            <span className="nk-menu-text">Administradas</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link to="/cuadro-administradas" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
              <span className="nk-menu-text">Cuadro Administradas </span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link to="/lista-administradas" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
              <span className="nk-menu-text">Lista Administradas</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to="/kanban-administradas"
              className="nk-menu-link is-disable"
            >
              <span className="nk-menu-text">Kanban Administradas</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-exchange" />
            </span>
            <span className="nk-menu-text">Transacciones</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link to="/transacciones" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
              <span className="nk-menu-text">Listado Transacciones</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>
    </Accordion>
  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(MainMenu);
