import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(alerts = initialState.alerts, action) {
  switch (action.type) {
    case types.ADD_ALERT:
      return [...alerts, action.data];

    case types.REMOVE_ALERT:
      return [...alerts.slice(1, alerts.length)];

    default:
      return alerts;
  }
}
