import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMenu } from 'redux/actions/menuActions';

import menuTypes from 'constants/menuTypes';
import PartnerCard from 'components/PartnerCard';

import { getActualSociety } from 'utils/filters';

function PartnerFiles({ user, actualSociety, actions }) {
  const { societyId } = useParams();

  const [totalShares, setTotalShares] = useState();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setTotalShares(actualSociety.sharesCount?.actual + actualSociety.sharesCount?.future);
      setCards(actualSociety.partners
        .filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => (
          <PartnerCard
            partner={{ ...partner.id, cif: partner.cif, sharesCount: partner.sharesCount }}
            totalShares={totalShares}
            society={actualSociety}
            key={partner['_id']}
          />
        )));
    }
  }, [user, actions, actualSociety, societyId, totalShares]);

  useEffect(() => {
    actions.setMenu({
      type: menuTypes?.EXTENDED,
      societyId: actualSociety?.['_id'],
      societyName: actualSociety?.name,
    });
  }, [actions, actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">
            {actualSociety?.name}
          </h3>
          <p />
        </div>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Fichas de los socios
              </h3>
              <div className="nk-block-des text-soft">
                <p>{`${actualSociety?.name} tiene ${cards.length} socios`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-gs">{cards}</div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerFiles);
