import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(sideNav = initialState.sideNav, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_SIDE_NAV:
      return !sideNav;

    default:
      return sideNav;
  }
}
