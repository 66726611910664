import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { setMenu } from 'redux/actions/menuActions';

import TransactionsRow from 'components/TransactionsRow';
import Pagination from 'components/Pagination';
import { useParams } from 'react-router-dom';
import { getActualPartner, getActualSociety, getPartnerOperations } from 'utils/filters';
import { bindActionCreators } from 'redux';
import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';

function TransactionsList({ user, actualSociety, actions }) {
  const { societyId, partnerId } = useParams();

  const [operations, setOperations] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [indexOfLastItem] = useState(currentPage * itemsPerPage);
  const [indexOfFirstItem] = useState(indexOfLastItem - itemsPerPage);

  const [currentItems, setCurrentItems] = useState();

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (user && actualSociety) {
      let operations;
      if (!societyId) {
        const allSocieties = [...user.societies.participated];
        const allOperations = allSocieties.reduce(
          (acc, society) => [...acc, ...society.operations], [],
        );
        operations = allOperations.sort(
          (operationA, operationB) => new Date(operationB.date) - new Date(operationA.date),
        );
      } else if (!partnerId) {
        operations = actualSociety.operations;
      } else {
        const actualPartner = getActualPartner(actualSociety, partnerId);
        operations = getPartnerOperations(actualPartner, actualSociety);
      }
      const filteredOpeartions = operations.filter(
        (operation) => operation.operationType !== operationTypes.ADD_PARTNER,
      );
      setOperations(filteredOpeartions);
    }
  }, [actualSociety, user, societyId, partnerId]);

  useEffect(() => {
    if (actualSociety) {
      actions.setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      });
    }
  }, [actions, actualSociety]);

  useEffect(() => {
    if (operations) setCurrentItems(operations.slice(indexOfFirstItem, indexOfLastItem));
  }, [operations, setCurrentItems, indexOfFirstItem, indexOfLastItem]);

  const transactionsTable = (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Transacciones</h3>
              <div className="nk-block-des text-soft">
                <p>Detalle de las últimas operaciones.</p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <a className="btn btn-icon btn-trigger toggle-expand mr-n1">
                  <em className="icon ni ni-more-v" />
                </a>
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="drodown">
                        <a
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light is-disable"
                          data-toggle="dropdown"
                        >
                          <em className="d-none d-sm-inline icon ni ni-calender-date" />
                          <span>
                            <span className="d-none d-md-inline">Últimos</span>
                            {' '}
                            12 meses
                          </span>
                          <em className="dd-indc icon ni ni-chevron-right" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <span>Último 1 año</span>
                            </li>
                            <li>
                              <span>Últimos 6 meses</span>
                            </li>
                            <li>
                              <span>último 1 mes</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="row g-gs">
            <div className="col-12">
              <div className="card card-bordered card-full">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">
                        <span className="mr-2">Últimas Operaciones</span>
                      </h6>
                    </div>
                    <div className="card-tools">
                      <ul className="card-tools-nav">
                        <li>
                          <span>Adquisición</span>
                        </li>
                        <li>
                          <span>Venta</span>
                        </li>
                        <li className="active">
                          <span>Todas</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0 border-top">
                  <div className="nk-tb-list nk-tb-orders">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Nº. Ref.</span>
                      </div>
                      <div className="nk-tb-col tb-col-sm">
                        <span>{societyId && !partnerId ? 'Socio' : 'Sociedad'}</span>
                      </div>
                      <div className="nk-tb-col tb-col-md">
                        <span>Fecha</span>
                      </div>
                      <div className="nk-tb-col tb-col-lg">
                        <span>Nº Participaciones</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Importe</span>
                      </div>
                      <div className="nk-tb-col">
                        <span className="d-none d-sm-inline">Operación</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>&nbsp;</span>
                      </div>
                    </div>
                    {currentItems?.map((operation) => (
                      <TransactionsRow
                        transaction={operation}
                        isSociety={societyId && !partnerId}
                        key={operation['_id']}
                      />
                    ))}
                  </div>
                </div>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={operations?.length}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    operations?.length > 0 ? transactionsTable : (
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Transacciones</h3>
                <div className="nk-block-des text-soft">
                  <p>No tienes transacciones.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
