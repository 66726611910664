import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

import "../../assets/styles/Icons.scss";
import "../../assets/styles/Bootstrap.scss";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
  >
    {children}
    <span className="icon ni ni-more-h" />
  </div>
));
CustomToggle.displayName = "CustomToggle";

function MenuDots({ menuOptions, id = null, secondaryId }) {
  const menu = menuOptions.map((menuOption) => {
    const idUrl = id ? `/${id}` : "";

    return menuOption.action ? (
      <a
        key={menuOption.text}
        onClick={() => menuOption.action(secondaryId, id)}
      >
        <em className={`icon ni ${menuOption.icon}`} />
        <span>{menuOption.text}</span>
      </a>
    ) : (
      <Link to={`${menuOption.url}${idUrl}`} key={menuOption.text}>
        <em className={`icon ni ${menuOption.icon}`} />
        <span>{menuOption.text}</span>
      </Link>
    );
  });

  return (
    <Dropdown drop={menuOptions.direction}>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu size="sm" title="" className="link-list-opt no-bdr">
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default MenuDots;
