import React from 'react';
import { connect } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';

import SocietyAdministratorRow from './SocietyAdministratorRow';
import AddAdmin from './AddAdmin';

function SocietyAdministrator({ actualSociety, isAdmin }) {
  const administratorRows = actualSociety.administrators?.map((administrator, index) => (
    <SocietyAdministratorRow
      administrator={administrator}
      society={actualSociety}
      isAdmin={isAdmin}
      index={index}
      key={administrator['_id'] || ''}
    />
  ));

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">
            Usuarios administradores de la Sociedad
          </h5>
          <div className="nk-block-des">
            <p>
              Aquí puedes dar acceso a otros usuarios que también podrán
              administrar la Sociedad, añadir otros soicos, actualizar
              información, etc.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => store.dispatch(setModal(<AddAdmin societyId={actualSociety?.['_id']} />))}
              >
                Añadir usuarios
              </button>
            </div>
          </div>
        ) : <> </>}
        <div className="card card-bordered card-preview">
          <table className="table table-tranx">
            <thead>
              <tr className="tb-tnx-head">
                <th className="tb-tnx-id">
                  <span className="">#</span>
                </th>
                <th className="tb-tnx-info">
                  <span className="tb-tnx-desc d-none d-sm-inline-block">
                    <span>Nombre y apellidos</span>
                  </span>
                  <span className="tb-tnx-date d-md-inline-block d-none">
                    <span className="d-md-none">Fechas</span>
                    <span className="d-none d-md-block">
                      <span>Fecha alta</span>
                      <span>Último acceso</span>
                    </span>
                  </span>
                </th>
                <th className="tb-tnx-amount is-alt">
                  <span className="tb-tnx-total">email</span>
                  <span className="tb-tnx-status d-none d-md-inline-block">
                    Nivel de acceso
                  </span>
                </th>
                <th className="tb-tnx-action">
                  <span>&nbsp;</span>
                </th>
              </tr>
            </thead>
            <tbody>{administratorRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ society }) {
  return { actualSociety: society.actualSociety, isAdmin: society?.isAdmin };
}

export default connect(mapStateToProps)(SocietyAdministrator);
