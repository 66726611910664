import Slider from 'components/Slider';
import React from 'react';
import { Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import '../../assets/styles/Pages.scss';

function Register() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="nk-split nk-split-page nk-split-md">
      <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white w-lg-45">
        <div className="nk-block nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to="/" className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src="./images/logo-dark.png"
                srcSet="./images/logo-dark2x.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src="./images/logo-dark.png"
                srcSet="./images/logo-dark2x.png 2x"
                alt="logo-dark"
              />
            </Link>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Alta</h5>
              <div className="nk-block-des">
                <p>Crear cuenta en socio.legal</p>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block"
            onClick={loginWithRedirect}
          >
            Registrarse
          </button>
          <div className="form-note-s2 pt-4">
            ¿Ya eres usuario?
            {' '}
            <Link to="/login">
              <strong>Entra aquí</strong>
            </Link>
          </div>
        </div>
      </div>
      <Slider />
    </div>
  );
}

export default Register;
