import React from 'react';

function Support() {
  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head wide-md nk-block-head-lg">
          <div className="nk-block-head-sub"><span>Centro de ayuda y soporte</span></div>
          <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">Soporte</h2>
            <p>Consulta nuestra sección de ayuda y soportet.</p>
            <div className="nk-search-box">
              <div className="form-group">
                <div className="form-control-wrap">
                  <input type="text" className="form-control form-control-lg" placeholder="Search..." />
                  <button className="form-icon form-icon-right" type="button">
                    <em className="icon ni ni-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="support-topic-item card card-bordered">
            <a className="support-topic-block card-inner" href="html/soporte-listado.html">
              <div className="support-topic-media">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                  <path d="M63.42,87H10.58A4.08,4.08,0,0,1,6.5,82.92V7.08A4.08,4.08,0,0,1,10.58,3H48.32L67.5,21.23V82.92A4.08,4.08,0,0,1,63.42,87ZM47.5,3V16.92A4,4,0,0,0,51.38,21H66.5" fill="#fff" stroke="#6576ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                  <circle cx="63.5" cy="67" r="20" fill="#6576ff" />
                  <path d="M61.87,71a6.83,6.83,0,0,1,.39-2.55,6.71,6.71,0,0,1,1.51-2.09,11.82,11.82,0,0,0,1.44-1.61,2.92,2.92,0,0,0,.47-1.59,2.47,2.47,0,0,0-.55-1.72,2,2,0,0,0-1.58-.6,2.22,2.22,0,0,0-1.61.59A2,2,0,0,0,61.33,63H58.5a4.39,4.39,0,0,1,1.4-3.37,5.27,5.27,0,0,1,3.65-1.24,5.09,5.09,0,0,1,3.64,1.23,4.48,4.48,0,0,1,1.31,3.43,5.69,5.69,0,0,1-1.77,3.86L65.3,68.37A4.08,4.08,0,0,0,64.51,71Zm-.3,3.17A1.6,1.6,0,0,1,62,73,1.69,1.69,0,0,1,65,74.17a1.65,1.65,0,0,1-.44,1.17,1.67,1.67,0,0,1-1.26.46A1.62,1.62,0,0,1,62,75.34,1.65,1.65,0,0,1,61.57,74.17Z" fill="#fff" />
                  <circle cx="17" cy="21.5" r="4.5" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="20" x2="36.5" y2="20" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="24" x2="43.5" y2="24" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <circle cx="17" cy="39.5" r="4.5" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="37" x2="36.5" y2="37" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="42" x2="43.5" y2="42" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <circle cx="17" cy="56.5" r="4.5" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="54" x2="36.5" y2="54" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="28.5" y1="59" x2="43.5" y2="59" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />

                </svg>
              </div>
              <div className="support-topic-context">
                <h5 className="support-topic-title title">Información General</h5>
                <div className="support-topic-info">Aquí puedes consultar el funcionamiento general de legal.partner.</div>
                <div className="support-topic-count">Here are 7 questions and answers.</div>
              </div>
              <div className="support-topic-action">
                <em className="icon ni ni-chevron-right" />
              </div>
            </a>
          </div>
          <div className="support-topic-item card card-bordered">
            <a className="support-topic-block card-inner" href="html/soporte-listado.html">
              <div className="support-topic-media">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                  <rect x="3" y="12.5" width="64" height="63.37" rx="7" ry="7" fill="#fff" stroke="#6576ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <path d="M10,13.49H60a6,6,0,0,1,6,6v3.9a0,0,0,0,1,0,0H4a0,0,0,0,1,0,0v-3.9A6,6,0,0,1,10,13.49Z" fill="#e3e7fe" />
                  <rect x="3" y="23.39" width="64" height="1.98" fill="#6576ff" />
                  <path d="M65.37,31.31H76.81A12.24,12.24,0,0,0,87,42S88.12,66.31,65.37,77.5C42.62,66.31,43.75,42,43.75,42A12.23,12.23,0,0,0,53.93,31.31Z" fill="#fff" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <path d="M66,72.62c19-9.05,18.1-28.71,18.1-28.71s-7.47-.94-8.52-8.64H66Z" fill="#e3e7fe" />
                  <polygon points="56 46.16 55 46.16 55 42.2 59 42.2 59 43.2 56 43.2 56 46.16" fill="#010863" />
                  <polygon points="59 65.97 55 65.97 55 62.01 56 62.01 56 64.98 59 64.98 59 65.97" fill="#010863" />
                  <polygon points="78 65.97 74 65.97 74 64.98 77 64.98 77 62.01 78 62.01 78 65.97" fill="#010863" />
                  <polygon points="78 46.16 77 46.16 77 43.2 74 43.2 74 42.2 78 42.2 78 46.16" fill="#010863" />
                  <path d="M70,51.12H62V48.86a3.74,3.74,0,0,1,3.17-3.57c2.56-.46,4.83,1.28,4.83,3.49Zm-7-1h6V48.56a2.78,2.78,0,0,0-2-2.63,3,3,0,0,0-4,2.64Z" fill="#6576ff" />
                  <path d="M58,57.28V50.13H74V57.5c0,4.62-4.65,8.26-9.86,7.17A7.63,7.63,0,0,1,58,57.28Z" fill="#e5effe" />
                  <path d="M59,51.12v6.7A7,7,0,0,0,73,58V51.12Z" fill="#6576ff" />
                  <ellipse cx="66" cy="55.08" rx="2" ry="1.98" fill="#fff" />
                  <polygon points="68.91 62.01 63.84 62.01 65.18 56.07 67.57 56.07 68.91 62.01" fill="#fff" />
                  <path d="M72,51.12H60V48.66a5.41,5.41,0,0,1,4.06-5.14c4.13-1.14,7.94,1.54,7.94,5Zm-11-1H71V48.49A4.69,4.69,0,0,0,67.08,44c-3.23-.6-6.08,1.58-6.08,4.33Z" fill="#6576ff" />
                  <rect x="13" y="32.3" width="22" height="5.94" rx="1" ry="1" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <rect x="12" y="45.17" width="22" height="5.94" rx="1" ry="1" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />
                  <rect x="12" y="57.06" width="12" height="5.94" rx="1" ry="1" fill="none" stroke="#6576ff" strokeMiterlimit="10" strokeWidth="2" />

                </svg>
              </div>
              <div className="support-topic-context">
                <h5 className="support-topic-title title">Account Security Topics</h5>
                <div className="support-topic-info">You can find all of the questions and answers about secure your account.</div>
                <div className="support-topic-count">Here are 12 questions and answers.</div>
              </div>
              <div className="support-topic-action">
                <em className="icon ni ni-chevron-right" />
              </div>
            </a>
          </div>
          <div className="support-topic-item card card-bordered">
            <a className="support-topic-block card-inner" href="html/soporte-listado.html">
              <div className="support-topic-media">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                  <path d="M29,74H11a7,7,0,0,1-7-7V17a7,7,0,0,1,7-7H61a7,7,0,0,1,7,7V30" fill="#fff" stroke="#6576ff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <path d="M11,11H61a6,6,0,0,1,6,6v4a0,0,0,0,1,0,0H5a0,0,0,0,1,0,0V17A6,6,0,0,1,11,11Z" fill="#e3e7fe" />
                  <circle cx="11" cy="16" r="2" fill="#6576ff" />
                  <circle cx="17" cy="16" r="2" fill="#6576ff" />
                  <circle cx="23" cy="16" r="2" fill="#6576ff" />
                  <rect x="11" y="27" width="19" height="19" rx="1" ry="1" fill="#c4cefe" />
                  <path d="M33.8,53.79c.33-.43.16-.79-.39-.79H12a1,1,0,0,0-1,1V64a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V59.19a10.81,10.81,0,0,1,.23-2Z" fill="#c4cefe" />
                  <line x1="36" y1="29" x2="60" y2="29" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="36" y1="34" x2="55" y2="34" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="36" y1="39" x2="50" y2="39" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="36" y1="44" x2="46" y2="44" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <rect x="4" y="21" width="64" height="2" fill="#6576ff" />
                  <rect x="36" y="56" width="38" height="24" rx="5" ry="5" fill="#fff" stroke="#e3e7fe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <rect x="41" y="60" width="12" height="9" rx="1" ry="1" fill="#c4cefe" />
                  <path d="M84.74,53.51,66.48,35.25a4.31,4.31,0,0,0-6.09,0l-9.13,9.13a4.31,4.31,0,0,0,0,6.09L69.52,68.73a4.31,4.31,0,0,0,6.09,0l9.13-9.13A4.31,4.31,0,0,0,84.74,53.51Zm-15-5.89L67,50.3a2.15,2.15,0,0,1-3,0l-4.76-4.76a2.16,2.16,0,0,1,0-3l2.67-2.67a2.16,2.16,0,0,1,3,0l4.76,4.76A2.15,2.15,0,0,1,69.72,47.62Z" fill="#6576ff" />

                </svg>
              </div>
              <div className="support-topic-context">
                <h5 className="support-topic-title title">Payment Related Topics</h5>
                <div className="support-topic-info">You can find all of the questions and answers about online payment.</div>
                <div className="support-topic-count">Here are 16 questions and answers.</div>
              </div>
              <div className="support-topic-action">
                <em className="icon ni ni-chevron-right" />
              </div>
            </a>
          </div>
          <div className="support-topic-item card card-bordered">
            <a className="support-topic-block card-inner" href="html/soporte-listado.html">
              <div className="support-topic-media">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                  <path d="M62.92,87H10.08A4.08,4.08,0,0,1,6,82.92V7.08A4.08,4.08,0,0,1,10.08,3H47.82L67,21.23V82.92A4.08,4.08,0,0,1,62.92,87ZM47,3V16.92A4,4,0,0,0,50.88,21H66" fill="#fff" stroke="#6576ff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                  <line x1="14" y1="14" x2="28" y2="14" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="19" x2="36" y2="19" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="37" x2="28" y2="37" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="42" x2="45" y2="42" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="49" x2="40" y2="49" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="56" x2="32" y2="56" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="63" x2="38" y2="63" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <line x1="14" y1="70" x2="30" y2="70" fill="none" stroke="#c4cefe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <path d="M67,40h9a9.59,9.59,0,0,0,8,8.32s.89,19-17,27.68C49.12,67.28,50,48.32,50,48.32A9.59,9.59,0,0,0,58,40Z" fill="#6576ff" />
                  <path d="M67,72.51C52.12,65.35,52.86,49.82,52.86,49.82A7.94,7.94,0,0,0,59.52,43H67Z" fill="#fff" />
                  <path d="M62,61.77a11.05,11.05,0,0,1,12,0V65H62Z" fill="#fff" />
                  <circle cx="68.5" cy="54.5" r="3.5" fill="#fff" />
                  <path d="M68.47,58.13a4,4,0,1,1,4-4,4,4,0,0,1-4,4Zm0-6.92a3,3,0,1,0,3,3,3,3,0,0,0-3-3Zm0,0" fill="#6576ff" />
                  <path d="M74.5,66h-13a.51.51,0,0,1-.5-.5V62.1a1.46,1.46,0,0,1,.69-1.25,12.32,12.32,0,0,1,12.66,0A1.46,1.46,0,0,1,75,62.1v3.41a.49.49,0,0,1-.5.49ZM62,65H74V62.09a.47.47,0,0,0-.22-.41,11.28,11.28,0,0,0-11.56,0,.47.47,0,0,0-.22.41Zm.53,0" fill="#6576ff" />

                </svg>
              </div>
              <div className="support-topic-context">
                <h5 className="support-topic-title title">Terms & Policy Topics</h5>
                <div className="support-topic-info">You can find all of the questions and answers about Privacy Policy.</div>
                <div className="support-topic-count">Here are 5 questions and answers.</div>
              </div>
              <div className="support-topic-action">
                <em className="icon ni ni-chevron-right" />
              </div>
            </a>
          </div>
        </div>
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered border-primary">
            <div className="card-inner">
              <div className="nk-cta">
                <div className="nk-cta-block">
                  <div className="nk-cta-img">
                    <em className="icon icon-circle ni ni-msg" />
                  </div>
                  <div className="nk-cta-text">
                    <p>If you don’t find your question please contact our support team.</p>
                  </div>
                </div>
                <div className="nk-cta-action">
                  <a href="html/pages/contacto.html" className="btn btn-primary">Contact Us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Support;
