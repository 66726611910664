import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { setModal } from 'redux/actions/modalActions';

import { dayMonthYear } from '../../utils/formatDate';

import EditModal from './EditModal';

function UserMyData({ user, dispatch }) {
  const [displayAddress, setDisplayAddress] = useState('');
  const [displayBirthDate, setDisplayBirthDate] = useState('');

  useEffect(() => {
    const {
      line1, zip, city, country,
    } = user?.address || {};
    const addressText = () => (`${line1 || ''} ${zip || ''} ${city || ''} ${country ? `(${country})` : ''}`);

    setDisplayAddress(addressText);
    setDisplayBirthDate(dayMonthYear(user?.birthdate));
  }, [user]);

  return (
    <div className="card-inner card-inner-lg position-relative">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">Información personal</h4>
          <div className="nk-block-des">
            <p>
              El Real Decreto Legislativo 1/2010, de la Ley de Sociedades de
              Capital establece que en el libro de socios constará el nombre y
              la dirección del Socio. Los datos que indiques en este apartado
              serán los que constarán en tus Sociedades Participadas.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div
          className="nk-data data-list data-list-s2"
          onClick={() => dispatch(setModal(<EditModal user={user} />))}
        >
          <div className="data-head">
            <h6 className="overline-title">
              Tus datos: Estos datos serán visibles para el resto de socios
            </h6>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Nombre</span>
              <span className="data-value">{user.firstName}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Apellidos</span>
              <span className="data-value">{user.lastName || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{user.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#address"
          >
            <div className="data-col">
              <span className="data-label">Dirección completa</span>
              <span className="data-value">{displayAddress}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Nacionalidad</span>
              <span className="data-value">{user.nationality || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Imagen</span>
              <div className="user-avatar md bg-primary">
                <img src={user.image} alt="profile" />
              </div>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
        <div
          className="nk-data data-list data-list-s2"
          onClick={() => dispatch(setModal(<EditModal user={user} />))}
        >
          <div className="data-head">
            <h6 className="overline-title">
              Otros datos: NO visible para el resto de socios
            </h6>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Teléfono</span>
              <span className="data-value">{user.phone || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Fecha de nacimiento</span>
              <span className="data-value">{displayBirthDate || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ address }) {
  return { address };
}

export default connect(mapStateToProps)(UserMyData);
