/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PDFDownloadLink } from '@react-pdf/renderer';

import ParticipationsCertificate from 'components/ParticipationsCertificate';

import { getActualPartner, getActualSociety } from 'utils/filters';
import { dayMonthYear } from 'utils/formatDate';

import { setModal } from 'redux/actions/modalActions';
import { setMenu } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import AnnualValueModal from './annualValueModal';

import './SocietyAnnualValue.scss';

function SocietyAnnualValue({ user, actualSociety, actions }) {
  const { societyId } = useParams();

  const [rows, setRows] = useState([]);

  const [actualAnnualValues, setActualAnnualValues] = useState([]);

  const [lastYear] = useState(new Date().getFullYear() - 1);

  function calculateCertificate(annualValue) {
    const actualPartner = getActualPartner(actualSociety, user['_id']);
    const fullName = `${actualPartner?.id?.firstName} ${
      actualPartner?.id?.lastName || ''
    }`;
    const NDpercent = (
      (actualPartner?.sharesCount?.actual * 100) /
      actualSociety.sharesCount?.actual
    ).toFixed(2);
    const actualCertificateData = {
      userFullName: fullName.trim(),
      userShares: actualPartner.sharesCount?.actual,
      societyName: actualSociety?.name,
      societyCIF: actualSociety?.cif,
      societyAdmin: `${actualSociety?.administrators[0].firstName} ${actualSociety.administrators[0].lastName}`,
      capitalPercent: NDpercent,
      nominalValueShares: actualSociety?.nominalValue,
      totalNominalValueShares: (
        actualSociety?.nominalValue * actualPartner?.sharesCount?.actual
      ).toFixed(2),
      totalValueShares: (
        annualValue?.contableValue *
        (NDpercent / 100)
      ).toFixed(2),
      totalActive: annualValue?.contableValue,
      lastYear: annualValue?.year,
      downloadDate: new Date(),
    };
    return actualCertificateData;
  }

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    const sortedAnnualValues = actualSociety?.annualValues.sort(
      (annualA, annualB) => annualA.year - annualB.year
    );

    setActualAnnualValues(sortedAnnualValues);
  }, [actualSociety]);

  useEffect(() => {
    setRows(
      actualSociety?.annualValues?.map((annualValue) => (
        <tr className="tb-ticket-item is-unread" key={annualValue?.['_id']}>
          <td className="tb-ticket-id">{annualValue?.year}</td>
          <td className="tb-ticket-desc">
            <span className="title">{annualValue?.contableValue}</span>
          </td>
          <td className="tb-ticket-desc">
            <span className="title">{annualValue?.billing}€</span>
          </td>
          <td className="tb-ticket-desc">
            <span className="title">{annualValue?.ebitda}€</span>
          </td>
          <td className="tb-ticket-date tb-col-md">
            <span className="date">
              {dayMonthYear(annualValue?.modifiedDate)}
            </span>
          </td>
          <td className="tb-ticket-seen tb-col-md">
            {+annualValue?.year === lastYear ? (
              <PDFDownloadLink
                document={
                  <ParticipationsCertificate
                    certificateData={calculateCertificate(annualValue)}
                  />
                }
                fileName="Certificado Valor Participaciones.pdf"
              >
                {({ loading }) =>
                  loading ? (
                    <span className="btn btn-sm btn-block btn-primary">
                      Generando...
                    </span>
                  ) : (
                    <span className="btn btn-sm btn-block btn-primary">
                      <em className="icon ni ni-reports-alt mr-1" />
                      Certificado
                    </span>
                  )
                }
              </PDFDownloadLink>
            ) : (
              <Link
                to="/contacto"
                className="btn btn-sm btn-block btn-outline-primary"
              >
                <em className="icon ni ni-external mr-1" />
                Contáctanos
              </Link>
            )}
          </td>
        </tr>
      ))
    );
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      actions.setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety['_id'],
        societyName: actualSociety?.name,
      });
    }
  }, [actions, actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Valor Anual</h3>
              <div className="nk-block-des text-soft">
                <p>Detalle del valor anual de la sociedad</p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  actions.setModal(
                    <AnnualValueModal
                      userId={user['_id']}
                      societyId={actualSociety['_id']}
                      actualAnnualValues={actualAnnualValues}
                    />
                  )
                }
              >
                Añadir valor anual
              </button>
            </div>
          </div>
        </div>
        {rows?.length > 0 ? (
          <div className="nk-block">
            <div className="card card-bordered">
              <table className="table table-tickets">
                <thead className="tb-ticket-head">
                  <tr className="tb-ticket-title">
                    <th className="tb-ticket-id">
                      <span>Año</span>
                    </th>
                    <th className="tb-ticket-desc">
                      <span>Valor contable</span>
                    </th>
                    <th className="tb-ticket-desc">
                      <span>Facturación</span>
                    </th>
                    <th className="tb-ticket-desc">
                      <span>EBITDA</span>
                    </th>
                    <th className="tb-ticket-date tb-col-md">
                      <span>Modificado</span>
                    </th>
                    <th className="tb-ticket-seen tb-col-md">
                      <span>Certificado</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="tb-ticket-body">{rows}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu, setModal }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietyAnnualValue);
