import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { countOperationShares, getActualPartner } from 'utils/filters';
import { dayMonYea } from 'utils/formatDate';

import operationTypesRealNames from 'constants/operationTypesRealNames';
import operationTypes from 'constants/operationTypes';
import MenuDots from '../MenuDots';
import menuOptions from './menuOptions';

import '../../assets/styles/Components.scss';

function TransactionsRow({ transaction, isSociety, user }) {
  const [displayDate] = useState(dayMonYea(transaction.date));

  const [participations, setParticipations] = useState();
  const [amount, setAmount] = useState();

  const [logo, setLogo] = useState();
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    const societyId = transaction?.society;
    const allSocieties = [...user.societies.participated, ...user.societies.administrated];
    const actualSociety = allSocieties.find((society) => society['_id'] === societyId);

    if (!isSociety) {
      setLogo(actualSociety?.additional.logo);
      setName(actualSociety?.name);
      setLink(`/socios/${actualSociety?.['_id']}`);
    } else {
      const { id: actualPartner } = getActualPartner(actualSociety, transaction?.partner);
      if (actualPartner) {
        setLogo(actualPartner?.image);
        setName(`${actualPartner?.firstName} ${(actualPartner?.lastName || '')}`);
        setLink(`/detalle-socio/${actualSociety['_id']}/${actualPartner['_id']}`);
      }
    }

    const totalShares = countOperationShares(transaction);
    if (transaction.sharePremium) {
      setParticipations(totalShares);
      setAmount(`${totalShares * transaction.sharePremium}${transaction.currency}`);
    } else if (transaction.operationType === operationTypes.CAPITAL_DECREASE) {
      setParticipations(totalShares);
      setAmount('-');
    } else {
      setParticipations('-');
      setAmount('-');
    }
  }, [transaction, user, isSociety]);

  return (
    <div className="nk-tb-item">
      <div className="nk-tb-col">
        {
          transaction.operationType !== operationTypes.ADD_PARTNER ? (
            <Link to={`/detalle-transacciones/${transaction.society}/${transaction['_id']}`} className="tb-lead text-primary">
              {`#${transaction['_id'].slice(0, 6)}...`}
            </Link>
          ) : (
            <a className="tb-lead text-primary">{`#${transaction['_id'].slice(0, 6)}...`}</a>
          )
        }
      </div>
      <div className="nk-tb-col tb-col-sm">
        <Link to={link}>
          <div className="user-card">
            <div className="user-avatar user-avatar-sm bg-warning">
              {logo ? (<img src={logo} alt={name} className="rounded-circle" />) : (
                <span>{name.slice(0, 2).toUpperCase()}</span>
              )}
            </div>
            <div className="user-name">
              <span className="tb-lead">{name}</span>
            </div>
          </div>
        </Link>
      </div>
      <div className="nk-tb-col tb-col-md">
        <span className="tb-sub">{displayDate}</span>
      </div>
      <div className="nk-tb-col tb-col-lg">
        <span className="tb-sub text-primary">
          {participations}
        </span>
      </div>
      <div className="nk-tb-col">
        <span className="tb-sub tb-amount">
          {amount}
        </span>
      </div>
      <div className="nk-tb-col">
        <span className="badge badge-dot badge-dot-xs badge-success">
          {operationTypesRealNames[transaction.operationType]}
        </span>
      </div>
      <div className="nk-tb-col nk-tb-col-action">
        <div className="dropdown">
          <MenuDots
            menuOptions={menuOptions}
            id={transaction.society}
            direction="left"
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(TransactionsRow);
