/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import Header from 'components/Header';
import Footer from 'components/Footer';
import SideNav from 'components/SideNav';

import Dashboard from 'pages/Dashboard';
import ParticipatedTable from 'pages/ParticipatedTable';
import ParticipatedList from 'pages/ParticipatedList';
import AdministratedTable from 'pages/AdministratedTable';
import AdministratedList from 'pages/AdministratedList';
import TransactionsList from 'pages/TransactionsList';
import SocietiesAdd from 'pages/SocietiesAdd';
import PartnerFiles from 'pages/PartnerFiles';
import PartnerList from 'pages/PartnerList';
import PartnerBook from 'pages/PartnerBook';
import PartnerDetail from 'pages/PartnerDetail';
import UserProfile from 'pages/UserProfile';
import SocietyProfile from 'pages/SocietyProfile';
import SocietyDocuments from 'pages/SocietyDocuments';
import SocietyAnnualValue from 'pages/SocietyAnnualValue';
import LegalWarning from 'pages/LegalWarning';
import PolicyPrivacy from 'pages/PrivacyPolicy';
import TransactionDetails from 'pages/TransactionDetails';
import Contact from 'pages/Contact';
import Support from 'pages/Support';
import Register from 'pages/Register';
import Login from 'pages/Login';

import LegalAlert from 'components/Alert';
import { findUser, getUser } from 'redux/actions/userActions';

import './App.scss';
import './assets/styles/Icons.scss';
import './assets/styles/Fonts.scss';
import './assets/styles/Bootstrap.scss';
import './assets/styles/Layouts.scss';
import './assets/styles/Components.scss';
import './assets/styles/Misc.scss';
import './assets/styles/Styles.scss';

function LegalRoute({ path, component, user, isLoading }) {
  const history = useHistory();
  useEffect(() => {
    if (!isLoading && !localStorage.token && !user) history.push('/login');
  });

  return user ? (
    <div className="nk-wrap">
      <Header />
      <div className="nk-content ">
        <div className="container wide-xl d-flex">
          <SideNav className="container__nav" />
          <div className="container__main">
            <Route path={path} component={component} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function App({ alerts, currentAlert, user, dispatch, statemodal }) {
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();

  const [modal, setModal] = useState(false);

  if (!user && localStorage.token && !currentAlert) {
    dispatch(getUser(localStorage.token));
  } else if (isAuthenticated && auth0User && !user) {
    dispatch(findUser(auth0User.email));
  }

  useEffect(() => setModal(statemodal), [statemodal]);

  return (
    <>
      {!!alerts.length && <LegalAlert {...currentAlert} />}
      <Router>
        <div className="nk-body bg-white npc-default has-aside no-touch nk-nio-theme">
          <div className="nk-app-root">
            {modal ? (
              <Modal
                className="position-absolute"
                size="md"
                show
                onHide={() => setModal(null)}
              >
                {modal}
              </Modal>
            ) : (
              <> </>
            )}
            <div className="nk-main ">
              <Switch>
                <LegalRoute
                  path="/"
                  exact
                  component={Dashboard}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/cuadro-participadas"
                  component={ParticipatedTable}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/lista-participadas"
                  component={ParticipatedList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/cuadro-administradas"
                  component={AdministratedTable}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/lista-administradas"
                  component={AdministratedList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/transacciones/:societyId/:partnerId"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/transacciones/:societyId/"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/transacciones/"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/detalle-transacciones/:societyId/:transactionId"
                  component={TransactionDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/nueva-sociedad"
                  component={SocietiesAdd}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/socios/:societyId"
                  component={PartnerList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/socios/:societyId"
                  component={PartnerList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/socios-fichas/:societyId"
                  component={PartnerFiles}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/socios-libro/:societyId"
                  component={PartnerBook}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/detalle-socio/:societyId/:partnerId"
                  component={PartnerDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/perfil-usuario/:activeTab"
                  component={UserProfile}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/perfil-sociedad/:pageType/:societyId"
                  component={SocietyProfile}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/documentos-sociedad/:societyId"
                  component={SocietyDocuments}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/valor-anual-sociedad/:societyId"
                  component={SocietyAnnualValue}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/aviso-legal"
                  component={LegalWarning}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/privacidad"
                  component={PolicyPrivacy}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/contacto"
                  component={Contact}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  path="/ayuda-soporte"
                  component={Support}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
}

function mapStateToProps({ alerts, user, modal }) {
  const currentAlert = alerts[0];
  return {
    alerts,
    currentAlert,
    user,
    statemodal: modal,
  };
}

export default connect(mapStateToProps)(App);
