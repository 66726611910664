import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import SocietyProfileData from "components/SocietyProfileData";
import SocietySubscription from "components/SocietySubscription";
import SocietyAdministrators from "components/SocietyAdministrator";
import { setMenu } from "redux/actions/menuActions";
import { bindActionCreators } from "redux";
import menuTypes from "constants/menuTypes";
import { getActualSociety } from "utils/filters";

const pageTypes = {
  datos: 0,
  suscripcion: 1,
  administradores: 2,
};

function SocietyProfile({ user, actualSociety, actions }) {
  const { societyId, pageType } = useParams();

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState();

  const [isModalOpened, setisModelOpened] = useState(false);

  useEffect(() => {
    if (!actualSociety) getActualSociety(user, societyId);
    else {
      const page = pageTypes[pageType];
      setPage(page || 0);
      setPages([
        <SocietyProfileData
          isOpen={page === undefined && !isModalOpened}
          key="SocietyProfileData"
        />,
        <SocietySubscription key="SocietySubscription" />,
        <SocietyAdministrators key="SocietyAdministrators" />,
      ]);
      setisModelOpened(true);
    }
  }, [actualSociety, isModalOpened, pageType, societyId, user]);

  useEffect(() => {
    actions.setMenu({
      type: menuTypes.EXTENDED,
      societyName: actualSociety?.name,
      societyId: actualSociety?.["_id"],
    });
  }, [actions, actualSociety]);

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <div className="nk-block-head-sub">
              <span>Configuración de la Sociedad</span>
            </div>
            <h2 className="nk-block-title fw-normal">{actualSociety?.name}</h2>
            <div className="nk-block-des">
              <p>
                Desde esta sección controlas los datos de la sociedad.
                <span className="text-primary">
                  <em className="icon ni ni-info" />
                </span>
              </p>
            </div>
          </div>
        </div>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <p
              className={`nav-link ${page === 0 ? "active" : ""}`}
              onClick={() => setPage(0)}
            >
              Datos
            </p>
          </li>
          <li className="nav-item">
            <p
              className={`nav-link ${page === 1 ? "active" : ""} is-disable`}
              onClick={() => setPage(1)}
            >
              Suscripción
            </p>
          </li>
          <li className="nav-item">
            <p
              className={`nav-link ${page === 2 ? "active" : ""}`}
              onClick={() => setPage(2)}
            >
              Administradores
            </p>
          </li>
        </ul>
        {pages ? pages[page] : ""}
      </div>
    </div>
  ) : (
    <p>Error al cargar la Sociedad</p>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietyProfile);
