import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { getActualPartner } from 'utils/filters';
import MenuDots from 'components/MenuDots';
import UsersArray from '../UsersArray';
import Badge from '../Badge';

import menuOptions from '../SocietyRow/menuOptions';

import '../../assets/styles/Components.scss';
import './SocietyCard.scss';

function SocietyCard({
  society,
  userId,
  isAdmin,
  alternativeMenuOptions,
}) {
  const [partner] = useState(getActualPartner(society, userId));

  const [percent] = useState(((partner?.sharesCount?.actual * 100)
    / (society.sharesCount?.actual + society.sharesCount?.future)).toFixed(0));

  return society && (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered h-100">
        <div className="card-inner">
          <div className="project">
            <div className="project-head">
              <Link to={`/socios/${society['_id']}`} className="project-title">
                <div className="user-avatar md bg-primary">
                  {society.additional.logo
                    ? (<img src={society.additional.logo} alt={society.name} />) : (
                      <span>{society.name.slice(0, 2).toUpperCase()}</span>
                    )}
                </div>
                <div className="project-info">
                  <h6 className="title">{society.name}</h6>
                  <span className="sub-text">{isAdmin ? 'Administrador' : 'Socio'}</span>
                </div>
              </Link>
              <div>
                <MenuDots
                  menuOptions={alternativeMenuOptions || menuOptions}
                  id={society['_id']}
                  direction="left"
                />
              </div>
            </div>
            <div className="project-details">
              <p>{society.additional.description}</p>
            </div>
            <div className="project-progress">
              <div className="project-progress-details">
                <div className="project-progress-task">
                  <em className="icon ni ni-layers" />
                  <span>
                    {`${society.sharesCount?.actual || '0'} participaciones`}
                  </span>
                </div>
                <div className="project-progress-percent">
                  {`${percent > 0 ? percent : '0'}%`}
                </div>
              </div>
              <div className="progress progress-pill progress-md bg-light">
                <div
                  className="progress-bar"
                  data-progress={percent}
                  style={{ width: `${percent}%` }}
                />
              </div>
            </div>

            {isAdmin && (
              <div className="project-progress-percent mb-1">
                <a>
                  <em className="icon ni ni-users mr-1" />
                  {`Tiene ${society.partners.length} socios`}
                </a>
              </div>
            )}
            <div className="project-meta">
              <UsersArray users={society.partners} />
              {society.creationDate
                ? <Badge creationDate={new Date(society.creationDate)} /> : <> </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyCard;
