import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import SocietyCard from '../../components/SocietyCard';

function AdministratedTable({ user, actions }) {
  const [societies, setSocieties] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => actions.setMenuType(menuTypes.MAIN), [actions]);

  useEffect(() => {
    if (user) {
      const sharedSocieties = user.usersWithAccess.reduce((acc, user) => {
        const { administrated } = user.societies;

        const deleteDuplicated = administrated.filter((societyA) => !acc.some((societyB) => societyA['_id'] === societyB['_id']));

        return [...acc, ...deleteDuplicated];
      }, [...user.societies.administrated]);

      setSocieties(sharedSocieties);
      setCards(sharedSocieties.map((society) => {
        const isAdmin = society.partners.find((society) => society['_id'] === user['_id']);
        return (
          <SocietyCard
            society={society}
            userId={user['_id']}
            isAdmin={!isAdmin}
            key={society['_id']}
          />
        );
      }));
    }
  }, [user]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Sociedades Administradas
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {societies.length === 0
                    ? 'No tienes sociedades administradas'
                    : `Tienes ${societies.length} sociedad(es) administrada(s)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="cuadro-participadas__main d-flex flex-wrap g-gs">
          {cards}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, societies }) {
  return { user, societies };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratedTable);
