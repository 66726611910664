import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPartnerInversion } from 'utils/filters';

import MenuDots from '../MenuDots';

import menuOptions from './menuOptions';

function PartnerCard({ partner, totalShares, society }) {
  const [partnerTotalShares, setPartnerTotalShares] = useState();
  const [percent, setPercent] = useState();

  useEffect(() => {
    if (partner) {
      const partnerShares = partner.sharesCount?.actual + partner.sharesCount?.future;
      setPartnerTotalShares(partnerShares);
      setPercent(((partnerShares * 100) / totalShares).toFixed(2));
    }
  }, [totalShares, partner, partnerTotalShares]);

  menuOptions[0].url = `/detalle-socio/${society?.['_id']}`;
  menuOptions[1].url = `/detalle-socio/${society?.['_id']}`;

  return (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="team">
            <MenuDots menuOptions={menuOptions} id={partner['_id']} secondaryId={partner['_id']} />
            <div className="user-card user-card-s2">
              <div className="user-avatar md bg-primary">
                <img
                  src={partner.image}
                  alt={`${partner.firstName} ${partner.lastName || ''}`}
                />
              </div>
              <div className="user-info">
                <h6>{`${partner.firstName} ${partner.lastName || ''}`}</h6>
                <span className="sub-text">SOCIO</span>
              </div>
            </div>
            <div className="team-details">
              <p>{partner.description}</p>
            </div>
            <ul className="team-statistics">
              <li>
                <span>{partnerTotalShares || 0}</span>
                <span>Nº Part.</span>
              </li>
              <li>
                <span>
                  {`${percent > 0 ? percent : 0}%`}
                </span>
                <span>%</span>
              </li>
              <li>
                <span>
                  {`${getPartnerInversion(partner.shares, society)}€`}
                </span>
                <span>Inversión</span>
              </li>
            </ul>
            <div className="team-view">
              <Link
                to={`/detalle-socio/${society?.['_id']}/${partner?.['_id']}`}
                className="btn btn-round btn-outline-light w-150px"
              >
                <span>Ver perfil</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerCard;
