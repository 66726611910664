import React from 'react';
import { Link } from 'react-router-dom';

function SocietiesRepresentedRow({ user }) {
  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col">
        <Link to={`/socios/${user.society?.['_id']}`} className="project-title">
          <div className="user-avatar bg-light">
            {user.society?.logo ? (
              <img src={user.society?.logo} alt={user.society?.name} />
            ) : (
              <span>{user.society?.name.slice(0, 2).toUpperCase()}</span>
            )}
          </div>
          <div className="project-info">
            <h6 className="title">{user.society?.name}</h6>
          </div>
        </Link>
      </td>
      <td className="nk-tb-col">
        <span className="">
          {!user.isAdmin
            ? user.partner.cif || ''
            : user.administrator.cif || ''}
        </span>
      </td>
      <td className="nk-tb-col">
        <span className="">
          {!user.isAdmin
            ? `${user.partner?.id.firstName} ${user.partner?.id.lastName || ''}`
            : `${user.administrator?.firstName} ${
                user.administrator?.lastName || ''
              }`}
        </span>
      </td>
      <td className="nk-tb-col">
        <span className="project-progress-percent">
          {!user.isAdmin ? user.partner?.id.email : user.administrator?.email}
        </span>
      </td>
    </tr>
  );
}

export default SocietiesRepresentedRow;
