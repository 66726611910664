import AddPartner from 'components/Modals/AddPartner';
import Constitution from 'components/Modals/Constitution';
import CapitalIncrease from 'components/Modals/CapitalIncrease';
import CapitalDecrease from 'components/Modals/CapitalDecrease';
import SellParticipations from 'components/Modals/SellParticipations';
import StockOptions from 'components/Modals/StockOption';
import PhantomShares from 'components/Modals/PhantomShares';

export default [
  {
    icon: 'ni-info',
    text: 'Nuevo Socio',
    url: AddPartner,
  },
  {
    icon: 'ni-book-read',
    text: 'Constitución',
    url: Constitution,
  },
  {
    icon: 'ni-book-read',
    text: 'Ampliación de capital',
    url: CapitalIncrease,
  },
  {
    icon: 'ni-book-read',
    text: 'Reducción de capital',
    url: CapitalDecrease,
  },
  {
    icon: 'ni-book-read',
    text: 'Venta de participaciones',
    url: SellParticipations,
  },
  {
    icon: 'ni-book-read',
    text: 'Stock options',
    url: StockOptions,
  },
  {
    icon: 'ni-book-read',
    text: 'Phantom Shares',
    url: PhantomShares,
  },
];
