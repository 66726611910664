import axios from 'axios';

import server from 'constants/server';
import types from 'redux/actions/actionTypes';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from './alertActions';

export function setDocument(document) {
  return { type: types.SET_DOCUMENT, document };
}

export function addDocument(newDocumentData) {
  return async (dispatch) => {
    try {
      const { file, ...documentData } = newDocumentData;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('data', JSON.stringify(documentData));

        const { data } = await axios.post(`${server.URL}/documents`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });

        return data;
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_CREATING_DOCUMENT']));
    }
  };
}

export const getDocument = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${server.URL}/documents/${id}`);
    dispatch(setDocument(data));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};
