import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import UserMenuDots from 'components/MenuDots/UserMenuDots';
import toggleSideNav from 'redux/actions/sideNavActions';
import menuOptions from './menuOptions';

import './Header.scss';
import '../../assets/styles/Layouts.scss';
import '../../assets/styles/Bootstrap.scss';
import '../../assets/styles/Styles.scss';

function Header({ showSideNav, dispatch }) {
  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-lg wide-xl">
        <div className="nk-header-wrap">
          <div className="nk-header-brand">
            <Link to="/" className="logo-link">
              <img
                className="logo-light logo-img"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark2x.png 2x"
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark2x.png 2x"
                alt="logo-dark"
              />
            </Link>
          </div>
          <div className="nk-header-menu">
            <ul className="nk-menu nk-menu-main">
              <li className="nk-menu-item">
                <Link to="/" className="nk-menu-link">
                  <span className="nk-menu-text">Panel</span>
                </Link>
              </li>
              <li className="nk-menu-item has-sub">
                <a className="nk-menu-link nk-menu-toggle cursor-pointer">
                  <span className="nk-menu-text">Participadas</span>
                </a>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link to="/cuadro-participadas" className="nk-menu-link">
                      <span className="nk-menu-text">Cuadro Participadas</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link to="/lista-participadas" className="nk-menu-link">
                      <span className="nk-menu-text">Lista Participadas</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      to="/kanban-participadas"
                      className="nk-menu-link is-disable"
                    >
                      <span className="nk-menu-text">Kanban Participadas</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nk-menu-item has-sub">
                <a className="nk-menu-link nk-menu-toggle cursor-pointer">
                  <span className="nk-menu-text">Administradas</span>
                </a>
                <ul className="nk-menu-sub">
                  <li className="nk-menu-item">
                    <Link to="/cuadro-administradas" className="nk-menu-link">
                      <span className="nk-menu-text">Cuadro Administradas</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link to="/lista-administradas" className="nk-menu-link">
                      <span className="nk-menu-text">Lista Administradas</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      to="/kanban-administradas"
                      className="nk-menu-link is-disable"
                    >
                      <span className="nk-menu-text">Kanban Administradas</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <Link to="/nueva-sociedad" className="btn btn-outline-primary">
                Añadir Sociedad
              </Link>
              <li className="dropdown notification-dropdown">
                <span
                  className="dropdown-toggle nk-quick-nav-icon"
                  data-toggle="dropdown"
                >
                  <div className="icon-status icon-status-info">
                    <em className="icon ni ni-bell" />
                  </div>
                </span>
                <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-head">
                    <span className="sub-title nk-dropdown-title">
                      Notificaciones
                    </span>
                    <a>Marcar como leídas</a>
                  </div>
                  <div className="dropdown-body">
                    <div className="nk-notification">
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            You have requested to
                            {' '}
                            <span>Widthdrawl</span>
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Your
                            {' '}
                            <span>Deposit Order</span>
                            {' '}
                            is placed
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            You have requested to
                            {' '}
                            <span>Widthdrawl</span>
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Your
                            {' '}
                            <span>Deposit Order</span>
                            {' '}
                            is placed
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            You have requested to
                            {' '}
                            <span>Widthdrawl</span>
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                      <div className="nk-notification-item dropdown-inner">
                        <div className="nk-notification-icon">
                          <em className="icon icon-circle bg-success-dim ni ni-curve-down-left" />
                        </div>
                        <div className="nk-notification-content">
                          <div className="nk-notification-text">
                            Your
                            {' '}
                            <span>Deposit Order</span>
                            {' '}
                            is placed
                          </div>
                          <div className="nk-notification-time">2 hrs ago</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-foot center">
                    <a>Ver todas</a>
                  </div>
                </div>
              </li>
              <li className="dropdown user-dropdown">
                <UserMenuDots menuOptions={menuOptions} />
              </li>
              <li className="d-lg-none">
                {showSideNav}
                <a
                  className="toggle nk-quick-nav-icon mr-n1"
                  data-target="sideNav"
                  aria-label="temp"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <em className="icon ni ni-menu" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(Header);
