import React from 'react';

import { connect } from 'react-redux';

import menuTypes from 'constants/menuTypes';

import MainMenu from './MainMenu';
import ExtendedMenu from './ExtendedMenu';
import SecondaryMenu from './SecondaryMenu';

import '../../assets/styles/Styles.scss';
import './SideNav.scss';

function SideNav({
  type, societyName, societyId, showSideNav,
}) {
  return (
    type && (
      <div
        className={
          showSideNav
            ? 'nk-aside toggle-screen-lg mobile-menu content-active'
            : 'nk-aside toggle-screen-lg mobile-menu'
        }
        data-content="sideNav"
        data-toggle-overlay="true"
        data-toggle-screen="lg"
        data-toggle-body="true"
      >
        <div className="nk-sidebar-menu" data-simplebar>
          <ul className="nk-menu">
            <li className="nk-menu-heading">
              <h6 className="overline-title text-primary-alt">
                {type === menuTypes.MAIN ? 'Sociedades' : societyName}
              </h6>
            </li>
            {type === menuTypes.MAIN ? <MainMenu /> : <ExtendedMenu societyId={societyId} />}
            <SecondaryMenu />
          </ul>
        </div>
        <div className="nk-aside-close">
          <a
            href="/"
            className="toggle"
            aria-label="link"
            data-target="sideNav"
          >
            <em className="icon ni ni-cross" />
          </a>
        </div>
      </div>
    )
  );
}

function mapStateToProps({ menu: { type, societyName, societyId }, sideNav: showSideNav }) {
  return {
    type, societyName, societyId, showSideNav,
  };
}
export default connect(mapStateToProps)(SideNav);
