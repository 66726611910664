import React, { useState } from 'react';
import { getPartnerOperations } from 'utils/filters';

import PartnerTransactionsRow from './PartnerTransactionsRow';

function PartnerTransactions({ society, partner }) {
  const [partnerOperations] = useState(getPartnerOperations(partner, society));

  const rows = partnerOperations.map((transaction, index) => (
    <PartnerTransactionsRow
      transaction={transaction}
      index={index + 1}
      key={transaction?.['_id']}
    />
  ));

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">Transacciones del socio</h4>
          <div className="nk-block-des">
            <p>
              {`Transacciones en ${society.name} de ${partner.firstName} ${partner.lastName || ''}`}
            </p>
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Nº</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Importe</th>
                  <th scope="col">Nº Part.</th>
                  <th scope="col">Doc.</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>
          {`${partner.firstName} ${partner.lastName || ''} aun no tiene transacciones en ${society.name}`}

        </p>
      )}
    </div>
  );
}

export default PartnerTransactions;
