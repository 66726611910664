import MenuDots from 'components/MenuDots';
import React, { useEffect, useState } from 'react';

import { dayMonYea } from '../../utils/formatDate';
import menuOptions from './menuOptions';

function UserWithAccessRow({ userId, admin }) {
  const [lastAccess, setLastAccess] = useState('');
  const [verification, setVerification] = useState('');

  useEffect(() => {
    setVerification(dayMonYea(admin.verificationDate));
    setLastAccess(dayMonYea(admin.lastAccess));
  }, [admin]);

  return (
    <tr className="tb-tnx-item">
      <td className="tb-tnx-info">
        <div className="tb-tnx-desc">
          <span className="title">
            {`${admin.firstName} ${admin.lastName || ''}`}
          </span>
        </div>
        <div className="tb-tnx-date">
          <span className="date">{verification}</span>
          <span className="date">{lastAccess}</span>
        </div>
      </td>
      <td className="tb-tnx-amount is-alt">
        <div className="tb-tnx-total">
          <span className="amount">{admin.email}</span>
        </div>
        <div className="tb-tnx-status">
          <span className="badge badge-dot badge-danger">Completo</span>
        </div>
      </td>
      <td className="tb-tnx-action">
        <MenuDots menuOptions={menuOptions} id={admin['_id']} secondaryId={userId} />
      </td>
    </tr>
  );
}

export default UserWithAccessRow;
