import axios from 'axios';

import server from 'constants/server';
import operationTypes from 'constants/operationTypes';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from './alertActions';

import { reSetSociety } from './societyActions';

async function modalCall(body, dispatch) {
  try {
    const { data } = await axios.post(`${server.URL}/operations`, body);
    dispatch(reSetSociety(data));
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
}

export const addPartner = (partnerData) => async (dispatch) => {
  const operationType = operationTypes.ADD_PARTNER;
  const body = { ...partnerData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
  }
};

export const constitution = (constitutionData) => async (dispatch) => {
  const operationType = operationTypes.CONSTITUTION;
  const body = { ...constitutionData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const capitalIncreaseAction = (increaseData) => async (dispatch) => {
  const operationType = operationTypes.CAPITAL_INCREASE;
  const body = { ...increaseData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const capitalDecreaseAction = (decreaseData) => async (dispatch) => {
  const operationType = operationTypes.CAPITAL_DECREASE;
  const body = { ...decreaseData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const sellParticipationsAction = (sellData) => async (dispatch) => {
  const operationType = operationTypes.SELL_PARTICIPATION;
  const body = { ...sellData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const stockOptionAction = (stockData) => async (dispatch) => {
  const operationType = operationTypes.STOCK_OPTION;
  const body = { ...stockData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const phantomSharesAction = (phantomData) => async (dispatch) => {
  const operationType = operationTypes.PHANTOM_SHARE;
  const body = { ...phantomData, operationType };
  try {
    modalCall(body, dispatch);
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};
