export default [
  {
    icon: 'ni-user-alt',
    text: 'Mi perfil',
    url: '/perfil-usuario/datos',
  },
  {
    icon: 'ni-users',
    text: 'Mis sociedades',
    url: '/perfil-usuario/identidades',
  },
  {
    icon: 'ni-building',
    text: 'Sociedades representadas',
    url: '/perfil-usuario/sociedades',
  },
  {
    icon: 'ni-lock-alt',
    text: 'Usuarios de la cuenta',
    url: '/perfil-usuario/administradores',
  },
];
