export default [
  {
    icon: 'ni-book-read',
    text: 'Libro Socios',
    url: '/socios-libro',
  },
  {
    icon: 'ni-users',
    text: 'Socios',
    url: '/socios',
  },
  {
    icon: 'ni-file-text',
    text: 'Documentos',
    url: '/documentos',
  },
  {
    icon: 'ni-repeat',
    text: 'Transacciones',
    url: '/transacciones',
  },
  {
    icon: 'ni-edit',
    text: 'Editar',
    url: '/perfil-sociedad/editar',
  },
];
