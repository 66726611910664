import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import "../../assets/styles/Icons.scss";
import "../../assets/styles/Bootstrap.scss";
import "./OperationMenuDots.scss";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-toggle mr-lg-n1"
  >
    {children}
  </div>
));
CustomToggle.displayName = "CustomToggle";

function OperationMenuDots({ menuOptions, handleModal }) {
  const menu = menuOptions.map((menuOption) => (
    <Dropdown.Item
      onClick={() => handleModal(menuOption.url)}
      key={menuOption.text}
    >
      <span>{menuOption.text}</span>
    </Dropdown.Item>
  ));

  return (
    <Dropdown drop={menuOptions.direction}>
      <Dropdown.Toggle as={CustomToggle}>
        <button type="button" className="btn btn-primary">
          Añadir Operación
          <span className="icon ni ni-caret-down ml-2" />
        </button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        size="sm"
        title=""
        className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
      >
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default OperationMenuDots;
