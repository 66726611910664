import axios from 'axios';

import server from 'constants/server';
import types from 'redux/actions/actionTypes';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from './alertActions';

export function setPartnerBook(partnerBook) {
  return { type: types.SET_PARTNER_BOOK, partnerBook };
}

export const getPartnerBook = (societyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${server.URL}/book/${societyId}`);
    return dispatch(setPartnerBook(data));
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes[500]));
  }
};
