import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';

import ParticipationsCertificate from 'components/ParticipationsCertificate';
import operationTypes from 'constants/operationTypes';
import { dayMonthYear, monDayYear } from 'utils/formatDate';
import { getPartnerInversion } from 'utils/filters';

function PartnerSociety({ partner, society, partnerNumber }) {
  const [fullName] = useState(`${partner.firstName} ${partner.lastName || ''}`);
  const [NDpercent] = useState(((partner.sharesCount?.actual * 100)
    / society.sharesCount?.actual).toFixed(0));
  const [FDpercent] = useState((
    ((partner.sharesCount?.actual + partner.sharesCount?.future) * 100)
    / (society.sharesCount?.actual + society.sharesCount?.future)).toFixed(0));
  const [lastYear] = useState(new Date().getFullYear() - 1);
  const [societyBalance] = useState(society.annualValues
    .filter((society) => +society.year === lastYear)
    .map((annualValue) => annualValue.contableValue));
  const [hasConstitution, setHasConstitution] = useState(false);
  const [hasAnnualData, setHasAnnualData] = useState(false);

  const [certificateData] = useState({
    userFullName: fullName,
    userShares: partner.sharesCount?.actual,
    societyName: society?.name,
    societyCIF: society?.cif,
    societyAdmin: `${society?.administrators[0].firstName} ${society?.administrators[0].lastName}`,
    capitalPercent: NDpercent,
    nominalValueShares: society?.nominalValue,
    totalNominalValueShares: society?.nominalValue * partner?.sharesCount?.actual,
    totalValueShares: societyBalance * (NDpercent / 100),
    totalActive: societyBalance,
    lastYear,
    downloadDate: new Date(),
  });

  useEffect(() => {
    if (society.operations.map((data) => data.operationType)
      .includes(operationTypes.CONSTITUTION)) {
      setHasConstitution(true);
    }
    if (certificateData.totalActive) {
      setHasAnnualData(true);
    }
  }, [society.operations, certificateData.totalActive]);

  return (
    <div className="card-inner">
      <div className="card-inner-group" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className="simplebar-content-wrapper"
                style={{ height: 'auto', overflow: 'hidden' }}
              >
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2">
                      <div className="user-avatar lg bg-primary">
                        {society.additional.logo
                          ? <img src={society.additional.logo} alt="Logo" />
                          : <span>{society.name.slice(0, 2).toUpperCase()}</span>}
                      </div>
                      <div className="user-info">
                        <h5>{fullName}</h5>
                        <div className="badge badge-outline-primary badge-pill text-primary ucap">
                          Socio
                        </div>
                        <span className="sub-text">
                          {`Es socio de ${society.name}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">

                      {!hasConstitution || !hasAnnualData
                        ? (
                          <span className="btn btn-primary report-button is-disable">
                            <em className="icon ni ni-reports-alt mr-1" />
                            Certificado no disponible
                          </span>
                        ) : (
                          <span className="btn btn-primary report-button">
                            <em className="icon ni ni-reports-alt mr-1" />
                            <PDFDownloadLink
                              document={(
                                <ParticipationsCertificate
                                  certificateData={certificateData}
                                />
                              )}
                              fileName="Certificado Valor Participaciones.pdf"
                            >
                              {({ loading }) => (loading ? 'Generando Certificado...' : 'Certificado Valor Participaciones')}
                            </PDFDownloadLink>
                          </span>
                        )}
                    </ul>
                  </div>

                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">
                      {`Inversión de ${fullName} en ${society.name}`}
                    </h6>
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">{partner.sharesCount?.actual || 0}</div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline-primary">
                            Participaciones
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">
                            {`${NDpercent > 0 ? NDpercent : 0}%`}
                          </div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline-primary">
                            Porcentaje
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">
                            {`${getPartnerInversion(partner.shares, society)} €`}
                          </div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline-primary">
                            Invertido
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nk-fmg-status">
                    <h6 className="nk-fmg-status-title">
                      <em className="icon ni ni-meter" />
                      <span>
                        {`Porcentaje: ${NDpercent > 0 ? NDpercent : 0}%`}
                      </span>
                    </h6>
                    <div className="progress progress-md bg-light">
                      <div
                        className="progress-bar"
                        data-progress={NDpercent || 0}
                        style={{ width: `${NDpercent > 0 ? NDpercent : 0}%` }}
                      />
                    </div>
                    <div className="nk-fmg-status-info">
                      {`${NDpercent > 0 ? NDpercent : 0}% ND - ${FDpercent > 0 ? FDpercent : 0}% FD`}
                    </div>
                    <div className="nk-fmg-status-action">
                      <Link
                        to={`/socios-libro/${society['_id']}`}
                        className="link link-primary link-sm"
                      >
                        Ver Libro de Socios
                      </Link>
                    </div>
                  </div>

                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">
                      Know Your Customer (KYC)
                    </h6>
                    <div className="row g-3">
                      <div className="col-6">
                        <span className="sub-text">Socio nº:</span>
                        <span>{partnerNumber || '-'}</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">Fecha alta:</span>
                        <span>{dayMonthYear(partner.creationDate)}</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">KYC Estado:</span>
                        <span className="lead-text text-success">?¿?¿?¿</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">Fecha verificación</span>
                        <span>{monDayYear(partner.verificationDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: '767px' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ height: '0px', display: 'none' }}
          />
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default PartnerSociety;
