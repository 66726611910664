/* eslint-disable no-undef */
import React from 'react';
import { Page, Text, Document, StyleSheet } from '@react-pdf/renderer';
import { dayMonthYear } from 'utils/formatDate';

const styles = StyleSheet.create({
  body: {
    paddingTop: 130,
    paddingBottom: 65,
    paddingHorizontal: 70,
  },
  title: {
    color: '#274673',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    display: 'block',
    marginBottom: 8,
  },
  titleBottom: {
    color: '#274673',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    display: 'block',
    marginBottom: 70,
  },
  author: {
    color: '#657796',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 40,
    display: 'block',
  },
  text: {
    color: '#657796',
    fontSize: 13,
    fontWeight: 300,
    textAlign: 'justify',
    lineHeight: 1.6,
    display: 'block',
    marginBottom: 20,
  },
});

function ParticipationsCertificate({ certificateData, year }) {
  const {
    userFullName,
    userShares,
    societyName,
    societyCIF,
    societyAdmin,
    capitalPercent,
    nominalValueShares,
    totalNominalValueShares,
    totalValueShares,
    totalActive,
    downloadDate,
  } = certificateData;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Certificado Valor Participaciones</Text>
        <Text style={styles.titleBottom}>{societyName}</Text>

        <Text style={styles.text}>
          {`A fecha 31 de diciembre de ${year}, ${userFullName} tenía ${userShares} participaciones de la sociedad ${societyName} con CIF ${societyCIF}, que representan el ${capitalPercent}% del capital social.`}
        </Text>

        <Text style={styles.text}>
          {`El valor nominal de cada participación es de ${nominalValueShares}€. Por tanto, el valor nominal de las participaciones de ${userFullName} es de ${totalNominalValueShares}€ y el valor contable de las participaciones es de ${totalValueShares}€ al tener la sociedad un activo de ${totalActive}€.`}
        </Text>

        <Text style={styles.text}>
          {`Y para que así conste, expido el presente certificado el ${dayMonthYear(
            downloadDate
          )}
          `}
        </Text>

        <Text style={styles.text}>
          {`El Administrador de ${societyName}
          `}
          <Text style={styles.author}>{societyAdmin}</Text>
        </Text>
      </Page>
    </Document>
  );
}

export default ParticipationsCertificate;
