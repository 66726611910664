/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import PartnerSociety from 'components/PartnerSociety';
import PartnerPersonal from 'components/PartnerPersonal';
import PartnerTransactions from 'components/PartnerTransactions';
import PartnerAside from 'components/PartnerAside';

import { dayMonthYear, monDayYear } from 'utils/formatDate';
import {
  getActualSociety,
  getActualPartner,
  getPartnerNumber,
} from 'utils/filters';

import { setMenu } from 'redux/actions/menuActions';

import menuTypes from 'constants/menuTypes';
import './PartnerDetail.scss';

function PartnerDetail({ user, actualSociety, actions }) {
  const { societyId, partnerId } = useParams();

  const [actualPartner, setActualPartner] = useState();

  const [partnerNumber, setPartnerNumber] = useState();

  const [incorporationDate, setIncorporationDate] = useState(null);
  const [verificationDate, setVerificationDate] = useState(null);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (user && actualSociety) {
      const partnerNumber = getPartnerNumber(actualSociety, partnerId);
      setPartnerNumber(partnerNumber.number);
      setIncorporationDate(dayMonthYear(partnerNumber.partnerSince));

      const partner = getActualPartner(actualSociety, partnerId);
      if (partner) {
        const partnerData = {
          ...partner.id,
          sharesCount: partner.sharesCount,
          cif: partner.cif,
        };
        setActualPartner(partnerData);
        setVerificationDate(monDayYear(partner.verificationDate));
        setPages([
          <PartnerSociety
            key="PartnerSociety"
            society={actualSociety}
            partner={partnerData}
            partnerNumber={partnerNumber.number}
          />,
          <PartnerPersonal
            key="PartnerPersonal"
            incorporationDate={incorporationDate}
            partner={partnerData}
          />,
          <PartnerTransactions
            key="PartnerTransactions"
            society={actualSociety}
            partner={partnerData}
          />,
        ]);
      }
    }
  }, [
    partnerId,
    societyId,
    user,
    partnerNumber,
    actualSociety,
    incorporationDate,
  ]);

  useEffect(() => {
    actions.setMenu({
      type: menuTypes.EXTENDED,
      societyName: actualSociety?.name,
      societyId: actualSociety?.['_id'],
    });
  }, [actions, actualSociety]);

  const [isCardVisible, setIsCardVisible] = useState(false);
  const handleClick = () => {
    setIsCardVisible(!isCardVisible);
  };

  return actualPartner ? (
    <div className="nk-content-body">
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
      </div>
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Socio /{' '}
                <strong className="text-primary small">
                  {`${actualPartner.firstName} ${actualPartner.lastName || ''}`}
                </strong>
              </h3>
              <div className="nk-block-des text-soft">
                <ul className="list-inline">
                  <li>
                    Socio Nº:
                    <span className="text-base">{partnerNumber}</span>
                  </li>
                  <li>{`Desde: ${incorporationDate}`}</li>
                </ul>
              </div>
            </div>
            <div className="nk-block-head-content">
              <a
                href="html/socios-listado.html"
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex is-disable"
              >
                <em className="icon ni ni-arrow-left" />
                <span>Volver</span>
              </a>
              <a
                href="html/socios-listado.html"
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <em className="icon ni ni-arrow-left" />
              </a>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 0 ? 'active' : ''}`}
                      onClick={() => setPage(0)}
                    >
                      <em className="icon ni ni-user-circle" />
                      <span>Socio</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 1 ? 'active' : ''}`}
                      onClick={() => setPage(1)}
                    >
                      <em className="icon ni ni-list" />
                      <span>Datos</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 2 ? 'active' : ''}`}
                      onClick={() => setPage(2)}
                    >
                      <em className="icon ni ni-repeat" />
                      <span>Transacciones</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link is-disable ${
                        page === 3 ? 'active' : ''
                      }`}
                      onClick={() => setPage(3)}
                    >
                      <em className="icon ni ni-file-text" />
                      <span>Documentos</span>
                    </p>
                  </li>
                  <li className="nav-item nav-item-trigger d-xxl-none">
                    <p
                      className="toggle btn btn-icon btn-trigger"
                      data-target="userAside"
                      onClick={() => setIsCardVisible(!isCardVisible)}
                    >
                      <em className="icon ni ni-user-list-fill" />
                    </p>
                  </li>
                </ul>
                {pages[page]}
              </div>
              <div
                className={
                  isCardVisible
                    ? 'card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl toggle-screen-xxl content-active'
                    : 'card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl toggle-screen-xxl'
                }
                data-content="userAside"
                data-toggle-screen="xxl"
                data-toggle-overlay="true"
                data-toggle-body="true"
              >
                <div className="card-inner-group" data-simplebar="init">
                  <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                          <div className="simplebar-content">
                            <ul>
                              <li className="nav-item nav-item-trigger d-xxl-none m-4">
                                <p
                                  className="toggle btn btn-icon btn-trigger"
                                  data-target="userAside"
                                  onClick={handleClick}
                                >
                                  <em className="icon ni ni-cross" />
                                </p>
                              </li>
                            </ul>
                            <PartnerAside
                              partner={actualPartner}
                              society={actualSociety}
                              partnerNumber={partnerNumber}
                              incorporationDate={incorporationDate}
                              verificationDate={verificationDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder" />
                </div>
                <div className="simplebar-track simplebar-horizontal">
                  <div className="simplebar-scrollbar" />
                </div>
                <div className="simplebar-track simplebar-vertical">
                  <div className="simplebar-scrollbar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDetail);
