import store from 'redux/store';
import { updateSociety } from 'redux/actions/societyActions';
import { editUser } from 'utils/modals';

export default [
  {
    icon: 'ni-edit',
    text: 'Editar',
    action: editUser,
  },
  {
    icon: 'ni-trash',
    text: 'Eliminar',
    action: (societyId, adminId) => store.dispatch(
      updateSociety(societyId, { admin: { remove: adminId } }),
    ),
  },
];
