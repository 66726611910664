import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import toggleSideNav from 'redux/actions/sideNavActions';

import './SideNav.scss';

function ExtendedMenu({ societyId, dispatch }) {
  return (
    <Accordion>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-users" />
            </span>
            <span className="nk-menu-text">Socios</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link
              to={`/socios/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Socios</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/socios-fichas/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Fichas de Socios</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/socios-libro/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Libro de Socios</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>

      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-exchange" />
            </span>
            <span className="nk-menu-text">Transacciones</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link
              to={`/transacciones/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Listado Transacciones</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>

      <Accordion.Toggle as={Button} variant="link" eventKey="2">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-files" />
            </span>
            <span className="nk-menu-text">Documentos</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link
              to={`/documentos-sociedad/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Documentos</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/valor-anual-sociedad/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Certificado</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>

      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-chart-up" />
            </span>
            <span className="nk-menu-text">Informes</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link to="/" className="nk-menu-link is-disable">
              <span className="nk-menu-text">Informes financieros</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/valor-anual-sociedad/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Valor Participaciones</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>

      <Accordion.Toggle as={Button} variant="link" eventKey="4">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-setting" />
            </span>
            <span className="nk-menu-text">Datos Sociedad</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="4">
        <ul className="nk-menu-sub">
          <li className="nk-menu-item">
            <Link
              to={`/perfil-sociedad/datos/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Datos básicos</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/perfil-sociedad/suscripcion/${societyId}`}
              className="nk-menu-link is-disable"
            >
              <span className="nk-menu-text">Suscripción</span>
            </Link>
          </li>
          <li className="nk-menu-item">
            <Link
              to={`/perfil-sociedad/administradores/${societyId}`}
              className="nk-menu-link"
              onClick={() => dispatch(toggleSideNav())}
            >
              <span className="nk-menu-text">Otros Administradores</span>
            </Link>
          </li>
        </ul>
      </Accordion.Collapse>
    </Accordion>
  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(ExtendedMenu);
