import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import alertBodyTypes from 'components/Alert/alertBodyTypes';

import { addAlert } from 'redux/actions/alertActions';
import { updateSociety } from 'redux/actions/societyActions';
import { setModal } from 'redux/actions/modalActions';

import '../Modals/Modals.scss';

function AddAdminModal({ actualSociety, dispatch }) {
  const [email, setEmail] = useState('');

  function savePartner(event) {
    event.preventDefault();

    const isAdminAlready = actualSociety?.administrators.find((admin) => admin.email === email);

    if (!isAdminAlready) {
      dispatch(updateSociety(actualSociety['_id'],
        {
          admin: {
            add: email,
          },
          society: actualSociety['_id'],
        }));
      dispatch(setModal(null));
    } else {
      dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
    }
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Socio</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  Correo electrónico
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email del nuevo socio"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    patter="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  />
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-lg btn-primary">
            Añadir administrador
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">
          El administrador recibirá una notificación por email
        </span>
      </Modal.Footer>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(AddAdminModal);
