import React from "react";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import "../../assets/styles/Icons.scss";
import "../../assets/styles/Bootstrap.scss";
import "./UserMenuDots.scss";

function UserMenuDots({ menuOptions, user }) {
  const { logout } = useAuth0();

  function onLogout() {
    localStorage.removeItem("token");
    logout();
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle mr-lg-n1"
    >
      {children}
      <div className="user-toggle">
        <div className="user-avatar sm">
          {user ? (
            <img
              src={user.image}
              alt={user.firstName}
              className="rounded-circle"
            />
          ) : (
            <em className="icon ni ni-user-alt" />
          )}
        </div>
      </div>
    </div>
  ));
  CustomToggle.displayName = "CustomToggle";

  const menu = menuOptions.map((menuOption) => (
    <Link to={menuOption.url} key={menuOption.text}>
      <em className={`icon ni ${menuOption.icon}`} />
      <span>{menuOption.text}</span>
    </Link>
  ));

  menu.push(
    <Dropdown.Item onClick={onLogout} key="signout">
      <em className="icon ni ni-signout" />
      <span>Salir</span>
    </Dropdown.Item>
  );

  return (
    <Dropdown drop={menuOptions.direction}>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu
        size="sm"
        title=""
        className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
      >
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card">
            <div className="user-avatar">
              {user ? (
                <img
                  src={user.image}
                  alt={user.firstName}
                  className="rounded-circle"
                />
              ) : (
                <em className="icon ni ni-user-alt" />
              )}
            </div>
            <div className="user-info">
              <span className="lead-text">
                {`${user?.firstName} ${user?.lastName || ""}`}
              </span>
              <span className="sub-text">{user?.email}</span>
            </div>
          </div>
        </div>
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(UserMenuDots);
