import React from 'react';

import './Pagination.scss';

function Pagination({ itemsPerPage, totalItems, paginate }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i += 1) {
    pageNumbers.push(i);
  }
  return (
    <div className="card-inner">
      <div className="nk-block-between-md g-3">
        <div className="g">
          <ul className="pagination justify-content-center justify-content-md-start">
            {pageNumbers.map((number) => (
              <li className="page-item" key={number}>
                <a
                  role="button"
                  className="page-link"
                  onClick={() => paginate(number)}
                  tabIndex={0}
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="g">
          <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
            <span className="pagination-text">Página</span>
            <div>
              <select
                className="form-select form-select-sm"
                data-search="on"
                data-dropdown="xs center"
                onChange={(event) => paginate(event.target.value)}
              >
                {pageNumbers.map((number) => (
                  <option
                    value={number}
                    key={number}
                  >
                    {number}
                  </option>
                ))}
              </select>
            </div>
            <span className="pagination-text">
              de
              {' '}
              {pageNumbers.length}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
