/* eslint-disable import/prefer-default-export */
import React from 'react';
import store from 'redux/store';

import { setModal } from 'redux/actions/modalActions';

import EditModal from 'components/UserMyData/EditModal';
import axios from 'axios';
import server from 'constants/server';

export async function editUser(userId) {
  const { data } = await axios.get(`${server.URL}/users/${userId}`);
  store.dispatch(setModal(<EditModal user={data} />));
}
