import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addDocument } from 'redux/actions/documentActions';
import { capitalIncreaseAction } from 'redux/actions/modalsActions';
import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';

import operationTypes from 'constants/operationTypes';

import { INITIAL_DATE, SELECIONAR_SOCIO } from 'constants/defaultConstants';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import UserDropdown from '../UserDropdown';

import '../Modals.scss';

function CapitalIncreaseModal({ user, actualSociety, dispatch }) {
  const [step, setStep] = useState(0);

  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);

  const [actualFrom, setActualFrom] = useState(actualSociety.sharesCount?.actual + 1);

  const [date, setDate] = useState(INITIAL_DATE);

  const [totalParticipationsToAdd, setTotalParticipationsToAdd] = useState('');
  const [share, setShare] = useState('');
  const [file, setFile] = useState();

  const [selectedPartner, setSelectedPartner] = useState(SELECIONAR_SOCIO);
  const [partnerParticipations, setPartnerParticipations] = useState('');

  const [lastOperationDate, setLastOperationDate] = useState();

  useEffect(() => {
    if (actualSociety) {
      let lastOperation = actualSociety.operations.reduce(
        (acc, op) => (new Date(acc.date) > new Date(op.date)
          ? acc
          : op),
      );

      // TODO Replace with moment and the proper format
      lastOperation = new Date(lastOperation.date).toISOString().replace(':00.000Z', '');
      setLastOperationDate(lastOperation || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  function nextPartner() {
    if (selectedPartner === SELECIONAR_SOCIO) dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      const newMovement = {
        partner: JSON.parse(selectedPartner),
        shareFrom: actualFrom,
        shareTo: actualFrom + +partnerParticipations - 1,
      };

      setMovements([...movements, newMovement]);

      setPartnerParticipations('');
      setActualFrom(actualFrom + +partnerParticipations);
      setSelectedPartner(SELECIONAR_SOCIO);
      setMissingParticipations(missingParticipations - +partnerParticipations);
    }
  }

  function saveIncrease() {
    if (selectedPartner === SELECIONAR_SOCIO) dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      const finalMovements = [
        ...movements,
        {
          partner: JSON.parse(selectedPartner),
          shareFrom: actualFrom,
          shareTo: actualFrom + +partnerParticipations - 1,
        },
      ];

      const newDocument = {
        file,
        date,
        name: operationTypes.CAPITAL_INCREASE_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
      };

      dispatch(capitalIncreaseAction({
        movements: finalMovements,
        date,
        sharePremium: share,
        society: actualSociety['_id'],
        partner: user['_id'],
      }));

      dispatch(addDocument(newDocument));
      dispatch(setModal(null));
    }
  }

  function nextStep(event) {
    event.preventDefault();
    if (!step) {
      setTotalParticipationsToAdd(+totalParticipationsToAdd);
      setShare(+share);
      setMissingParticipations(+totalParticipationsToAdd);
      setStep(1);
    } else if (missingParticipations - +partnerParticipations) nextPartner();
    else saveIncrease();
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Ampliación de capital</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Fecha ampliación
                </label>
                <div className="form-control-wrap">
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    required
                    max="2100-01-01T00:00"
                    min={lastOperationDate}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Nº nuevas participaciones
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Nº</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Nº de nuevas participaciones"
                    value={totalParticipationsToAdd}
                    onChange={(event) => setTotalParticipationsToAdd(event.target.value)}
                    min="1"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Prima de emisión por participación
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Eur</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Importe prima de emisión por participación"
                    value={share}
                    onChange={(event) => setShare(event.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  Añadir escritura (opcional)
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="customFile"
                      type="file"
                      accept="application/pdf"
                      className="custom-file-input"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {file?.name || 'Seleccionar documento'}
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                Siguiente
              </button>
            </>
          ) : (
            <>
              <label className="form-label">
                <span className="bg-warning p-2">
                  Total Nuevas Participaciones pendientes de asignar:
                </span>
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  {missingParticipations}
                </span>
              </label>
              <hr />
              <div className="form-group">
                <label className="form-label ">
                  Nombre Socio
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-user" />
                  </div>
                  <UserDropdown
                    users={actualSociety?.partners}
                    action={setSelectedPartner}
                    user={selectedPartner}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Nº nuevas participaciones
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Nº</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Nº de nuevas participaciones"
                    value={partnerParticipations}
                    onChange={(event) => setPartnerParticipations(event.target.value)}
                    min="1"
                    max={missingParticipations}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  De la número
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Numeración</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={actualFrom}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Hasta la número
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Numeración</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={actualFrom + +partnerParticipations - (partnerParticipations ? 1 : 0)}
                    readOnly
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {missingParticipations - +partnerParticipations ? 'Siguiente' : 'Guardar'}
              </button>
            </>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(CapitalIncreaseModal);
