import operationTypes from 'constants/operationTypes';
import React, { useState, useEffect } from 'react';

import { getPartnerInversion, getPartnerOperations } from 'utils/filters';

function PartnerAside({
  partner, society, incorporationDate, verificationDate, partnerNumber,
}) {
  const [NDpercent] = useState(((partner.sharesCount?.actual * 100)
    / society.sharesCount?.actual).toFixed(0));

  const [progressTransactions, setProgressTransactions] = useState();
  const [completeTransactions, setCompleteTransactions] = useState();

  useEffect(() => {
    const partnerOperationsInSociety = getPartnerOperations(partner, society);

    const completeOperations = partnerOperationsInSociety.filter(
      (operation) => operation?.operationType !== (operationTypes.STOCK_OPTION
        || operationTypes.PHANTOM_SHARE),
    ).length;

    setProgressTransactions(partnerOperationsInSociety.length - completeOperations);
    setCompleteTransactions(completeOperations);
  }, [partner, society]);

  return (
    <>
      <div className="card-inner">
        <div className="user-card user-card-s2">
          <div className="user-avatar lg bg-primary">
            <img
              src={partner.image}
              alt={`${partner.firstName} ${partner.lastName || ''}`}
            />
          </div>
          <div className="user-info">
            <div className="badge badge-outline-primary badge-pill text-primary ucap">
              Socio
            </div>
            <h5>{`${partner.firstName} ${partner.lastName || ''}`}</h5>
            <span className="sub-text">{partner.email}</span>
          </div>
        </div>
      </div>

      <div className="card-inner">
        <div className="overline-title-alt mb-2">Inversión</div>
        <div className="profile-balance">
          <div className="profile-balance-group gx-4">
            <div className="profile-balance-sub">
              <div className="profile-balance-amount">
                <div className="number" style={{ textAlign: 'center' }}>
                  {`${getPartnerInversion(partner.shares, society)} €`}
                </div>
              </div>
              <div className="profile-balance-subtitle text-center">
                Inversión
              </div>
            </div>
            <div className="profile-balance-sub">
              <span className="profile-balance-plus text-soft">
                <em className="icon ni ni-plus" />
              </span>
              <div className="profile-balance-amount">
                <div className="number text-center">
                  {`${NDpercent > 0 ? NDpercent : 0}%`}
                </div>
              </div>
              <div className="profile-balance-subtitle">Porcentaje</div>
            </div>
            <div className="profile-balance-sub">
              <span className="profile-balance-plus text-soft">
                <em className="icon ni ni-plus" />
              </span>
              <div
                className="profile-balance-amount"
                style={{ textAlign: 'center' }}
              >
                <div className="number">{partner.sharesCount?.actual || 0}</div>
              </div>
              <div className="profile-balance-subtitle">Nº Part.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-inner">
        <div className="row text-center">
          <div className="col-4">
            <div className="profile-stats">
              <span className="amount">
                {completeTransactions + progressTransactions || 0}
              </span>
              <span className="sub-text" style={{ width: '90px' }}>Transacciones</span>
            </div>
          </div>
          <div className="col-4">
            <div className="profile-stats">
              <span className="amount">{completeTransactions}</span>
              <span className="sub-text">Completadas</span>
            </div>
          </div>
          <div className="col-4">
            <div className="profile-stats">
              <span className="amount">{progressTransactions}</span>
              <span className="sub-text">En Progreso</span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-inner">
        <h6 className="overline-title-alt mb-2">Know Your Customer (KYC)</h6>
        <div className="row g-3">
          <div className="col-6">
            <span className="sub-text">Socio nº:</span>
            <span>{partnerNumber || '-'}</span>
          </div>
          <div className="col-6">
            <span className="sub-text">Fecha alta:</span>
            <span>{incorporationDate}</span>
          </div>
          <div className="col-6">
            <span className="sub-text">KYC Estado:</span>
            <span className="lead-text text-success">?¿?¿?¿</span>
          </div>
          <div className="col-6">
            <span className="sub-text">Fecha verificación</span>
            <span>{verificationDate}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerAside;
