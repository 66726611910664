import React from 'react';

import store from 'redux/store';

import { setModal } from 'redux/actions/modalActions';
import UserWithAccessRow from './UserWithAccessRow';
import AddUserWithAccess from './AddUserWithAccess';

function UserWithAccess({ user }) {
  const rows = user.accountAdmins?.map((admin) => <UserWithAccessRow admin={admin} userId={user['_id']} key={admin['_id']} />);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">Usuarios con acceso a tu cuenta</h4>
          <div className="nk-block-des">
            <p>
              Puedes dar diferentes niveles de acceso a tu cuenta a otros usuarios.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <p className="btn btn-success" onClick={() => store.dispatch(setModal(<AddUserWithAccess />))}>
              Añadir usuarios
            </p>
          </div>
        </div>
        {rows.length ? (
          <div className="card card-bordered card-preview">
            <table className="table table-tranx">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="tb-tnx-info">
                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                      <span>Nombre y apellidos</span>
                    </span>
                    <span className="tb-tnx-date d-md-inline-block d-none">
                      <span className="d-md-none">Fechas</span>
                      <span className="d-none d-md-block">
                        <span>Fecha alta</span>
                        <span>Último acceso</span>
                      </span>
                    </span>
                  </th>
                  <th className="tb-tnx-amount is-alt">
                    <span className="tb-tnx-total">email</span>
                    <span className="tb-tnx-status d-none d-md-inline-block">
                      Nivel de acceso
                    </span>
                  </th>
                  <th className="tb-tnx-action">
                    <span>&nbsp;</span>
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        ) : <></>}
      </div>
    </div>
  );
}

export default UserWithAccess;
