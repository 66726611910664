/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { countSocietyShares } from 'utils/getShareCount';
import initialState from '../initialState';

export default function reducer(society = initialState.society, action) {
  switch (action.type) {
    case actionTypes.SET_SOCIETY:
      const actualSociety = countSocietyShares(action.society.society);
      return { actualSociety, isAdmin: action.society.isAdmin };
    case actionTypes.UPDATE_SOCIETY:
      const updatedSociety = countSocietyShares(action.society);
      return { ...society, actualSociety: updatedSociety };
    default:
      return society;
  }
}
