import React from 'react';
import { Link } from 'react-router-dom';

import '../../assets/styles/Layouts.scss';
import './Footer.scss';

function Footer() {
  return (
    <div className="nk-footer footer">
      <div className="container wide-xl">
        <div className="nk-footer-wrap g-2">
          <div className="nk-footer-copyright">
            {' '}
            &copy;
            {' '}
            <Link to="/">socio.legal</Link>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <Link className="nav-link" to="/aviso-legal">
                  Aviso legal
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/privacidad">
                  Privacidad
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
