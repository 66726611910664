import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import MenuDots from '../MenuDots';

import menuOptions from './menuOptions';

function PartnerRow({
  partner,
  societyId,
  societyShares,
  index,
  csvData,
  setCsvData,
  setCsvKey,
  sharesCount,
}) {
  const [partnerActualShares, setPartnerActualShares] = useState();
  const [partnerFutureShares, setPartnerFutureShares] = useState();

  const [NDpercent, setNDpercent] = useState();
  const [FDpercent, setFDpercent] = useState();

  const actualData = csvData;

  function updateData() {
    actualData.splice(index, 1, [
      index,
      `${partner.firstName} ${partner.lastName}`,
      partner.email,
      partnerActualShares,
      `${NDpercent}%`,
      `${FDpercent}%`,
    ]);
    setCsvData(actualData);
    setCsvKey(Math.random());
  }

  useEffect(() => {
    const actualShares = sharesCount?.actual || 0;
    const futureShares = (sharesCount?.actual + sharesCount?.future) || 0;

    setPartnerActualShares(actualShares);
    setPartnerFutureShares(futureShares);

    setNDpercent(((actualShares * 100) / societyShares).toFixed(2));
    setFDpercent(((futureShares * 100) / societyShares).toFixed(2));

    updateData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    societyShares,
    partner,
    partnerActualShares,
    partnerFutureShares,
  ]);

  menuOptions[0].url = `/detalle-socio/${societyId}`;
  menuOptions[1].url = `/detalle-socio/edit/${societyId}`;
  menuOptions[2].url = `/transacciones/${societyId}`;

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-md">
        <span>{index}</span>
      </td>

      <td className="nk-tb-col">
        <div className="user-card">
          <div className="user-avatar bg-dim-primary d-none d-sm-flex">
            <img
              src={partner.image}
              alt={`${partner.firstName} ${partner.lastName || ''}`}
              className="rounded-circle"
            />
          </div>
          <div className="user-info">
            <span className="tb-lead">
              <Link to={`/detalle-socio/${societyId}/${partner['_id']}`}>
                {`${partner.firstName} ${partner.lastName || ''}`}
              </Link>
              <span className="dot dot-success d-md-none ml-1" />
            </span>
            <span>{partner.email}</span>
          </div>
        </div>
      </td>
      <td className="nk-tb-col tb-col-mb" data-order="35040.34">
        <span className="tb-amount">
          {partnerActualShares}
          {' '}
          <span className="currency" />
        </span>
      </td>
      <td className="nk-tb-col tb-col-md">
        <span>{NDpercent > 0 ? `${NDpercent}%` : '-'}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{FDpercent > 0 ? `${FDpercent}%` : '-'}</span>
      </td>

      <td className="nk-tb-col nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="drodown">
              <MenuDots
                menuOptions={menuOptions}
                id={partner['_id']}
                secondaryId={partner['_id']}
                direction="left"
              />
            </div>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default PartnerRow;
