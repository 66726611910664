import React from 'react';

function Badge({ creationDate, isAdmin }) {
  const creationYear = creationDate.getFullYear();
  const currentYear = new Date().getFullYear();

  const yearsAgo = currentYear - creationYear;

  const badgeIcon = isAdmin ? 'calendar-alt' : 'clock';
  let badgeType;

  switch (yearsAgo) {
    case 0:
      badgeType = 'danger';
      break;
    case 1:
    case 2:
    case 3:
      badgeType = 'warning';
      break;
    default:
      badgeType = 'light text-gray';
  }

  return (
    <span className={`badge badge-dim badge-${badgeType}`}>
      <em className={`icon ni ni-${badgeIcon}`} />
      {
        isAdmin
          ? (<span>{creationDate.toLocaleDateString('es-ES')}</span>)
          : ((yearsAgo && (<span>{`${yearsAgo} años`}</span>)) || (<span> Menos de un año</span>))
      }
    </span>
  );
}

export default Badge;
