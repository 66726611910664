import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TransactionsList from 'pages/TransactionsList';
import ParticipatedTable from 'pages/ParticipatedTable';

import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';
import menuOptions from './menuOptions';

import '../../assets/styles/Misc.scss';
import '../../assets/styles/Layouts.scss';
import '../../assets/styles/Components.scss';
import '../../assets/styles/Bootstrap.scss';
import './Dashboard.scss';

function Dashboard({ actions }) {
  useEffect(() => actions.setMenuType(menuTypes.MAIN), [actions]);

  return (
    <div className="nk-content-inner">
      <ParticipatedTable alternativeMenuOptions={menuOptions} />
      <TransactionsList />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(Dashboard);
