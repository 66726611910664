/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
import menuTypes from "constants/menuTypes";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";

import { setMenu } from "redux/actions/menuActions";

import { getActualPartner, getActualSociety } from "utils/filters";
import { dayMonYea } from "utils/formatDate";

import operationTypes from "constants/operationTypes";

import "./TransactionDetails.scss";

const operationTypesRealName = {
  CONSTITUTION: "Constitución",
  CAPITAL_INCREASE: "Ampliación de capital",
  CAPITAL_DECREASE: "Reducción de capital",
  SELL_PARTICIPATION: "Compraventa de participaciones",
  STOCK_OPTION: "Opciones sobre acciones",
  PHANTOM_SHARE: "Opciones sobre acciones",
};

function TransactionDetails({ user, actualSociety, actions }) {
  const { societyId, transactionId } = useParams();

  const [actualTransaction, setActualTransaction] = useState();

  const [totalNominal, setTotalNominal] = useState();
  const [totalSharePremium, setSharePremium] = useState();
  const [totalShares, setTotalShares] = useState();
  const [totalSum, setTotalSum] = useState();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (actualSociety?.["_id"] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(
    () =>
      actions.setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.["_id"],
        societyName: actualSociety?.name,
      }),
    [actions, actualSociety]
  );

  useEffect(
    () =>
      setActualTransaction(
        actualSociety?.operations.find(
          (operation) => operation["_id"] === transactionId
        )
      ),
    [actualSociety, transactionId]
  );

  useEffect(() => {
    setTotalNominal(
      actualSociety?.nominalValue * actualTransaction?.movements.length
    );
    setSharePremium(
      actualTransaction?.sharePremium * actualTransaction?.movements.length
    );
  }, [actualSociety, actualTransaction]);

  useEffect(() => {
    let totalShares = 0;
    let totalSum = 0;
    const rows = actualTransaction?.movements.map((movement) => {
      const {
        id: { firstName, lastName },
      } = getActualPartner(actualSociety, movement.partner.id);
      const name = firstName + (lastName ? " " + lastName : "");

      const shares = movement.shareTo - movement.shareFrom + 1;
      totalShares += shares;

      const total =
        shares * actualSociety.nominalValue + actualTransaction.sharePremium;
      totalSum += total;

      const nominalValue = actualSociety.nominalValue + "€";
      const sharePremium = actualTransaction.sharePremium + "€";

      let nominalValueTD;
      let sharePremiumTD;
      let sharesTD;
      let totalTD;

      switch (actualTransaction.operationType) {
        case operationTypes.SELL_PARTICIPATION:
          sharePremiumTD = (
            <td>{(movement.movementType ? "-" : "+") + sharePremium}</td>
          );
          nominalValueTD = <td>-</td>;
          sharesTD = <td>{(movement.movementType ? "-" : "+") + shares}</td>;
          totalTD = <td>{(movement.movementType ? "-" : "+") + total}€</td>;
          break;
        default:
          sharePremiumTD = <td>{sharePremium}</td>;
          nominalValueTD = <td>{nominalValue}</td>;
          sharesTD = <td>{shares}</td>;
          totalTD = <td>{total}€</td>;
          break;
      }

      return (
        <tr key={JSON.stringify(actualTransaction)}>
          <td>{name}</td>
          <td>{operationTypesRealName[actualTransaction.operationType]}</td>
          {nominalValueTD}
          {sharePremiumTD}
          {sharesTD}
          {totalTD}
        </tr>
      );
    });
    setTotalShares(totalShares);
    setTotalSum(totalSum);
    if (rows) setRows(rows);
  }, [actualSociety, actualTransaction, transactionId]); // Not add totalShares

  return !actualTransaction ? (
    <>Cargando...</>
  ) : (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Transacción{" "}
                <strong className="text-primary small">#{societyId}</strong>
              </h3>
            </div>
            <div className="nk-block-head-content">
              <a className="btn btn-outline-light bg-white d-none d-sm-inline-flex is-disable">
                <em className="icon ni ni-arrow-left" />
                <span>Volver</span>
              </a>
              <a className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none">
                <em className="icon ni ni-arrow-left" />
              </a>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="invoice">
            <div className="invoice-action">
              <a className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary is-disable">
                <em className="icon ni ni-printer-fill" />
              </a>
            </div>
            <div className="invoice-wrap">
              <div className="invoice-brand text-center">
                <div className="user-avatar lg bg-primary">
                  {actualSociety.additional.logo ? (
                    <img src={actualSociety.additional.logo} alt="Logo" />
                  ) : (
                    <span>{actualSociety.name.slice(0, 2).toUpperCase()}</span>
                  )}
                </div>
              </div>
              <div className="invoice-head">
                <div className="invoice-contact">
                  <span className="overline-title">Tipo de transacción</span>
                  <div className="invoice-contact-info">
                    <h4 className="title">
                      {operationTypesRealName[actualTransaction?.operationType]}
                    </h4>
                    <ul className="list-plain">
                      <li>
                        <em className="icon ni ni-calender-date" />
                        <span>{dayMonYea(actualTransaction?.date)}</span>
                      </li>
                      <li>
                        <em className="icon ni ni-info" />
                        <span>
                          Nota o comentarios que el administrador desee incluir.
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="invoice-bills">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Socio</th>
                        <th>Descripción</th>
                        <th>Valor Nominal</th>
                        <th>Prima</th>
                        <th>Nº Part.</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="1">&nbsp;</td>
                        <td colSpan="1">Total</td>
                        <td>{totalNominal}€</td>
                        <td>{totalSharePremium}€</td>
                        <td>{totalShares}</td>
                        <td>{totalSum}€</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
