const initialState = {
  user: null,
  partnerBook: null,
  menu: { type: 'MAIN' },
  sideNav: false,
  alerts: [],
  document: [],
  modal: null,
  society: null,
};

export default initialState;
