/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import { getPartnerBook } from 'redux/actions/partnerBookActions';

import { dayMonYea } from 'utils/formatDate';
import { getActualSociety } from 'utils/filters';

import OperationMenuDots from 'components/MenuDots/OperationMenuDots';
import operationTypes from 'constants/operationTypes';

import menuOptions from '../PartnerList/menuOptions';

import './PartnerBook.scss';

const columns = {
  CONSTITUTION: { colSpan: 2 },
  CAPITAL_INCREASE: { colSpan: 3 },
  STOCK_OPTION: { colSpan: 3 },
  PHANTOM_SHARE: { colSpan: 3 },
  CAPITAL_DECREASE: { colSpan: 3 },
  SELL_PARTICIPATION: { colSpan: 4 },
};

const text = 'Constitución';

const titleColumn = {
  NUEVAS: 'Nuevas',
  TOTAL: 'Total',
  PERCENT: '%',
};

function PartnerBook() {
  const history = useHistory();
  const { societyId } = useParams();

  const dispatch = useDispatch();
  const { user, partnerBook, actualSociety } = useSelector(
    ({ user, society, partnerBook }) => ({
      user,
      partnerBook: societyId === partnerBook?.societyId ? partnerBook : null,
      actualSociety: society?.actualSociety,
    })
  );

  const [bookDates, setBookDates] = useState([]);
  const [bookColumns, setBookColumns] = useState([]);
  const [bookHeaders, setBookHeaders] = useState([]);
  const [bookPartners, setBookPartners] = useState([]);
  const [bookOperations, setBookOperations] = useState([]);
  const [bookTotals, setBookTotals] = useState([]);

  const [bookTotalPartners, setBookTotalPartners] = useState();
  const [bookOrder, setBookOrder] = useState([]);

  const [filtredMenuOptions, setFiltredMenuOptions] = useState([]);

  const [csvData, setCsvData] = useState([]);

  function goBack() {
    history.goBack();
  }

  function collectCsv() {
    const joinedTable = [
      Object.values(partnerBook.headers),
      Object.values(partnerBook.dates).map((data) =>
        data !== '' ? dayMonYea(data) : ''
      ),
      Object.values(partnerBook.columns),
      ...Object.values(partnerBook.partners).map((partner) => partner),
      Object.values(partnerBook.totals),
    ];
    setCsvData(joinedTable);
  }

  function filterValue(id, index, data, column) {
    if (data !== 0 && column === titleColumn.PERCENT)
      return `${parseFloat(data).toFixed(2)}%`;
    if (data !== 0 && column !== titleColumn.PERCENT) return data;

    if (column === titleColumn.TOTAL) {
      if (
        bookOperations[index - 1] === operationTypes.CAPITAL_DECREASE &&
        bookPartners[id][index - 1]
      )
        return 0;
      if (
        bookOperations[index - 2] === operationTypes.SELL_PARTICIPATION &&
        bookPartners[id][index - 1]
      )
        return 0;
    }

    if (column === titleColumn.PERCENT) {
      if (
        bookOperations[index - 2] === operationTypes.CAPITAL_DECREASE &&
        bookPartners[id][index - 2]
      )
        return '0.00%';
      if (
        bookOperations[index - 3] === operationTypes.SELL_PARTICIPATION &&
        bookPartners[id][index - 2]
      )
        return '0.00%';
    }

    return '';
  }

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(
    () =>
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      ),
    [actualSociety, dispatch]
  );

  useEffect(() => {
    if (!actualSociety?.partners?.length)
      setFiltredMenuOptions([menuOptions[0]]);
    else if (!actualSociety?.shares?.length) {
      setFiltredMenuOptions([menuOptions[0], menuOptions[1]]);
    } else {
      const options = menuOptions.filter((option) => option.text !== text);
      setFiltredMenuOptions(options);
    }
  }, [actualSociety, societyId]);

  useEffect(() => {
    if (societyId) dispatch(getPartnerBook(societyId));
  }, [societyId, dispatch, actualSociety]);

  useEffect(() => {
    if (partnerBook) {
      setBookDates(partnerBook.dates);
      setBookColumns(partnerBook.columns);
      setBookHeaders(partnerBook.headers);
      setBookPartners(partnerBook.partners);
      setBookOperations(partnerBook.operations);
      setBookTotals(partnerBook.totals);
      setBookOrder(partnerBook.order);
      setBookTotalPartners(Object.keys(partnerBook).length);
      collectCsv();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerBook]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {actualSociety?.name}
              </h3>
              <p />
            </div>
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Libro de socios</h3>
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <a
                          className="btn btn-white btn-outline-light"
                          aria-label="link"
                          onClick={() => goBack()}
                        >
                          <em className="icon ni ni-arrow-left" />
                          <span>Volver</span>
                        </a>
                      </li>
                      <li>
                        <CSVLink
                          data={csvData}
                          filename={`Libro de Socios - ${actualSociety?.name}.csv`}
                          className="btn btn-white btn-outline-light"
                          aria-label="link"
                        >
                          <em className="icon ni ni-file-download" />
                          <span>Exportar</span>
                        </CSVLink>
                      </li>
                      <li className="preview-item">
                        <OperationMenuDots
                          menuOptions={filtredMenuOptions}
                          handleModal={(Modal) =>
                            dispatch(
                              setModal(
                                <Modal
                                  user={user}
                                  societyId={actualSociety['_id']}
                                />
                              )
                            )
                          }
                        />
                      </li>
                    </ul>

                    <div className="nk-block nk-block-lg">
                      <div className="nk-block-head" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {partnerBook && (
            <>
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="table-responsive">
                    <table className="table table-striped text-right cloned pos-abs bg-white border-right">
                      <tr className="small text-center">
                        <th className="w-200px bg-light">&nbsp;</th>
                      </tr>
                      <tr className="small text-center">
                        <td>Socios</td>
                      </tr>

                      {Object.entries(bookPartners).map(
                        ([partnerId, partnerName], index) => (
                          <>
                            <tr key={partnerId}>
                              <td
                                className={
                                  !(index % 2)
                                    ? 'bg-light text-left w-200px'
                                    : 'text-left w-200px'
                                }
                              >
                                {partnerName[0]}
                              </td>
                            </tr>
                          </>
                        )
                      )}

                      <tr
                        className={
                          !(bookTotalPartners % 2)
                            ? 'bg-light font-weight-bold'
                            : 'font-weight-bold'
                        }
                      >
                        <td className="text-left">TOTAL</td>
                      </tr>
                    </table>

                    <table
                      className="table table-striped text-right border-right"
                      style={{
                        width: `${
                          (Object.entries(partnerBook.currency).length - 1) *
                            210 +
                          210
                        }px`,
                      }}
                    >
                      <tr className="small text-center">
                        <th className="w-200px">&nbsp;</th>

                        {Object.entries(bookHeaders).map(
                          ([, header], index) =>
                            header !== '' && (
                              <th
                                key={index}
                                colSpan={
                                  bookOperations[index] !== '' &&
                                  columns[bookOperations[index]].colSpan
                                }
                                className={
                                  bookOrder[index] % 2 && 'bg-light-vert'
                                }
                              >
                                {bookHeaders[index] !== '' &&
                                  bookHeaders[index]}
                                <br />
                                {bookDates[index] !== '' &&
                                  dayMonYea(bookDates[index])}
                              </th>
                            )
                        )}
                      </tr>

                      <tr className="small text-center">
                        {Object.entries(bookColumns).map(([, column], index) =>
                          index === 0 ? (
                            <td>Socio</td>
                          ) : (
                            <td
                              className={
                                bookOrder[index] % 2 && 'bg-light-vert'
                              }
                            >
                              {column !== '' && column}
                            </td>
                          )
                        )}
                      </tr>

                      {Object.entries(bookPartners).map(
                        ([partnerId, partnerData], index) => (
                          <>
                            <tr
                              key={partnerId}
                              className={!(index % 2) ? 'bg-light' : ''}
                            >
                              {partnerData.map((data, index) => (
                                <td
                                  className={
                                    bookOrder[index] % 2
                                      ? 'bg-light-vert text-right'
                                      : 'text-right'
                                  }
                                  key={JSON.stringify(data)}
                                >
                                  {filterValue(
                                    partnerId,
                                    index,
                                    partnerData[index],
                                    bookColumns[index]
                                  )}
                                </td>
                              ))}
                            </tr>
                          </>
                        )
                      )}

                      <tr
                        className={
                          !(bookTotalPartners % 2)
                            ? 'bg-light font-weight-bold'
                            : 'font-weight-bold'
                        }
                      >
                        {Object.entries(bookTotals).map(([, total], index) =>
                          index === 0 ? (
                            <td>TOTAL</td>
                          ) : (
                            <td
                              className={
                                bookOrder[index] % 2 ? 'bg-light-vert' : ''
                              }
                            >
                              {bookColumns[index] === '%'
                                ? total !== '' &&
                                  `${parseFloat(total).toFixed(2)}%`
                                : total !== '' && total}
                            </td>
                          )
                        )}
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default PartnerBook;
