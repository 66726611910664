import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(menu = initialState.menu, action) {
  switch (action.type) {
    case types.SET_MENU_TYPE:
      return { ...menu, type: action.menuType };

    case types.SET_MENU:
      return action.menu;

    default:
      return menu;
  }
}
