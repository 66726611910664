import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import store from 'redux/store';
import alertOptions from './alertOptions';

import { removeAlert } from '../../redux/actions/alertActions';
import './Alert.scss';

function LegalAlert({
  title, html, footer, type,
}) {
  const myAlert = withReactContent(Swal);

  myAlert
    .fire({
      showCloseButton: true,
      iconColor: alertOptions[type].iconColor,
      iconHtml: alertOptions[type].iconHtml,
      title,
      html,
      footer,
      confirmButtonText: 'Cerrar',
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      willClose: () => store.dispatch(removeAlert()),
    });

  return (
    <></>
  );
}

export default LegalAlert;
