/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getActualSociety } from 'utils/filters';
import { dayMonthYear } from 'utils/formatDate';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import menuTypes from 'constants/menuTypes';

import DocumentModal from './documentModal';

function SocietyDocuments({ user, actualSociety, actions }) {
  const { societyId } = useParams();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    setRows(
      actualSociety?.documents.map((document) => (
        <tr className="tb-ticket-item is-unread" key={document?.['_id']}>
          <td className="tb-ticket-id">#{document?.['_id'].slice(0, 6)}</td>
          <td className="tb-ticket-desc">
            <a>
              <span className="title">{document?.name}</span>
            </a>
          </td>
          <td className="tb-ticket-date tb-col-md">
            <span className="date">{dayMonthYear(document?.date)}</span>
          </td>
          <td className="tb-ticket-seen tb-col-md">
            <span className="date-last">
              <Link to="/detalle-socio/">
                <em
                  className="icon-avatar bg-danger-dim icon ni ni-user-fill nk-tooltip"
                  title="Support Team"
                />
                {document?.author?.firstName}
                {document?.author?.lastName}
              </Link>
            </span>
          </td>
          <td className="tb-ticket-action">
            <a
              className="btn btn-icon btn-trigger"
              href={document?.url}
              download
            >
              <em className="icon ni ni-file-download" />
            </a>
          </td>
        </tr>
      ))
    );
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      actions.setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety['_id'],
        societyName: actualSociety?.name,
      });
    }
  }, [actions, actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">Documentos</h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`Tienes ${actualSociety?.documents?.length} documentos de ${actualSociety?.name}`}
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  actions.setModal(
                    <DocumentModal
                      userId={user['_id']}
                      societyId={actualSociety['_id']}
                    />
                  )
                }
              >
                Añadir documento
              </button>
            </div>
          </div>
        </div>
        {rows?.length > 0 ? (
          <div className="nk-block">
            <div className="card card-bordered">
              <table className="table table-tickets">
                <thead className="tb-ticket-head">
                  <tr className="tb-ticket-title">
                    <th className="tb-ticket-id">
                      <span>Nº</span>
                    </th>
                    <th className="tb-ticket-desc">
                      <span>Documento</span>
                    </th>
                    <th className="tb-ticket-date tb-col-md">
                      <span>Añadido</span>
                    </th>
                    <th className="tb-ticket-seen tb-col-md">
                      <span>Autor</span>
                    </th>
                    <th className="tb-ticket-action"> &nbsp; </th>
                  </tr>
                </thead>
                <tbody className="tb-ticket-body">{rows}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu, setModal }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietyDocuments);
