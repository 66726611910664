import operationTypes from 'constants/operationTypes';
import { setSociety } from 'redux/actions/societyActions';
import store from 'redux/store';

export function getActualSociety(user, societyId) {
  if (!user || !societyId) return null;
  const result = {};
  const {
    societies: { participated, administrated },
    usersWithAccess,
  } = user;

  result.society = administrated.find(
    (society) => society['_id'] === societyId
  );
  if (result.society) result.isAdmin = true;
  else {
    result.society = participated.find(
      (society) => society['_id'] === societyId
    );
    if (!result.society) {
      usersWithAccess.forEach((userWithAccess) => {
        const { participated, administrated } = userWithAccess.societies;
        result.society = administrated.find(
          (society) => society['_id'] === societyId
        );
        if (result.society) result.isAdmin = true;
        else
          result.society = participated.find(
            (society) => society['_id'] === societyId
          );
      });
    }
  }
  store.dispatch(setSociety(result));
}

export function getActualPartner(society, partnerId) {
  if (!society || !partnerId) return null;
  const partner = society.partners.find(
    (partner) => partner.id['_id'] === partnerId
  );
  return partner || { id: null };
}

export function getActualAdmin(society, admin) {
  if (!society || !admin) return null;
  const mainAdmin = society?.administrators.find(
    (currentAdmin) => currentAdmin['_id'] === admin.id
  );
  return mainAdmin ? `${admin.cif} | ${mainAdmin.email || ''}` : '-';
}

export function getPartnerNumber(society, partnerId) {
  if (!society || !partnerId) return null;
  const partner = society.partners.find(
    (partner) => partner.id['_id'] === partnerId
  );
  const partnerNumber = partner.id.partnerNumber.find(
    (actualSociety) => actualSociety.society === society['_id']
  );
  return partnerNumber;
}

export function getPartnerOperations(partner, society) {
  if (!society || !partner) return null;

  const partnerOperationsInSociety = society.operations.filter((operation) =>
    partner.operations?.some((operationId) => operationId === operation['_id'])
  );
  return partnerOperationsInSociety.filter(
    (operation) => operation.operationType !== operationTypes.ADD_PARTNER
  );
}

export function countOperationShares(operation) {
  if (!operation) return operation;

  return operation?.movements?.reduce(
    (acc, { shareFrom, shareTo }) => acc + (shareTo - shareFrom + 1),
    0
  );
}

export function getPartnerInversion(partnerShares, society) {
  if (!society) return null;

  const shares = society.shares.filter((share) =>
    partnerShares?.some((shareId) => shareId === share['_id'])
  );
  const result = shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;

    let result;
    if (share.sharePremium)
      result = (share.sharePremium + society.nominalValue) * shareCount;
    if (share.sharePrice) result = share.sharePrice * shareCount;

    return acc + result;
  }, 0);
  return result.toFixed(2).replace(/\.00$/, '');
}

export function getSocietyInversion(society) {
  if (!society) return null;

  return society.shares.reduce((acc, share) => {
    const shareCount = share.to - share.from + 1;

    let result;
    if (share.sharePremium)
      result = (share.sharePremium + society.nominalValue) * shareCount;
    if (share.sharePrice) result = share.sharePrice * shareCount;

    return acc + result;
  }, 0);
}
