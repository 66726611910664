/* eslint-disable no-console */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from 'redux/actions/alertActions';
import { addPartner } from 'redux/actions/modalsActions';
import { setModal } from 'redux/actions/modalActions';
import { fetchIdentities } from 'redux/actions/userActions';
import { getSocietyByCif } from 'redux/actions/societyActions';

import '../Modals.scss';

function AddPartnerModal({ user, actualSociety, dispatch }) {
  const [email, setEmail] = useState('');
  const [cif, setCif] = useState();
  const [identities, setIdentities] = useState([]);
  const [name, setName] = useState('');
  const [nameReadOnly, setNameReadOnly] = useState(false);

  function loadIdentities() {
    if (!email.trim()) return;

    fetchIdentities(email)
      .then(({ data = [] }) => {
        setIdentities(data);
      })
      .catch(() => setIdentities([]));
  }

  async function loadSocietyByCif(selectedCif) {
    if (!selectedCif) return;

    const societyCif = await getSocietyByCif(selectedCif);
    setName(societyCif?.name);
    setNameReadOnly(!!societyCif.name);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function savePartner(event) {
    event.preventDefault();

    const isPartnerAlready = actualSociety?.partners.find(
      (partner) => partner.email?.toLowerCase() === email?.toLowerCase()
    );

    const isCifAlready = isPartnerAlready?.identities?.find(
      (identity) => identity?.toLowerCase() === cif?.toLowerCase()
    );

    if (isPartnerAlready && isCifAlready) {
      dispatch(addAlert(alertBodyTypes.DUPLICATED_EMAIL));
    } else {
      dispatch(
        addPartner({
          email,
          society: actualSociety['_id'],
          partner: user['_id'],
          societyName: actualSociety?.name,
          userEmail: user.email,
          cif,
        })
      );
      dispatch(setModal(null));
    }
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Socio</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  Correo electrónico
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email del nuevo socio"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={loadIdentities}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                  />
                </div>
              </div>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left" />
                <Form.Group>
                  <Form.Label>Identificación fiscal</Form.Label>
                  <Typeahead
                    id="identity-search-box"
                    onChange={([selectedCif]) => setCif(selectedCif)}
                    onInputChange={(text) => {
                      setCif(text);
                    }}
                    onBlur={() => loadSocietyByCif(cif)}
                    filterBy={() => true}
                    minLength={1}
                    options={identities}
                    placeholder="Introduce el CIF o NIF..."
                  />
                </Form.Group>
              </div>
              <div className="form-group mt-4">
                <label className="form-label" htmlFor="partner-name">
                  Nombre del Socio/Sociedad
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-user" />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    readOnly={nameReadOnly}
                    placeholder="Nombre y Apellido"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name || !email}
          >
            Añadir Socio
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">
          El socio recibirá una notificación por email
        </span>
      </Modal.Footer>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(AddPartnerModal);
