import alertTypes from 'constants/alertTypes';

export default {
  500: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Internal server error</h4>',
  },
  401: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en el acceso</h4>',
    html: '<div class="caption-text">Usuario o contraseña desconocidos</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Pruebe añadir una combinación diferente</p>
    </div></div>`,
  },
  DUPLICATED_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en el registro</h4>',
    html: '<div class="caption-text">Ya existe un usuario con estos datos</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Pruebe añadir una dirección de correo diferente</p>
    </div></div>`,
  },
  ERROR_TRANSACTION: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error en la transacción</h4>',
    html: '<div class="caption-text">Hubo un problema al crear esta transacción</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  INFORMATION_NO_COMPLETE: {
    type: alertTypes.ERROR,
    title:
      '<h4 class="nk-modal-title">No has introduccido todos los datos</h4>',
    html: '<div class="caption-text">Escribe todos los datos requeridos</div>',
  },
  SAME_BUY_AND_SELL_USER: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">El comprador y el vendedor no pueden ser el mismo usuario</h4>',
    html: '<div class="caption-text">Modifica uno de los dos</div>',
  },
  SELECT_USER: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Seleciona un usuario en el menu desplegable</h4>',
    html: '<div class="caption-text">Seleciona al usuario y vuelve a intenrarlo</div>',
  },
  ERROR_UPDATING_USER: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al actualizar el usuario</h4>',
    html: '<div class="caption-text">Hubo un problema al guardar los datos de este usuario</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el correo</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar los datos a este usuario</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_CONTACT_EMAIL: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al enviar el correo</h4>',
    html: '<div class="caption-text">Hubo un problema al enviar esta solicitud</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_UPLOADING_IMAGE: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al guardar la imagen</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  ERROR_CREATING_DOCUMENT: {
    type: alertTypes.ERROR,
    title: '<h4 class="nk-modal-title">Error al añadir el Documento</h4>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
  <p>Consulte al administrador para más detalles</p>
  </div></div>`,
  },
  USER_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Usuario actualizado</h4>',
  },
  SOCIETY_UPDATED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Sociedad actualizada</h4>',
  },
  SOCIETY_ADDED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Sociedad añadida correctamente</h4>',
  },
  DOCUMENT_ADDED: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Documento añadido correctamente</h4>',
  },
  CONTACT_EMAIL_SENT: {
    type: alertTypes.SUCCESS,
    title: '<h4 class="nk-modal-title">Solicitud enviada correctamente</h4>',
  },
};
