export default {
  SUCCESS: {
    iconColor: '#1ee0ac',
    iconHtml: '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>',
  },
  WARNING: {
    iconColor: '#f4bd0e',
    iconHtml: '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-warning"></em>',
  },
  ERROR: {
    iconColor: '#e85347',
    iconHtml: '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>',
  },
};
