export default {
  ADD_PARTNER: 'ADD_PARTNER',
  CONSTITUTION: 'CONSTITUTION',
  CAPITAL_INCREASE: 'CAPITAL_INCREASE',
  CAPITAL_DECREASE: 'CAPITAL_DECREASE',
  SELL_PARTICIPATION: 'SELL_PARTICIPATION',
  STOCK_OPTION: 'STOCK_OPTION',
  PHANTOM_SHARE: 'PHANTOM_SHARE',

  ADD_PARTNER_FILENAME: 'Añadir Socio',
  CONSTITUTION_FILENAME: 'Constitución de Sociedad',
  CAPITAL_INCREASE_FILENAME: 'Aumento de Capital',
  CAPITAL_DECREASE_FILENAME: 'Reducción de Capital',
  SELL_PARTICIPATION_FILENAME: 'Venta de Participaciones',
  STOCK_OPTION_FILENAME: 'Stock Option',
  PHANTOM_SHARE_FILENAME: 'Phantom Share',
};
