import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import toggleSideNav from 'redux/actions/sideNavActions';

import './SideNav.scss';

function SecondaryMenu({ dispatch }) {
  return (
    <ul className="nk-menu nk-menu-sm">
      <li className="nk-menu-heading">
        <span>Cuenta</span>
      </li>
      <li className="nk-menu-item">
        <Link to="/perfil-usuario/datos" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
          <span className="nk-menu-icon">
            <em className="icon ni ni-user-round" />
          </span>
          <span className="nk-menu-text">Mis datos</span>
        </Link>
      </li>
      <li className="nk-menu-item">
        <Link
          to="/"
          className="nk-menu-link is-disable"
        >
          <span className="nk-menu-icon">
            <em className="icon ni ni-report-profit" />
          </span>
          <span className="nk-menu-text">Suscripción</span>
        </Link>
      </li>
      <li className="nk-menu-item">
        <Link to="/contacto" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
          <span className="nk-menu-icon">
            <em className="icon ni ni-external" />
          </span>
          <span className="nk-menu-text">Contactar</span>
        </Link>
      </li>
      <li className="nk-menu-item">
        <Link to="/ayuda-soporte" className="nk-menu-link" onClick={() => dispatch(toggleSideNav())}>
          <span className="nk-menu-icon">
            <em className="icon ni ni-info" />
          </span>
          <span className="nk-menu-text">Ayuda/Soporte</span>
        </Link>
      </li>
    </ul>

  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(SecondaryMenu);
