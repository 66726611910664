import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSociety as createSocietyAction } from 'redux/actions/societyActions';

import '../../assets/styles/Misc.scss';
import './SocietiesAdd.scss';

function SocietiesAdd({ user, dispatch }) {
  const history = useHistory();

  const [page, setPage] = useState(0);

  const [socialDenomination, setSocialDenomination] = useState('');
  const [CIF, setCIF] = useState('');
  const [web, setWeb] = useState('');
  const [phone, setPhone] = useState('');

  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');

  const [privacy, setPrivacy] = useState(false);

  function createSociety(event) {
    event.preventDefault();
    const society = {
      name: socialDenomination,
      cif: CIF,
      web,
      phone,
      legalAddress: {
        line1,
        line2,
        zip,
        city,
        state,
        country,
      },
      administrators: [user['_id']],
    };
    dispatch(createSocietyAction(society));
    history.push('/cuadro-administradas');
  }

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="components-preview wide-md mx-auto">
          <div className="nk-block-head nk-block-head-lg wide-sm">
            <div className="nk-block-head-content">
              <h2 className="nk-block-title fw-normal">Añadir Sociedad</h2>
              <div className="nk-block-des">
                <p className="lead">
                  Usando el siguiente formulario puede dar de alta una nueva
                  sociedad. Cuando añadas una sociedad por defecto serás el
                  administrador y podrás dar de alta socios y otros
                  administradores.
                </p>
              </div>
            </div>
          </div>
          <div className="nk-block nk-block-lg">
            <div className="nk-block-head">
              <div className="nk-block-head-content">
                <h4 className="title nk-block-title">
                  Formulario para dar de alta una Sociedad
                </h4>
                <div className="nk-block-des">
                  <p>Indica la información en cada campo</p>
                </div>
              </div>
            </div>
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="steps clearfix mb-4">
                  <ul role="tablist">
                    <li role="tab" className="first current">
                      <span className="current-info audible">
                        current step:
                      </span>
                      <span className="number">1.</span>
                      <h5>Paso 1 - Información</h5>
                    </li>
                    <li
                      role="tab"
                      className={`disabled last" ${page ? 'current' : ''}`}
                      aria-disabled="true"
                    >
                      <span className="number">2.</span>
                      <h5>Paso 2 - Dirección</h5>
                    </li>
                  </ul>
                </div>
                <div className="content clearfix">
                  {!page ? (
                    <form
                      onSubmit={(event) => {
                        event.preventDefault();
                        setPage(1);
                      }}
                    >
                      <div
                        className={`nk-wizard-head ${!page ? 'current' : ''}`}
                        id="steps-uid-0-h-0"
                        tabIndex="-1"
                      >
                        <h5>Paso 1 - Info basica</h5>
                      </div>
                      <div
                        className="nk-wizard-content body current"
                        id="steps-uid-0-p-0"
                        role="tabpanel"
                        aria-labelledby="steps-uid-0-h-0"
                        aria-hidden="false"
                      >
                        <div className="row gy-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-first-name"
                              >
                                Denominación Social
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  required
                                  type="text"
                                  className="form-control required"
                                  value={socialDenomination}
                                  onChange={(event) => setSocialDenomination(event.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-last-name"
                              >
                                Web
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control "
                                  value={web}
                                  onChange={(event) => setWeb(event.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label w-100">
                                Telefono
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  required
                                  type="text"
                                  className="form-control "
                                  value={phone}
                                  onChange={(event) => setPhone(event.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-mobile-number"
                              >
                                CIF
                                <div className="form-control-wrap">
                                  <input
                                    required
                                    type="text"
                                    className="form-control "
                                    value={CIF}
                                    onChange={(event) => setCIF(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-primary mt-4" type="submit">
                        Siguiente
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={createSociety}>
                      <div
                        className={`nk-wizard-head ${page ? 'current' : ''}`}
                        id="steps-uid-0-h-1"
                        tabIndex="-1"
                      >
                        <h5>Paso 2 - Dirección completa</h5>
                      </div>
                      <div
                        className="nk-wizard-content body"
                        id="steps-uid-0-p-1"
                        role="tabpanel"
                        aria-labelledby="steps-uid-0-h-1"
                        aria-hidden="true"
                      >
                        <div className="row gy-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Dirección línea 1
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control required"
                                    value={line1}
                                    onChange={(event) => setLine1(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Dirección línea 2
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={line2}
                                    onChange={(event) => setLine2(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row gy-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Codigo Postal
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    maxLength="5"
                                    minLength="5"
                                    className="form-control"
                                    value={zip}
                                    onChange={(event) => setZip(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Ciudad
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row gy-3">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Pais
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={country}
                                    onChange={(event) => setCountry(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="fw-username"
                              >
                                Provincia
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={state}
                                    onChange={(event) => setState(event.target.value)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mt-3 mb-5">
                          <div className="custom-control custom-control-xs custom-checkbox">
                            <input
                              type="checkbox"
                              id="checkbox"
                              className="custom-control-input"
                              value={privacy}
                              onChange={(event) => setPrivacy(event.target.checked)}
                              required
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkbox"
                            >
                              Acepto
                              {' '}
                              <a href="https://socio.legal/#/privacidad" target="_blank" rel="noreferrer">
                                Política de Privacidad
                              </a>
                              {' '}
                              y
                              {' '}
                              <a href="https://socio.legal/#/aviso-legal" target="_blank" rel="noreferrer">
                                Términos y Condiciones
                              </a>
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary mr-4"
                        onClick={() => setPage(0)}
                      >
                        Anterior
                      </button>

                      <button type="submit" className="btn btn-primary">
                        Enviar
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(SocietiesAdd);
