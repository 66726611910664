export default function adjustImageSize(canvasId, imageId, size) {
  const canvas = document.getElementById(canvasId);
  const context = canvas.getContext('2d');
  const imageObj = document.getElementById(imageId);

  if (imageObj) {
    let imgWidth = imageObj?.naturalWidth;
    const screenWidth = canvas.width;
    let scaleX = 1;
    if (imgWidth > screenWidth) scaleX = screenWidth / imgWidth;
    let imgHeight = imageObj?.naturalHeight;
    const screenHeight = canvas.height;
    let scaleY = 1;
    if (imgHeight > screenHeight) scaleY = screenHeight / imgHeight;
    let scale = scaleY;
    if (scaleX < scaleY) scale = scaleX;
    if (scale < 1) {
      imgHeight *= scale;
      imgWidth *= scale;
    }

    canvas.height = size;
    canvas.width = size;

    const centerX = Math.round((size / 2) - (imgWidth / 2));
    const centerY = Math.round((size / 2) - (imgHeight / 2));

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, size, size);
    context.drawImage(
      imageObj, 0, 0, imageObj?.naturalWidth,
      imageObj?.naturalHeight, centerX, centerY, imgWidth, imgHeight,
    );
    return canvas.toDataURL();
  }
}
