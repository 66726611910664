/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';

function PartnerPersonal({ partner, incorporationDate }) {
  const [address, setAddress] = useState();

  useEffect(() => {
    const { line1, zip, city, country } = partner.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [partner.address]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <h5 className="title">Información personal</h5>
        <p>Información personal básica del socio.</p>
        <div className="nk-block-head nk-block-head-line" />
        <div className="profile-ud wider">
          <span className="profile-ud-label">Nombre completo</span>
          <span className="profile-ud-value">
            {`${partner.firstName} ${partner.lastName || ''}`}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">NIF</span>
          <span className="profile-ud-value">{partner.cif || '-'}</span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">Teléfono</span>
          <span className="profile-ud-value">{partner.phone || '-'}</span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">Dirección</span>
          <span className="profile-ud-value">{address}</span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">Email </span>
          <span className="profile-ud-value">{partner.email}</span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">Fecha incoporación</span>
          <span className="profile-ud-value">{incorporationDate}</span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label">Nacionalidad</span>
          <span className="profile-ud-value">{partner.nationality || '-'}</span>
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default PartnerPersonal;
