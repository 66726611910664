import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addDocument } from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';
import store from 'redux/store';

function DocumentModal({ userId, societyId }) {
  const [documentName, setDocumentName] = useState('');
  const [date, setDate] = useState('');
  const [file, setFile] = useState();

  async function saveDocument(event) {
    event.preventDefault();
    const newDocument = {
      file,
      date,
      name: documentName,
      author: userId,
      society: societyId,
    };
    const uploadedDocument = await store.dispatch(addDocument(newDocument));
    store.dispatch(
      updateSociety(societyId, {
        docs: {
          add: uploadedDocument,
        },
      })
    );
    store.dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Documento</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Nombre documento
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={documentName}
                onChange={(event) => setDocumentName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Fecha
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                max="2100-01-01"
                min="1000-01-01"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              Añadir documento
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept="application/pdf"
                  className="custom-file-input"
                  onChange={(event) => setFile(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file?.name || 'Seleccionar documento'}
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!documentName || !date || !file}
          >
            Guardar documento
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <span className="sub-text">
          Los documentos serán accesibles por todos los socios
        </span>
      </Modal.Footer>
    </>
  );
}

export default connect()(DocumentModal);
