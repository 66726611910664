import React from 'react';

import { dayMonYea } from 'utils/formatDate';

import MenuDots from 'components/MenuDots';
import menuOptions from './menuOptions';

function SocietyAdministratorRow({
  administrator,
  society,
  isAdmin,
  index,
}) {
  const creationDate = dayMonYea(administrator.creationDate);
  const lastAccess = dayMonYea(administrator.lastAccess);

  return (
    <tr className="tb-tnx-item">
      <td className="tb-tnx-id">
        <p>
          <span>{index + 1}</span>
        </p>
      </td>
      <td className="tb-tnx-info">
        <div className="tb-tnx-desc">
          <span className="title">{`${administrator.firstName} ${administrator.lastName || ''}`}</span>
        </div>
        <div className="tb-tnx-date">
          <span className="date">{creationDate}</span>
          <span className="date">{lastAccess}</span>
        </div>
      </td>
      <td className="tb-tnx-amount is-alt">
        <div className="tb-tnx-total">
          <span className="amount">{administrator.email}</span>
        </div>
        <div className="tb-tnx-status">
          <span className="badge badge-dot badge-danger">Completo</span>
        </div>
      </td>
      {isAdmin ? (
        <td className="tb-tnx-action">
          <MenuDots menuOptions={menuOptions} id={administrator['_id']} secondaryId={society?.['_id']} />
        </td>
      ) : <> </>}
    </tr>
  );
}

export default SocietyAdministratorRow;
