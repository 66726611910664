import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addDocument } from 'redux/actions/documentActions';
import { capitalDecreaseAction } from 'redux/actions/modalsActions';
import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';

import operationTypes from 'constants/operationTypes';

import { INITIAL_DATE, SELECIONAR_SOCIO } from 'constants/defaultConstants';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import UserDropdown from '../UserDropdown';

import '../Modals.scss';

const DECREMENT = 'DECREMENT';

function CapitalDecreaseModal({
  user,
  actualSociety,
  dispatch,
}) {
  const [step, setStep] = useState(0);

  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);

  const [actualFrom, setActualFrom] = useState(actualSociety.sharesCount?.actual + 1);

  const [date, setDate] = useState(INITIAL_DATE);
  const [totalParticipationsToDelete, setTotalParticipationsToDelete] = useState('');
  const [file, setFile] = useState();

  const [selectedPartner, setSelectedPartner] = useState(SELECIONAR_SOCIO);
  const [partnerParticipations, setPartnerParticipations] = useState('');

  const [lastOperationDate, setLastOperationDate] = useState();

  useEffect(() => {
    if (actualSociety) {
      let lastOperation = actualSociety.operations.reduce(
        (acc, op) => (new Date(acc.date) > new Date(op.date)
          ? acc
          : op),
      );

      // TODO Replace with moment and the proper format
      lastOperation = new Date(lastOperation.date).toISOString().replace(':00.000Z', '');
      setLastOperationDate(lastOperation || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  function updateSelectedPartnerShares() {
    const partner = actualSociety.partners.find(
      (partner) => partner['_id'] === selectedPartner,
    );

    partner.sharesCount.actual -= partnerParticipations;
  }

  function nextPartner() {
    if (selectedPartner === SELECIONAR_SOCIO) dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      const newMovement = {
        partner: JSON.parse(selectedPartner),
        shareFrom: 1,
        shareTo: +partnerParticipations,
        movementType: DECREMENT,
      };

      setMovements([...movements, newMovement]);

      setPartnerParticipations('');
      setActualFrom(actualFrom + +partnerParticipations);
      setMissingParticipations(missingParticipations - +partnerParticipations);
      setSelectedPartner(SELECIONAR_SOCIO);
      updateSelectedPartnerShares();
    }
  }

  function saveDecrease() {
    if (selectedPartner === SELECIONAR_SOCIO) dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      const finalMovements = [
        ...movements,
        {
          partner: JSON.parse(selectedPartner),
          shareFrom: 1,
          shareTo: +partnerParticipations,
          movementType: DECREMENT,
        },
      ];

      const newDocument = {
        file,
        date,
        name: operationTypes.CAPITAL_DECREASE_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
      };

      dispatch(capitalDecreaseAction({
        movements: finalMovements,
        date,
        society: actualSociety['_id'],
        partner: user['_id'],
      }));

      dispatch(addDocument(newDocument));
      dispatch(setModal(null));
    }
  }

  function nextStep(event) {
    event.preventDefault();
    if (!step) {
      setTotalParticipationsToDelete(+totalParticipationsToDelete);
      setMissingParticipations(+totalParticipationsToDelete);
      setStep(1);
    } else if (missingParticipations - +partnerParticipations) {
      nextPartner();
    } else {
      saveDecrease();
    }
  }

  function getPartnerMaxShares() {
    if (selectedPartner === SELECIONAR_SOCIO) return;

    const { cif } = JSON.parse(selectedPartner);
    const partner = actualSociety.partners.find(
      (partner) => partner.cif === cif,
    );

    if (partner) {
      const { sharesCount: { actual } } = partner;
      return missingParticipations > actual
        ? actual
        : missingParticipations;
    }
    return missingParticipations;
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Reducción de capital</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Fecha de la reducción de capital
                </label>
                <div className="form-control-wrap">
                  <input
                    type="datetime-local"
                    className="form-control date-picker"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={lastOperationDate}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Nº participaciones eliminadas
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Nº</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Nº de nuevas participaciones"
                    value={totalParticipationsToDelete}
                    onChange={(event) => setTotalParticipationsToDelete(event.target.value)}
                    required
                    min="1"
                    max={actualSociety?.sharesCount?.actual}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  Añadir escritura (opcional)
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="customFile"
                      type="file"
                      accept="application/pdf"
                      className="custom-file-input"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {file?.name || 'Seleccionar documento'}
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                Siguiente
              </button>
            </>
          ) : (
            <div>
              <label className="form-label">
                <span className="bg-warning p-2">
                  Total Participaciones pendientes de eliminar:
                </span>
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  {missingParticipations}
                </span>
              </label>
              <div className="form-group">
                <label className="form-label">
                  Nombre Socio
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-user" />
                  </div>
                  <UserDropdown
                    users={actualSociety.partners}
                    action={setSelectedPartner}
                    user={selectedPartner}
                    showShares
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Nº participaciones eliminadas
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Nº</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Nº de participaciones eliminadas"
                    value={partnerParticipations}
                    onChange={(event) => setPartnerParticipations(event.target.value)}
                    min="1"
                    max={getPartnerMaxShares()}
                    required
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {missingParticipations - +partnerParticipations ? 'Siguiente' : 'Guardar'}
              </button>
            </div>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(CapitalDecreaseModal);
