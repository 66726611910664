import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ParticipatedRow from 'components/SocietyRow';
import Pagination from 'components/Pagination';
import { bindActionCreators } from 'redux';
import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

function AdministratedList({ user, actions }) {
  useEffect(() => actions.setMenuType(menuTypes.MAIN), [actions]);

  const [societies, setSocieties] = useState([]);

  useEffect(() => {
    if (user) {
      const sharedSocieties = user.usersWithAccess.reduce((acc, user) => {
        const { administrated } = user.societies;

        const deleteDuplicated = administrated.filter((societyA) => !acc.some((societyB) => societyA['_id'] === societyB['_id']));

        return [...acc, ...deleteDuplicated];
      }, [...user.societies.administrated]);
      setSocieties(sharedSocieties);
    }
  }, [user, actions]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = societies.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const rows = currentItems.map((society) => (
    <ParticipatedRow society={society} key={society.name} isAdmin />
  ));
  const societiesTotal = societies?.length;

  const administratedTable = (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Sociedades Administradas
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`Tienes ${societiesTotal} sociedad(es) administradas(s).`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table className="nk-tb-list nk-tb-ulist">
                  <thead>
                    <tr className="nk-tb-item nk-tb-head">
                      <th className="nk-tb-col">
                        <span className="sub-text">Sociedad</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl">
                        <span className="sub-text">Nº Part.</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl">
                        <span className="sub-text">Socios</span>
                      </th>
                      <th className="nk-tb-col tb-col-xxl">
                        <span className="sub-text">Facturación</span>
                      </th>
                      <th className="nk-tb-col tb-col-md">
                        <span className="sub-text">Valor</span>
                      </th>
                      <th className="nk-tb-col tb-col-mb">
                        <span className="sub-text">Fecha Const.</span>
                      </th>
                      <th className="nk-tb-col nk-tb-col-tools text-right" />
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={societies?.length}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    societies.length > 0
      ? administratedTable
      : (
        <div className="nk-content-body">
          <div className="nk-content-wrap">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">
                    Sociedades Administradas
                  </h3>
                  <div className="nk-block-des text-soft">
                    <p>
                      No tienes sociedades administradas.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  );
}

function mapStateToProps({ user }) {
  return { user };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratedList);
