import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { addDocument } from 'redux/actions/documentActions';
import { phantomSharesAction } from 'redux/actions/modalsActions';
import { setModal } from 'redux/actions/modalActions';

import operationTypes from 'constants/operationTypes';

import { INITIAL_DATE } from 'constants/defaultConstants';

import UserDropdown from '../UserDropdown';
import '../Modals.scss';

function PhantomSharesModal({ user, actualSociety, dispatch }) {
  const [step, setStep] = useState(0);

  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);

  const [date, setDate] = useState(INITIAL_DATE);
  const [beneficiary, setBeneficiary] = useState();
  const [totalParticipations, setTotalParticipations] = useState(1);
  const [price, setPrice] = useState(0);
  const [file, setFile] = useState();

  const [sharesDate, setSharesDate] = useState('');
  const [sharesParticipations, setSharesParticipations] = useState('');

  const [lastOperationDate, setLastOperationDate] = useState();

  useEffect(() => {
    if (actualSociety) {
      let lastOperation = actualSociety.operations.reduce(
        (acc, op) => (new Date(acc.date) > new Date(op.date)
          ? acc
          : op),
      );

      // TODO Replace with moment and the proper format
      lastOperation = new Date(lastOperation.date).toISOString().replace(':00.000Z', '');
      setLastOperationDate(lastOperation || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  function nextPartner() {
    const newMovement = {
      vestingDate: sharesDate,
      partner: JSON.parse(beneficiary),
      shareFrom: 1,
      shareTo: sharesParticipations,
    };

    setMovements([...movements, newMovement]);

    setSharesDate('');
    setSharesParticipations('');
    setMissingParticipations(missingParticipations - +sharesParticipations);
  }

  function savePhantomShares(event) {
    event.preventDefault();
    if (missingParticipations - +sharesParticipations > 0) return nextPartner();

    const newMovement = {
      vestingDate: sharesDate,
      partner: JSON.parse(beneficiary),
      shareFrom: 1,
      shareTo: sharesParticipations,
    };

    const finalMovements = [...movements, newMovement];

    const newDocument = {
      file,
      date,
      name: operationTypes.PHANTOM_SHARE_FILENAME,
      author: user['_id'],
      society: actualSociety['_id'],
    };

    dispatch(
      phantomSharesAction({
        movements: finalMovements,
        date,
        sharePremium: price,
        society: actualSociety['_id'],
        partner: user['_id'],
      }),
    );
    dispatch(addDocument(newDocument));
    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Phantom Shares</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {!step ? (
          <form
            className="form-validate is-alter"
            onSubmit={(event) => {
              event.preventDefault();
              setTotalParticipations(+totalParticipations);
              setMissingParticipations(+totalParticipations);
              setStep(1);
            }}
          >
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Fecha de la firma del Plan de Phantom Shares
              </label>
              <div className="form-control-wrap">
                <input
                  type="datetime-local"
                  className="form-control date-picker"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                  required
                  max="2100-01-01"
                  min={lastOperationDate}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="default-01">
                Nombre del beneficiario
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-user" />
                </div>
                <UserDropdown
                  users={actualSociety?.partners}
                  action={setBeneficiary}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Nº participaciones que le corresponden
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Nº</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Nº de nuevas participaciones"
                  value={totalParticipations}
                  onChange={(event) => setTotalParticipations(event.target.value)}
                  min="1"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Precio por participación
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Eur</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Importe prima de emisión por participación"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="default-06">
                Añadir escritura (opcional)
              </label>
              <div className="form-control-wrap">
                <div className="custom-file">
                  <input
                    id="customFile"
                    type="file"
                    accept="application/pdf"
                    className="custom-file-input"
                    onChange={(event) => setFile(event.target.files[0])}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {file?.name || 'Seleccionar documento'}
                  </label>
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-lg btn-primary"
              disabled={!beneficiary}
            >
              Siguiente
            </button>
          </form>
        ) : (
          <form onSubmit={savePhantomShares} className="form-validate is-alter">
            <label className="form-label" htmlFor="default-01">
              Participaciones del plan de phantom shares:
              <span className="badge-primary rounded px-2 py-1 ml-2">
                {missingParticipations}
              </span>
            </label>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Fecha del devengo (vesting)
              </label>
              <div className="form-control-wrap">
                <input
                  type="datetime-local"
                  className="form-control date-picker"
                  value={sharesDate}
                  onChange={(event) => setSharesDate(event.target.value)}
                  required
                  max="2100-01-01"
                  min="1000-01-01"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Nº participaciones
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Nº</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Nº de nuevas participaciones"
                  value={sharesParticipations}
                  onChange={(event) => setSharesParticipations(+event.target.value)}
                  required
                  min="1"
                  max={missingParticipations}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-lg btn-primary">
              {missingParticipations - +sharesParticipations > 0 ? 'Siguiente' : 'Guardar'}
            </button>
          </form>
        )}
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(PhantomSharesModal);
