import axios from 'axios';

import server from 'constants/server';
import types from 'redux/actions/actionTypes';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from './alertActions';

export function setSociety(society) {
  return { type: types.SET_SOCIETY, society };
}

export function reSetSociety(society) {
  return { type: types.UPDATE_SOCIETY, society };
}

export const createSociety = (society) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${server.URL}/societies`, society);

    dispatch({ type: types.CREATE_SOCIETY, society: data });
    dispatch(addAlert(alertBodyTypes.SOCIETY_ADDED));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const updateSociety = (societyId, updateSocietyData) => async (
  dispatch
) => {
  const body = {
    _id: societyId,
    ...updateSocietyData,
  };

  try {
    const { data } = await axios.put(`${server.URL}/societies`, body);

    dispatch(reSetSociety(data));
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export async function getSocietyByCif(cif) {
  try {
    const {
      data: [society],
    } = await axios.get(`${server.URL}/societies/?cif=${cif}`);
    return society ?? { name: '' };
  } catch (error) {
    return { name: '' };
  }
}
