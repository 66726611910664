import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import store from 'redux/store';

import { setModal } from 'redux/actions/modalActions';
import { updateUser } from 'redux/actions/userActions';
import adjustImageSize from '../../utils/images';
import { yearNmonthDay } from '../../utils/formatDate';

import './EditModal.scss';

function EditModal({ user, userId }) {
  const [page, setPage] = useState(0);

  const [firstName, setFirtsName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [birthdate, setBirthdate] = useState(
    user?.birthdate ? yearNmonthDay(user?.birthdate) : '',
  );
  const [image, setImage] = useState(user?.image);
  const [nationality, setNationality] = useState(user?.nationality);

  const [line1, setLine1] = useState(user?.address?.line1 || '');
  const [city, setCity] = useState(user?.address?.city || '');
  const [zip, setZip] = useState(user?.address?.zip || '');
  const [country, setCountry] = useState(user?.address?.country || '');

  async function fileSelected(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function onUpdateUser() {
    const newData = {
      firstName,
      lastName,
      phone,
      birthdate,
      image,
      nationality,
      address: {
        line1,
        city,
        zip,
        country,
      },
    };
    store.dispatch(updateUser(user['_id'], newData, userId));
    store.dispatch(setModal(null));
  }

  useEffect(() => {
    const resizedImage = adjustImageSize('user-canvas', 'user-image', 200);
    setImage(resizedImage);
  }, [image]);

  return (
    <>
      <Modal.Header>
        <h5 className="title">Actualizar datos</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item" onClick={() => setPage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              Información personal
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>Dirección</p>
          </li>
        </ul>
        <div className="tab-content">
          {!page ? (
            <div className={`tab-pane ${page || 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="full-name">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={firstName}
                      onChange={(event) => setFirtsName(event.target.value)}
                      placeholder="Introduce tu nombre"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="display-name"
                    >
                      Apellidos
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="display-name"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                      placeholder="Introduce tus apellidos"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="phone-no">
                      Número de teléfono
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="phone-no"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      placeholder="Introduce tu telefono"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="birth-day">
                      Correo electrónico
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg date-picker"
                      value={user?.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="birth-day">
                      Fecha nacimiento
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-lg date-picker"
                      value={birthdate}
                      onChange={(event) => setBirthdate(event.target.value)}
                      placeholder="Introduce tu fecha de nacimiento"
                      max="2100-01-01"
                      min="1000-01-01"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="full-name">
                      Nacionalidad
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={nationality}
                      onChange={(event) => setNationality(event.target.value)}
                      placeholder="Introduce tu nacionalidad"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-8">
                  <div className="form-group">
                    <label className="form-label" htmlFor="birth-day">
                      Imagen
                    </label>
                    <input
                      type="file"
                      className="form-control-file form-control-lg cursor-pointer"
                      accept="image/*"
                      onChange={fileSelected}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-4">
                  <canvas
                    className="bg-primary"
                    id="user-canvas"
                    width="200"
                    height="200"
                  >
                    {image
                      ? (<img id="user-image" src={image} alt={firstName} />) : (
                        <span>{firstName.slice(0, 2).toUpperCase()}</span>
                      )}
                  </canvas>
                </div>
              </div>
            </div>
          ) : (
            <div className={`tab-pane ${page && 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-l1">
                      Dirección
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-l1"
                      placeholder="Línea 1"
                      value={line1}
                      onChange={(event) => setLine1(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-l2">
                      Ciudad
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-l2"
                      placeholder="Introduce tu ciudad"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-st">
                      Código Postal
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-st"
                      placeholder="Introduce tu código postal"
                      value={zip}
                      onChange={(event) => setZip(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="address-county"
                    >
                      País
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="country-st"
                      placeholder="Introduce tu país"
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-12">
              <p
                className="btn btn-lg btn-primary"
                onClick={onUpdateUser}
              >
                Guardar cambios
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user }) {
  return { userId: user['_id'] };
}

export default connect(mapStateToProps)(EditModal);
