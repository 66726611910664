import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';
import UserMyData from '../../components/UserMyData';
import UserWithAccess from '../../components/UserWithAccess';
import UserMyIdentities from '../../components/UserMyIdentities';
import UserSocietiesRepresented from '../../components/UserSocietiesRepresented';

const tabs = {
  datos: 0,
  identidades: 1,
  sociedades: 2,
  administradores: 3,
};

function UserProfile({ user, actions }) {
  const { activeTab } = useParams();
  const [page, setPage] = useState(tabs[activeTab]);

  const pages = [
    <UserMyData user={user} key="UserMyData" />,
    <UserMyIdentities user={user} key="UserMyIdentities" />,
    <UserSocietiesRepresented user={user} key="UserSocietiesRepresented" />,
    <UserWithAccess user={user} key="UserWithAccess" />,
  ];

  useEffect(() => {
    setPage(tabs[activeTab]);
  }, [activeTab]);

  useEffect(() => {
    actions.setMenuType(menuTypes.MAIN);
  }, [actions]);

  return !user ? (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Mi perfil</h3>
            <div className="nk-block-des">
              <p>
                Desde aquí puedes gestionar tus datos. Si modificas cualquier
                datos se actualiza tu información en todas tus sociedades
                participadas.
              </p>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
              <li className="nav-item current-page">
                <Link
                  className={`nav-link ${page === 0 ? 'active' : ''}`}
                  to="/perfil-usuario/datos"
                  onClick={() => setPage(0)}
                >
                  <em className="icon ni ni-user-fill-c" />
                  <span>Mis datos</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${page === 1 ? 'active' : ''}`}
                  to="/perfil-usuario/identidades"
                  onClick={() => setPage(1)}
                >
                  <em className="icon ni ni-users-fill" />
                  <span>Mis sociedades</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${page === 2 ? 'active' : ''}`}
                  to="/perfil-usuario/sociedades"
                  onClick={() => setPage(2)}
                >
                  <em className="icon ni ni-building-fill" />
                  <span>Sociedades representadas</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${page === 3 ? 'active' : ''}`}
                  to="/perfil-usuario/administradores"
                  onClick={() => setPage(3)}
                >
                  <em className="icon ni ni-lock-alt-fill" />
                  <span>Usuarios con acceso</span>
                </Link>
              </li>
            </ul>
            {pages[page]}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, address }) {
  return { user, address };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
