import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(document = initialState.document, action) {
  switch (action.type) {
    case types.ADD_DOCUMENT:
      return [...document, action.document];

    case types.SET_DOCUMENT:
      return action.document;

    default:
      return document;
  }
}
