import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ParticipatedRow from 'components/SocietyRow';
import Pagination from 'components/Pagination';

import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import './ParticipatedList.scss';

function ParticipatedList({ user, dispatch }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [societies, setSocieties] = useState();

  const [indexOfLastItem] = useState(currentPage * itemsPerPage);
  const [indexOfFirstItem] = useState(indexOfLastItem - itemsPerPage);
  const [rows, setRows] = useState();

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => dispatch(setMenuType(menuTypes.MAIN)), [dispatch]);

  useEffect(() => {
    if (user) {
      const sharedSocieties = user.usersWithAccess.reduce((acc, user) => {
        const { participated } = user.societies;

        const deleteDuplicated = participated.filter((societyA) => acc.some((societyB) => societyA['_id'] !== societyB['_id']));

        return [...acc, ...deleteDuplicated];
      }, [...user.societies.participated]);

      const current = sharedSocieties.slice(indexOfFirstItem, indexOfLastItem);
      const rows = current.map((society) => (
        <ParticipatedRow society={society} userId={user['_id']} key={society.name} />
      ));

      setSocieties(sharedSocieties);
      setRows(rows);
    }
  }, [user, indexOfFirstItem, indexOfLastItem]);

  const participatedTable = (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Sociedades Participadas
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`Tienes ${societies?.length} sociedad(es) participada(s).`}
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  aria-label="link"
                >
                  <em className="icon ni ni-menu-alt-r" />
                </a>
                <div
                  className="toggle-expand-content"
                  data-content="pageMenu"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner p-0">
                <table className="nk-tb-list nk-tb-ulist">
                  <thead>
                    <tr className="nk-tb-item nk-tb-head">
                      <th className="nk-tb-col">
                        <span className="sub-text">Sociedad</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl">
                        <span className="sub-text">Nº Part.</span>
                      </th>
                      <th className="nk-tb-col tb-col-xl">
                        <span className="sub-text">Socios</span>
                      </th>
                      <th className="nk-tb-col tb-col-xxl">
                        <span className="sub-text">Valor</span>
                      </th>
                      <th className="nk-tb-col tb-col-md">
                        <span className="sub-text">Porcentaje</span>
                      </th>
                      <th className="nk-tb-col tb-col-mb">
                        <span className="sub-text">Antiguedad</span>
                      </th>
                      <th
                        className="nk-tb-col nk-tb-col-tools text-right"
                        aria-label="link"
                      />
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={societies?.length}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return societies?.length > 0 ? (
    participatedTable
  ) : (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Sociedades Participadas
              </h3>
              <div className="nk-block-des text-soft">
                <p>No tienes sociedades participadas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(ParticipatedList);
