import React from 'react';

import './UsersArray.scss';

function UsersArray({ users }) {
  let icons = users.slice(0, 3).map(({ id }) => id);

  icons = icons.map((icon) => (
    <div className="user-avatar sm bg-blue" key={`icon-${icon['_id']}`}>
      <img
        src={icon.image}
        alt={`${icon.firstName} ${icon.lastName}`}
        className="user-avatar__picture"
      />
    </div>
  ));

  if (users.length > 3) {
    icons[2] = (
      <div className="user-avatar sm bg-blue" key="extra-user-counter">
        <span>
          +
          {users.length - 2}
        </span>
      </div>
    );
  }

  return <div className="project-users g-1">{icons}</div>;
}

export default UsersArray;
