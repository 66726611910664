export default {
  // User
  SET_USER: 'SET_USER',

  // Society
  CREATE_SOCIETY: 'CREATE_SOCIETY',
  UPDATE_SOCIETY: 'UPDATE_SOCIETY',
  SET_SOCIETY: 'SET_SOCIETY',

  // PartnerBook
  SET_PARTNER_BOOK: 'SET_PARTNER_BOOK',

  // Menu
  SET_MENU_TYPE: 'SET_MENU_TYPE',
  SET_MENU: 'SET_MENU',

  // Sidenav
  TOGGLE_SIDE_NAV: 'TOGGLE_SIDE_NAV',

  // Alerts
  ADD_ALERT: 'ADD_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',

  // Documents
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  SET_DOCUMENT: 'SET_DOCUMENT',

  // Modals
  SET_MODAL: 'SET_MODAL',
};
