import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import SocietyCard from 'components/SocietyCard';

import './ParticipatedTable.scss';

function ParticipatedTable({ user, alternativeMenuOptions, actions }) {
  const [societies, setSocieties] = useState([]);

  useEffect(() => {
    const sharedSocieties = user.usersWithAccess.reduce((acc, user) => {
      const { participated } = user.societies;

      const deleteDuplicated = participated.filter((societyA) => acc.some((societyB) => societyA['_id'] !== societyB['_id']));

      return [...acc, ...deleteDuplicated];
    }, [...user.societies.participated]);
    setSocieties(sharedSocieties);
  }, [user]);

  useEffect(() => actions.setMenuType(menuTypes.MAIN, [actions]));

  const cards = societies.map((society) => {
    const isAdmin = society.administrators.find((society) => society['_id'] === user['_id']);
    return (
      <SocietyCard
        society={society}
        userId={user['_id']}
        isAdmin={!!isAdmin}
        alternativeMenuOptions={alternativeMenuOptions}
        key={society['_id']}
      />
    );
  });

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                Sociedades Participadas
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {societies.length === 0
                    ? 'No tienes sociedades participadas'
                    : `Tienes ${societies.length} sociedad(es) participada(s)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="cuadro-participadas__main d-flex flex-wrap g-gs">
          {cards}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipatedTable);
