import operationTypes from './operationTypes';

export default {
  [operationTypes.ADD_PARTNER]: 'Añadir socio',
  [operationTypes.SELL_PARTICIPATION]: 'Compra / Venta',
  [operationTypes.CONSTITUTION]: 'Constitución',
  [operationTypes.CAPITAL_INCREASE]: 'Ampliación',
  [operationTypes.CAPITAL_DECREASE]: 'Reducción',
  [operationTypes.STOCK_OPTION]: 'Stock Options',
  [operationTypes.PHANTOM_SHARE]: 'Phantom Share',
};
