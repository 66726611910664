import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { constitution } from 'redux/actions/modalsActions';
import { addDocument } from 'redux/actions/documentActions';
import { addAlert } from 'redux/actions/alertActions';
import { setModal } from 'redux/actions/modalActions';

import operationTypes from 'constants/operationTypes';

import { INITIAL_DATE, SELECIONAR_SOCIO } from 'constants/defaultConstants';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import UserDropdown from '../UserDropdown';

import '../Modals.scss';

function ConstitutionModal() {
  const [step, setStep] = useState(0);
  const { user, actualSociety } = useSelector(
    ({ user, society }) => ({ user, actualSociety: society?.actualSociety }),
  );
  const dispatch = useDispatch();

  const [missingParticipations, setMissingParticipations] = useState(0);
  const [movements, setMovements] = useState([]);

  const [actualFrom, setActualFrom] = useState(1);

  const [date, setDate] = useState(INITIAL_DATE);
  const [totalParticipationsToAdd, setTotalParticipationsToAdd] = useState('');
  const [participationsValue, setParticipationsValue] = useState('');
  const [share, setShare] = useState('');
  const [file, setFile] = useState();

  const [selectedPartner, setSelectedPartner] = useState(SELECIONAR_SOCIO);
  const [partnerParticipations, setPartnerParticipations] = useState('');

  function saveConstitution() {
    const finalMovements = [
      ...movements,
      {
        partner: JSON.parse(selectedPartner),
        shareFrom: actualFrom,
        shareTo: actualFrom + +partnerParticipations - 1,
      },
    ];

    const newDocument = {
      file,
      date,
      name: operationTypes.CONSTITUTION_FILENAME,
      author: user['_id'],
      society: actualSociety['_id'],
    };

    dispatch(
      constitution(
        {
          movements: finalMovements,
          date,
          sharePremium: share,
          nominalValue: participationsValue,
          society: actualSociety['_id'],
          partner: user['_id'],
        },
      ),
    );
    dispatch(addDocument(newDocument));
    dispatch(setModal(null));
  }

  function nextPartner(event) {
    event.preventDefault();
    if (selectedPartner === SELECIONAR_SOCIO) dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      if (missingParticipations - +partnerParticipations === 0) return saveConstitution();

      const newMovement = {
        partner: JSON.parse(selectedPartner),
        shareFrom: actualFrom,
        shareTo: actualFrom + +partnerParticipations - 1,
      };

      setMovements([...movements, newMovement]);

      setPartnerParticipations('');
      setActualFrom(actualFrom + +partnerParticipations);
      setSelectedPartner(SELECIONAR_SOCIO);
      setMissingParticipations(missingParticipations - +partnerParticipations);
    }
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Constitución</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {!step ? (
          <form
            className="form-validate is-alter"
            onSubmit={(event) => {
              event.preventDefault();
              setTotalParticipationsToAdd(+totalParticipationsToAdd);
              setParticipationsValue(+participationsValue);
              setShare(+share);
              setMissingParticipations(+totalParticipationsToAdd);
              setStep(1);
            }}
          >
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Fecha constitución
              </label>
              <div className="form-control-wrap">
                <input
                  type="datetime-local"
                  className="form-control date-picker"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                  required
                  max="2100-01-01"
                  min="1000-01-01"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Nº nuevas participaciones
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Nº</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Nº de nuevas participaciones"
                  value={totalParticipationsToAdd}
                  onChange={(event) => setTotalParticipationsToAdd(event.target.value)}
                  min="1"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Valor nominal por participación
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Eur</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Valor nominal de una participación"
                  value={participationsValue}
                  onChange={(event) => setParticipationsValue(event.target.value)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Prima de emisión por participación
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Eur</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Importe prima de emisión por participación"
                  value={share}
                  onChange={(event) => setShare(event.target.value)}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label className="formsetParticipationsSum-label" htmlFor="default-06">
                Añadir escritura (opcional)
              </label>
              <div className="form-control-wrap">
                <div className="custom-file">
                  <input
                    id="customFile"
                    type="file"
                    accept="application/pdf"
                    className="custom-file-input"
                    onChange={(event) => setFile(event.target.files[0])}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {file?.name || 'Seleccionar documento'}
                  </label>
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-lg btn-primary">
              Siguiente
            </button>
          </form>
        ) : (
          <form className="form-validate is-alter" onSubmit={nextPartner}>
            <label className="form-label">
              <span className="bg-warning p-2">
                Total Nuevas Participaciones pendientes de asignar:
              </span>
              <span className="badge-primary rounded px-2 py-1 ml-2">
                {missingParticipations}
              </span>
            </label>
            <hr />
            <div className="form-group">
              <label className="form-label" htmlFor="default-01">
                Nombre Socio
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-user" />
                </div>
                <UserDropdown
                  users={actualSociety.partners}
                  action={setSelectedPartner}
                  user={selectedPartner}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Nº nuevas participaciones
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Nº</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Nº de nuevas participaciones"
                  value={partnerParticipations}
                  onChange={(event) => setPartnerParticipations(event.target.value)}
                  min="1"
                  max={missingParticipations}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                De la número
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Numeración</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Valor nominal de una participación"
                  value={actualFrom}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="pay-amount">
                Hasta la número
              </label>
              <div className="form-control-wrap">
                <div className="form-text-hint">
                  <span className="overline-title">Numeración</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Importe prima de emisión por participación"
                  value={actualFrom + +partnerParticipations - (partnerParticipations ? 1 : 0)}
                  readOnly
                />
              </div>
            </div>
            <button type="submit" className="btn btn-lg btn-primary">
              {missingParticipations - +partnerParticipations ? 'Siguiente' : 'Guardar'}
            </button>
          </form>
        )}
      </Modal.Body>
    </>
  );
}

export default ConstitutionModal;
