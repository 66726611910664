/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import store from 'redux/store';
import { getActualAdmin } from 'utils/filters';

import { dayMonYea } from '../../utils/formatDate';

import EditModal from './modal';

function SocietyProfileData({ user, society, isAdmin, isOpen }) {
  useEffect(() => {
    if (isOpen && isAdmin)
      store.dispatch(setModal(<EditModal society={society} />));
  }, [isOpen, society, user, isAdmin]);

  // TBD
  const [mainAdmin] = useState(getActualAdmin(society, society.mainAdmin));

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Información de la Sociedad</h5>
          <div className="nk-block-des">
            <p>Información básica de la Sociedad.</p>
          </div>
        </div>
      </div>
      <div
        className="card card-bordered"
        onClick={() => {
          if (isAdmin)
            store.dispatch(setModal(<EditModal society={society} />));
        }}
      >
        <div className="nk-data data-list">
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Denominación Social</span>
              <span className="data-value">{society?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">CIF</span>
              <span className="data-value">{society?.cif}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Web</span>
              <span className="data-value">{society?.web || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Teléfono</span>
              <span className="data-value">{society?.phone}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Fecha constitución</span>
              <span className="data-value">
                {society?.creationDate
                  ? dayMonYea(society.creationDate)
                  : 'Sociedad aún no constituida'}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Domicilio Social</span>
              <span className="data-value">
                {society?.legalAddress.line1
                  ? `${society?.legalAddress.zip} ${society?.legalAddress.line1}, ${society?.legalAddress.line2}`
                  : '-'}
                <br />
                {society?.legalAddress &&
                  `${society?.legalAddress.city} ${society?.legalAddress.country}`}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label"> Administrador selecionado </span>
              <span className="data-value">{mainAdmin}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Información adicional</h5>
          <div className="nk-block-des">
            <p>
              Utiliza esta sección para actualizar la información de la
              sociedad.
            </p>
          </div>
        </div>
      </div>
      <div
        className="card card-bordered"
        onClick={() =>
          store.dispatch(setModal(<EditModal society={society} />))
        }
      >
        <div className="nk-data data-list">
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Logo</span>
              <div className="user-avatar md bg-primary">
                {society?.additional.logo ? (
                  <img src={society?.additional.logo} alt={society?.name} />
                ) : (
                  <span>{society?.name.slice(0, 2).toUpperCase()}</span>
                )}
              </div>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Descripción breve</span>
              <span className="data-value">
                {society?.additional.description}
              </span>
            </div>
            <div className="data-col data-col-end">
              <p className="link link-primary"> Editar </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return { user, society: society.actualSociety, isAdmin: society?.isAdmin };
}

export default connect(mapStateToProps)(SocietyProfileData);
