import React, { useState } from 'react';

import { dayMonYea } from 'utils/formatDate';
import { countOperationShares } from 'utils/filters';
import operationTypesRealNames from 'constants/operationTypesRealNames';

function PartnerTransactionsRow({ transaction, index }) {
  const [shares] = useState(countOperationShares(transaction));
  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{operationTypesRealNames[transaction?.operationType]}</td>
      <td>{dayMonYea(transaction?.date)}</td>
      <td>
        { transaction?.sharePremium
          ? `${transaction?.sharePremium * shares}${transaction?.currency}`
          : '-' }
      </td>
      <td>{shares || '-'}</td>
      <td>-</td>
    </tr>
  );
}

export default PartnerTransactionsRow;
