import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(partnerBook = initialState.partnerBook, action) {
  switch (action.type) {
    case types.SET_PARTNER_BOOK:
      return action.partnerBook;

    default:
      return partnerBook;
  }
}
