import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Slider from 'components/Slider';

import '../../assets/styles/Pages.scss';
import '../../assets/styles/Styles.scss';

function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="nk-split nk-split-page nk-split-md">
      <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div className="nk-block nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to="/" className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark.png"
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark.png"
                alt="logo-dark"
              />
            </Link>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">Identifícate</h5>
              <div className="nk-block-des">
                <p>Accede a socio.legal.</p>
              </div>
            </div>
          </div>
          <button
            className="btn btn-lg btn-primary btn-block"
            type="button"
            onClick={loginWithRedirect}
          >
            Entrar
          </button>
          <div className="form-note-s2 pt-4">
            {' '}
            ¿No estás registrado?
            {' '}
            <Link to="/register">Crea una cuenta</Link>
          </div>
        </div>
        <div className="nk-block nk-auth-footer">
          <div className="nk-block-between">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <Link className="nav-link" to="/aviso-legal">
                  Aviso Legal
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/privacidad">
                  Privacidad
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-3">
            <p>© 2021 socio.legal | Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
      <Slider />
    </div>
  );
}

export default Login;
