import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(menu = initialState.modal, action) {
  switch (action.type) {
    case types.SET_MODAL:
      return action.modal;

    default:
      return menu;
  }
}
