import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { connect } from 'react-redux';

import { addDocument } from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety as updateSocietyAction } from 'redux/actions/societyActions';
import store from 'redux/store';

function DocumentModal({ userId, societyId, actualAnnualValues }) {
  const [year, setYear] = useState();
  const [contableValue, setContableValue] = useState(0);
  const [billing, setBilling] = useState(0);
  const [ebitda, setEbitda] = useState(0);
  const [filePyG, setFilePyG] = useState();
  const [fileBalance, setFileBalance] = useState();

  const [years, setYears] = useState([]);

  useEffect(() => {
    const years = [];
    const lastYear = new Date().getFullYear() - 1;
    for (let i = lastYear - 20; i <= lastYear; i += 1) {
      years.push(i);
    }
    const filteredYears = years.filter(
      (year) =>
        !actualAnnualValues.some(
          (annualValue) => annualValue.year === year.toString()
        )
    );
    setYears(filteredYears);
  }, [actualAnnualValues]);

  function saveAnnualValue(event) {
    event.preventDefault();

    const newSocietyData = {
      annualValues: [
        {
          year,
          contableValue,
          billing,
          ebitda,
          modifiedDate: new Date(),
        },
        ...actualAnnualValues,
      ],
    };
    store.dispatch(updateSocietyAction(societyId, newSocietyData));

    const newDocumentPyG = {
      file: filePyG,
      date: new Date(),
      name: `PyG ${year}`,
      author: userId,
      society: societyId,
    };
    store.dispatch(addDocument(newDocumentPyG));

    const newDocumentBalance = {
      file: fileBalance,
      date: new Date(),
      name: `Balance ${year}`,
      author: userId,
      society: societyId,
    };
    store.dispatch(addDocument(newDocumentBalance));
    store.dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir valor anual</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveAnnualValue}>
          <div className="form-group">
            <label className="form-label" htmlFor="year">
              Año
            </label>
            <div className="form-control-wrap">
              <select
                name="year"
                id="year"
                className="form-control date-picker"
                onChange={(event) => setYear(event.target.value)}
                required
              >
                {years.map((year) =>
                  actualAnnualValues.some((data) => data.year === year) ? (
                    <option value={year} key={year} disabled>
                      {year}
                    </option>
                  ) : (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Valor contable (Balance)
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">EUR €</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Indicar el valor contable de la Sociedad (según balance)"
                value={contableValue}
                onChange={(event) => setContableValue(event.target.value)}
                min="1"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Facturación
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">EUR €</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Indicar facturación total"
                value={billing}
                onChange={(event) => setBilling(event.target.value)}
                min="1"
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              EBITDA
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">EUR €</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Indicar EBITDA"
                value={ebitda}
                onChange={(event) => setEbitda(event.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              Añadir PyG (opcional)
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept="application/pdf"
                  className="custom-file-input"
                  onChange={(event) => setFilePyG(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {filePyG?.name || 'Seleccionar documento'}
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              Añadir Balance (opcional)
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept="application/pdf"
                  className="custom-file-input"
                  onChange={(event) => setFileBalance(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {fileBalance?.name || 'Seleccionar documento'}
                </label>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-lg btn-primary">
            Guardar valor anual
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <span className="sub-text">Texto adicional para el Usuario</span>
      </Modal.Footer>
    </>
  );
}

export default connect()(DocumentModal);
