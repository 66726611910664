import { editUser } from 'utils/modals';

export default [
  {
    icon: 'ni-eye',
    text: 'Ver',
    url: '/detalle-socio',
  },
  {
    icon: 'ni-pen',
    text: 'Editar',
    action: editUser,
  },
  {
    icon: 'ni-repeat',
    text: 'Transacciones',
    url: '/transacciones',
  },
  {
    icon: 'ni-file',
    text: 'Documentos',
    url: '/documentos',
  }];
