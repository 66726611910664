/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSVLink } from 'react-csv';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';

import menuTypes from 'constants/menuTypes';
import PartnerRow from 'components/PartnerRow';
import OperationMenuDots from 'components/MenuDots/OperationMenuDots';

import { getActualSociety } from 'utils/filters';
import operationTypes from 'constants/operationTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import menuOptions from './menuOptions';

function PartnerList({ user, actualSociety, isAdmin, actions }) {
  const { societyId } = useParams();

  const [societyShares, setSocietyShares] = useState();

  const [filtredMenuOptions, setFiltredMenuOptions] = useState([]);
  const [rows, setRows] = useState([]);
  const [csvKey, setCsvKey] = useState('');
  const [csvData, setCsvData] = useState([
    ['No', 'Nombre del Socio', 'Email', 'Participaciones', '% ND', '% FD'],
  ]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      const totalShares =
        actualSociety.sharesCount?.actual + actualSociety.sharesCount?.future;

      setSocietyShares(totalShares);

      // TODO Remove filter when partner book is ok
      setRows(
        actualSociety.partners
          .filter((partner) => partner?.sharesCount?.actual > 0)
          .map((partner, index) => (
            <PartnerRow
              partner={partner.id}
              sharesCount={partner.sharesCount}
              societyId={actualSociety['_id']}
              societyShares={totalShares}
              index={index + 1}
              key={partner['_id']}
              csvData={csvData}
              setCsvData={setCsvData}
              setCsvKey={setCsvKey}
            />
          ))
      );

      if (!actualSociety.partners.length) {
        setFiltredMenuOptions([menuOptions[0]]);
      } else if (!actualSociety.shares.length) {
        const isSocietyConstituted = actualSociety.operations.some(
          (operation) => operation.operationType === operationTypes.CONSTITUTION
        );

        const [
          addPartnerOption,
          constitutionOption,
          capitalIncreaseOption,
        ] = menuOptions;

        setFiltredMenuOptions([
          addPartnerOption,
          isSocietyConstituted ? capitalIncreaseOption : constitutionOption,
        ]);
      } else {
        const options = menuOptions.filter(
          (option) =>
            option.text !== operationTypesRealNames[operationTypes.CONSTITUTION]
        );
        setFiltredMenuOptions(options);
      }
    }
  }, [actualSociety, societyShares, csvData]);

  useEffect(() => {
    actions.setMenu({
      type: menuTypes.EXTENDED,
      societyId: actualSociety?.['_id'],
      societyName: actualSociety?.name,
    });
  }, [actions, actualSociety]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Socios</h3>
                <div className="nk-block-des text-soft">
                  <p>{`${actualSociety.name} tiene ${rows.length} socios`}</p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <CSVLink
                          key={csvKey}
                          data={csvData}
                          filename={`${actualSociety.name}.csv`}
                          className="btn btn-white btn-outline-light"
                          aria-label="link"
                        >
                          <em className="icon ni ni-download-cloud" />
                          <span>Descargar</span>
                        </CSVLink>
                      </li>

                      {isAdmin ? (
                        <li>
                          <OperationMenuDots
                            menuOptions={filtredMenuOptions}
                            handleModal={(Modal) =>
                              actions.setModal(
                                <Modal
                                  user={user}
                                  societyId={actualSociety['_id']}
                                />
                              )
                            }
                          />
                        </li>
                      ) : (
                        <> </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {rows.length ? (
            <div className="nk-block">
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div className="card-tools" />
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <a
                              className="btn btn-icon search-toggle toggle-search"
                              data-target="search"
                              aria-label="link"
                            >
                              <em className="icon ni ni-search" />
                            </a>
                          </li>
                          <li className="btn-toolbar-sep" />
                          <li>
                            <div className="toggle-wrap">
                              <a
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                                aria-label="link"
                              >
                                <em className="icon ni ni-menu-right" />
                              </a>
                              <div
                                className="toggle-content"
                                data-content="cardTools"
                              >
                                <ul className="btn-toolbar gx-1">
                                  <li className="toggle-close">
                                    <a
                                      className="btn btn-icon btn-trigger toggle"
                                      data-target="cardTools"
                                      aria-label="link"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                  </li>
                                  <li />
                                  <li>
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-trigger btn-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-label="link"
                                      >
                                        <em className="icon ni ni-setting" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                        <ul className="link-check">
                                          <li>
                                            <span>Mostrar</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">10</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">20</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">50</a>
                                          </li>
                                        </ul>
                                        <ul className="link-check">
                                          <li>
                                            <span>Ordenar</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">
                                              Descendiente
                                            </a>
                                          </li>
                                          <li>
                                            <a aria-label="link">Ascendiente</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="card-search search-wrap"
                      data-search="search"
                    >
                      <div className="card-body">
                        <div className="search-content">
                          <a
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                            aria-label="link"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </a>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Buscar por nombre o email"
                          />
                          <button
                            type="button"
                            className="search-submit btn btn-icon"
                            aria-label="link"
                          >
                            <em className="icon ni ni-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist"
                      data-auto-responsive="false"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Nº</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">Socio</span>
                          </th>
                          <th className="nk-tb-col tb-col-mb">
                            <span className="sub-text">Participaciones</span>
                          </th>
                          <th className="nk-tb-col tb-col-md">
                            <span className="sub-text">% ND</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">% FD</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">Menu</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

function mapStateToProps({ user, society }) {
  return {
    user,
    actualSociety: society?.actualSociety,
    isAdmin: society?.isAdmin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenu, setModal }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerList);
