import { editUser } from 'utils/modals';

export default [
  {
    icon: 'ni-eye',
    text: 'Ver',
    url: '/detalle-socio',
  },
  {
    icon: 'ni-edit',
    text: 'Editar',
    action: editUser,
  },
];
