export default [
  {
    icon: 'ni-info',
    text: 'Ver sociedad',
    url: '/socios',
  },
  {
    icon: 'ni-book-read',
    text: 'Libro de Socios',
    url: '/socios-libro',
  },
];
