import React, { useState } from 'react';
import { connect } from 'react-redux';
import store from 'redux/store';

import sendContactEmail from 'redux/actions/contactActions';

import '../../assets/styles/Bootstrap.scss';
import './Contact.scss';

const contactTypes = {
  GENERAL: 'Una pregunta general',
  INCIDENCE: 'Tengo una incidencia',
};

function Contact({ user }) {
  const [type, setType] = useState();
  const [category, setCategory] = useState();
  const [priority, setPriority] = useState();
  const [details, setDetails] = useState();
  const [message, setMessage] = useState();
  const [file, setFile] = useState();

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleReset = () => {
    setType('');
    setCategory('');
    setPriority('');
    setDetails('');
    setMessage('');
    setFile({});
  };

  function sendEmail(event) {
    event.preventDefault();

    const email = {
      type,
      category,
      priority,
      details,
      message,
      file,
      user: `${user.firstName} ${user.lastName || ''}`.trim(),
      email: user.email,
    };

    store.dispatch(sendContactEmail(email));
    handleReset();
    event.target.reset();
  }

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-head-sub">
            <a className="text-soft">
              <span>Contactar con socio.legal</span>
            </a>
          </div>
          <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">
              ¿En qué le podemos ayudar?
            </h2>
            <div className="nk-block-des">
              <p>
                Mediante el siguiente formulario puede contactar con nosotros
                para cualquier tema
              </p>
            </div>
          </div>
        </div>
        <div className="nk-block mb-3">
          <div className="card card-bordered">
            <div className="card-inner">
              <form className="form-contact is-alter" onSubmit={sendEmail}>

                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="type"
                        id="type-general"
                        value={contactTypes.GENERAL}
                        onChange={(event) => setType(event.target.value)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="type-general"
                      >
                        Una pregunta general
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="type"
                        id="type-problem"
                        value={contactTypes.INCIDENCE}
                        onChange={(event) => setType(event.target.value)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="type-problem"
                      >
                        Tengo una incidencia
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <span>Categoría</span>
                      </label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control form-control-lg w-100"
                          data-search="off"
                          data-ui="lg"
                          onChange={(event) => setCategory(event.target.value)}
                          defaultValue=""
                        >
                          <option hidden value="" className="text-success">Seleccione categoría</option>
                          <option value="General">General</option>
                          <option value="Facturación">Facturación</option>
                          <option value="Técnica">Técnica</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <span>Prioridad</span>
                      </label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control form-control-lg w-100"
                          data-search="off"
                          data-ui="lg"
                          onChange={(event) => setPriority(event.target.value)}
                          defaultValue=""
                        >
                          <option hidden value="">Seleccione prioridad</option>
                          <option value="Normal">Normal</option>
                          <option value="Importante">Importante</option>
                          <option value="Urgente">Urgente</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Detalla tu questión</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Detalla tu questión... "
                          value={details}
                          onChange={(event) => setDetails(event.target.value)}
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">
                        <span>Facilita más información </span>
                        <em className="icon ni ni-info text-gray" />
                      </label>
                      <p className="text-soft">
                        Nos puedes facilitar el máximo de información
                        e intentaremos responderte rápidamente.
                      </p>
                      <div className="form-control-wrap">
                        <div className="form-editor-custom">
                          <textarea
                            className="form-control form-control-lg no-resize"
                            placeholder="Escribe tu mensaje"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            defaultValue=""
                          />
                          <div className="form-editor-action p-2">
                            <em className="icon ni ni-clip fs-18 ml-1" />
                            <input
                              id="customFile"
                              type="file"
                              accept="application/pdf"
                              ref={hiddenFileInput}
                              style={{ display: 'none' }}
                              onChange={handleChange}
                              defaultValue=""
                            />
                            <span className="link collapsed cursor-pointer" onClick={handleClick}>
                              {file?.name || 'Adjuntar un archivo'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="choose-file">
                      <div className="form-group collapse" id="filedown">
                        <div className="support-upload-box">
                          <div className="upload-zone">
                            <div className="dz-message" data-dz-message>
                              <em className="icon ni ni-clip" />
                              <span className="dz-message-text">
                                Adjuntar un archivo
                              </span>
                              <span className="dz-message-or"> or</span>
                              <button className="btn btn-primary" type="button">
                                Select
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className={type && category && priority && details && message ? 'btn btn-primary text-white' : 'btn btn-primary text-white is-disable'}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Contact);
