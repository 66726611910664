import React from 'react';

function SocietySubscription() {
  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Current Subscription</h5>
          <div className="nk-block-des">
            <p>
              Details about your current subscription and billing information.
            </p>
          </div>
        </div>
      </div>
      <div className="card card-bordered">
        <div className="card-inner-group">
          <div className="card-inner">
            <div className="between-center flex-wrap flex-md-nowrap g-3">
              <div className="nk-block-text">
                <h6>
                  Enterpise Plan -
                  {' '}
                  <span className="text-base">$599.00 / Yearly</span>
                </h6>
                <p className="text-soft">
                  Unlimited access with priority support, 99.95% uptime,
                  powerfull features and more...
                </p>
              </div>
              <div className="nk-block-actions flex-shrink-0">
                <p className="btn btn-primary">Change Plan</p>
              </div>
            </div>
          </div>
          <div className="card-inner">
            <div className="between-center flex-wrap flex-md-nowrap g-3">
              <div className="nk-block-text">
                <p>Learn more about our subscription options.</p>
              </div>
              <div className="nk-block-actions">
                <ul className="align-center gx-3">
                  <li>
                    <div className="custom-control custom-control-right custom-switch">
                      <label
                        className="custom-control-label"
                        htmlFor="auto-renewal"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="auto-renewal"
                        />
                        Auto Renewal
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Billing Cycle</h5>
          <div className="nk-block-des">
            <p>
              Your subscription renews on Jan 28, 2020
              {' '}
              <span className="fs-13px text-soft">
                (2 months 17 days remaining).
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="card card-bordered">
        <div className="card-inner-group">
          <div className="card-inner">
            <div className="between-center flex-wrap flex-md-nowrap g-3">
              <div className="nk-block-text">
                <h6>Yearly Subscription</h6>
                <ul className="list-inline list-col2 text-soft">
                  <li>
                    Next payment:
                    {' '}
                    <strong className="text-base">$599.00 USD</strong>
                    {' '}
                    on
                    {' '}
                    <strong className="text-base">Jan 28, 2020</strong>
                  </li>
                  <li>Last payment made: Jan 28, 2019</li>
                </ul>
              </div>
              <div className="nk-block-actions">
                <ul className="align-center gx-3">
                  <li className="order-md-last">
                    <p className="link link-primary">Switch Billing Cycle</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">Manage Liceneses</h5>
        </div>
      </div>
      <div className="card card-bordered">
        <div className="card-inner-group">
          <div className="card-inner">
            <div className="between-center flex-wrap flex-md-nowrap g-3">
              <div className="nk-block-text">
                <h6>20 Licenses</h6>
                <p className="text-soft">
                  Licenssed members have full access to all NioAccount Features.
                </p>
              </div>
              <div className="nk-block-actions">
                <ul className="align-center gx-3">
                  <li className="order-md-last">
                    <p className="link link-primary">Manage License</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietySubscription;
