import React, { useState, useEffect } from 'react';

import { SELECIONAR_SOCIO } from 'constants/defaultConstants';

function UserDropdown({
  users = [],
  action,
  showShares,
  user,
}) {
  const [usersList, setUsersList] = useState();

  useEffect(() => {
    const usersList = users.map((partner) => {
      const value = JSON.stringify({
        id: partner.id['_id'],
        cif: partner.cif,
      });

      return (
        <option
          value={value}
          key={value}
        >
          {partner.cif}
          {' | '}
          {partner.id.email}
        </option>
      );
    });

    setUsersList(usersList);
  }, [users, showShares]);

  return (
    <select
      className="form-control"
      onChange={(event) => action(event.target.value)}
      required
      value={user}
    >
      <option>
        {SELECIONAR_SOCIO}
      </option>
      {usersList}
    </select>
  );
}

export default UserDropdown;
