/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setModal } from 'redux/actions/modalActions';
import { sellParticipationsAction } from 'redux/actions/modalsActions';
import { addDocument } from 'redux/actions/documentActions';
import { addAlert } from 'redux/actions/alertActions';

import operationTypes from 'constants/operationTypes';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

import { INITIAL_DATE } from 'constants/defaultConstants';

import UserDropdown from '../UserDropdown';
import '../Modals.scss';

const DECREMENT = 'DECREMENT';

function SellParticipationsModal({ user, actualSociety, dispatch }) {
  const [date, setDate] = useState(INITIAL_DATE);
  const [sellPartner, setSellPartner] = useState();
  const [buyPartner, setBuyPartner] = useState();
  const [participationsToSell, setParticipationsToSell] = useState();
  const [participationPrice, setParticipationPrice] = useState();
  const [file, setFile] = useState('');

  const [lastOperationDate, setLastOperationDate] = useState();

  useEffect(() => {
    if (actualSociety) {
      let lastOperation = actualSociety.operations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );

      // TODO Replace with moment and the proper format
      lastOperation = new Date(lastOperation.date)
        .toISOString()
        .replace(':00.000Z', '');
      setLastOperationDate(lastOperation || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  function saveSell(event) {
    event.preventDefault();
    if (!sellPartner || !buyPartner)
      dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else if (sellPartner === buyPartner)
      dispatch(addAlert(alertBodyTypes['SAME_BUY_AND_SELL_USER']));
    else {
      const newDocument = {
        file,
        date,
        name: operationTypes.SELL_PARTICIPATION_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
      };

      dispatch(
        sellParticipationsAction({
          date,
          society: actualSociety['_id'],
          partner: user['_id'],
          sharePrice: participationPrice,
          movements: [
            {
              partner: JSON.parse(sellPartner),
              shareFrom: 1,
              shareTo: +participationsToSell,
              movementType: DECREMENT,
            },
            {
              partner: JSON.parse(buyPartner),
              shareFrom: 1,
              shareTo: +participationsToSell,
            },
          ],
        })
      );
      dispatch(addDocument(newDocument));
      dispatch(setModal(null));
    }
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Venta de participaciones</h5>
        <a className="close" onClick={() => dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveSell}>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              Fecha transmisicón
            </label>
            <div className="form-control-wrap">
              <input
                type="datetime-local"
                className="form-control date-picker"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                required
                max="2100-01-01"
                min={lastOperationDate}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              Socio que vende
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-user" />
              </div>
              <UserDropdown
                users={actualSociety?.partners}
                action={setSellPartner}
                value={sellPartner}
                showShares
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              Nº participaciones que vende
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">Nº</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Nº de nuevas participaciones"
                value={participationsToSell}
                onChange={(event) =>
                  setParticipationsToSell(event.target.value)
                }
                min="1"
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              Precio por participación
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">€</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Precio por participación"
                value={participationPrice}
                onChange={(event) => setParticipationPrice(event.target.value)}
                min="0.00"
                step="0.01"
                precision={2}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              Socio que compra
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-user" />
              </div>
              <UserDropdown
                users={actualSociety?.partners}
                action={setBuyPartner}
                value={buyPartner}
                showShares
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              Añadir escritura (opcional)
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept="application/pdf"
                  className="custom-file-input"
                  onChange={(event) => setFile(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file?.name || 'Seleccionar documento'}
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!sellPartner || !buyPartner}
          >
            Guardar
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(SellParticipationsModal);
