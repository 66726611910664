/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import SocietiesRepresentedRow from './SocietiesRepresentedRow';

const CIF_REGEX = /^([ab]|[AB])[0-9]{8}/;

function UserSocietiesRepresented({ user }) {
  const [rows, setRows] = useState([]);
  function getIdentitiesFromSociety(societies) {
    const newIdentities = [];
    societies.forEach((society) => {
      society.partners.map((partner) => {
        const partnerCIF = partner?.cif?.toUpperCase();
        if (partner.id.email === user.email && partnerCIF?.match(CIF_REGEX)) {
          newIdentities.push({
            partner,
            society: {
              _id: society['_id'],
              name: society.name,
              logo: society.additional.logo,
            },
            isAdmin: false,
          });
        }
        return newIdentities;
      });
      society.administrators.map((administrator) => {
        const administratorCIF = administrator?.cif?.toUpperCase();
        if (
          administrator.email === user.email &&
          administratorCIF?.match(CIF_REGEX)
        ) {
          newIdentities.push({
            administrator,
            society: {
              _id: society['_id'],
              name: society.name,
              logo: society.additional.logo,
            },
            isAdmin: true,
          });
        }
        return newIdentities;
      });
    });
    return newIdentities;
  }

  useEffect(() => {
    let identities = [];
    identities = [
      ...getIdentitiesFromSociety(user.societies.participated),
      ...getIdentitiesFromSociety(user.societies.administrated),
    ];

    const identitiesFiltered = identities.filter(
      (v, i, a) =>
        a.findIndex(
          (t) =>
            (t.partner?._id === v.partner?._id &&
              t.society?._id === v.society?._id) ||
            (t.administrator?._id === v.administrator?._id &&
              t.society?._id === v.society?._id)
        ) === i
    );

    setRows(
      identitiesFiltered?.map((identity) => (
        <SocietiesRepresentedRow
          user={identity}
          key={JSON.stringify(identity)}
        />
      ))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.societies.participated, user.societies.administrated]);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">Sociedades representadas</h4>
          <div className="nk-block-des">
            <p>
              Listado Sociedades que tú representas. Son sociedades que tú
              rpresentas, puedes ver las Sociedades en las q la Sociedad que
              representas es socio.
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        {rows ? (
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="nk-tb-col">
                    <span>
                      <span>Denominación Social</span>
                    </span>
                  </th>
                  <th className="nk-tb-col">
                    <span>
                      <span>NIF/CIF</span>
                    </span>
                  </th>
                  <th className="nk-tb-col">
                    <span>
                      <span>Nombre y Apellidos</span>
                    </span>
                  </th>
                  <th className="nk-tb-col">
                    <span>Email</span>
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default UserSocietiesRepresented;
