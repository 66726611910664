import axios from 'axios';

import server from 'constants/server';
import types from 'redux/actions/actionTypes';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from './alertActions';

function setUser(user) {
  return { type: types.SET_USER, user };
}

export const getUser = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${server.URL}/users/${id}`);
    dispatch(setUser(data));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const findUser = (email) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${server.URL}/users`, { email });
    localStorage.setItem('token', data['_id']);
    dispatch(setUser(data));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const updateUser = (userId, updateUserData, loggedUser) => async (dispatch) => {
  const body = {
    _id: userId,
    ...updateUserData,
    loggedUser: loggedUser || userId,
  };
  try {
    const { data } = await axios.put(`${server.URL}/users`, body);
    dispatch(setUser(data));
    dispatch(addAlert(alertBodyTypes.USER_UPDATED));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_USER));
  }
};

export const fetchIdentities = (email) => axios.post(`${server.URL}/identities`, { email });
