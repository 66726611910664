/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setMenuType } from 'redux/actions/menuActions';

function PolicyPrivacy({ actions }) {
  useEffect(() => actions.setMenuType(null), [actions]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-lg wide-sm">
          <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">Política de Privacidad y cookies</h2>
            <div className="nk-block-des">
              <p className="lead">
                Servicio sujeto al REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales
                y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos &quot;RGPD&quot;)

              </p>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner card-inner-xl">
              <div className="entry">
                <h3>Política de Privacidad</h3>
                <p><strong>1. El Responsable del tratamiento </strong></p>

                <p>
                  El Responsable del tratamiento de los datos recabados por medio de este Sitio Web es: Legal.Partner CB (en adelante, “legal.partner”) con domicilio en calle Pere Calders i Rossinyol, 16 Vilassar de Dalt (Barcelona),
                  correo electrónico de contacto
                  {' '}
                  <a href="mailto:info@legal.partner" alt="info@legal.partner" title="info@legal.partner">info@legal.partner</a>
                </p>

                <p>&nbsp;</p>

                <p><strong>2. Finalidades del tratamiento</strong></p>

                <p>Los datos personales facilitados a legal.partner se utilizarán para:</p>

                <ul>
                  <li>
                    En los casos de contratación de servicios o compra de productos, posibilitar el mantenimiento, desarrollo y gestión de la
                    relación negocial formalizada por la contratación de productos y/o servicios a través de este Sitio Web. Los datos tratados
                    con esta finalidad se conservarán mientras se mantenga dicha relación negocial y, una vez finalizada ésta,
                    durante los plazos de conservación y de prescripción de responsabilidades legalmente previstos. La base jurídica para
                    llevar a cabo este tratamiento de datos es la ejecución de las obligaciones contractuales asumidas por el usuario y
                    legal.partner en el Sitio Web. Para el caso de solicitudes efectuadas por un usuario que no mantenga una relación contractual
                    con legal.partner, la base jurídica es el consentimiento del usuario implícito en su solicitud a los efectos de que legal.partner pueda atenderla.
                  </li>
                  <br />
                  <li>
                    En los supuestos de solicitud de información o contacto, atender las solicitudes de información y/o consultas efectuadas por el Usuario.
                    Los datos tratados con esta finalidad se conservarán hasta resolver la solicitud efectuada y una vez finalizada ésta,
                    durante los plazos de conservación y de prescripción de responsabilidades legalmente previstos. La base jurídica para
                    llevar a cabo este tratamiento de datos es el consentimiento del Usuario.
                  </li>
                  <br />
                  <li>
                    Mantener informado al Usuario, incluso por medios electrónicos, acerca de los productos, servicios y novedades de legal.partner.
                    Los datos tratados con esta finalidad se conservarán hasta el momento en que el Usuario retire su consentimiento.
                    El Usuario podrá en todo momento indicar expresamente si autoriza o no dicho tratamiento de sus datos.
                    La base jurídica para llevar a cabo este tratamiento de datos es el consentimiento del Usuario
                  </li>
                  <br />
                  <li>
                    En caso de envío de currículum mediante este Sitio Web los datos facilitados por el Usuario se tratarán para atender su solicitud
                    de empleo y, en su caso, permitirle optar a un puesto de trabajo en legal.partner. Los datos tratados con esta finalidad se conservarán
                    hasta que el Usuario revoque su consentimiento o hasta pasados 24 meses desde su recepción. La base jurídica para llevar a cabo este
                    tratamiento de datos es el consentimiento del Usuario.
                  </li>
                </ul>

                <p>&nbsp;</p>

                <p><strong>3. Destinatarios y categorías de Encargados del tratamiento</strong></p>

                <p>
                  Los datos podrán comunicarse a Registros Públicos, para la ejecución del contrato; Administraciones Públicas para el cumplimiento de obligaciones
                  legales; Entidades bancarias para la gestión de cobros y pagos de facturas y a Encargados del tratamiento debidamente seleccionados. legal.partner
                  podrá transferir los datos de carácter personal a Encargados ubicados en Estados Unidos de América adheridos al Privacy Shield, que cuenta con una
                  decisión de adecuación de la Comisión Europea (Decisión de ejecución (UE) 2016/1250 de la Comisión).

                </p>

                <p>&nbsp;</p>

                <p><strong>4. Ejercicio de derechos del interesado</strong></p>
                <p>
                  La presente política de privacidad propiedad de legal.partner informa al interesado que podrá ejercer en cualquier momento los derechos de acceso,
                  rectificación, supresión, limitación, oposición o portabilidad mediante correo electrónico dirigido a info@legal.partner o bien mediante escrito dirigido
                  a Legal.Partner CB c/ Pere Calders i Rossinyol, 16 Vilassar de Dalt (Barcelona). En los tratamientos cuya legitimación se base en el consentimiento, el Usuario tiene el derecho a
                  retirar dicho consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                  El interesado tendrá derecho a presentar reclamación ante la autoridad de control. Le informamos de que no facilitar la información solicitada puede
                  implicar la imposibilidad de formalizar o dar cumplimiento al objeto del contrato.

                </p>

              </div>

              <p>&nbsp;</p>

              <h3>Cookies</h3>

              <div className="avisoLegal w100p">

                <p>
                  Este Sitio Web utiliza cookies propias y de terceros con la finalidad de mejorar los servicios prestados a través del mismo mediante el análisis
                  de los hábitos de navegación, publicidad para gestionar la publicidad en este Sitio Web y para ofrecerte publicidad comportamental.

                </p>
                <p>
                  Al aceptar el uso de cookies el usuario manifiesta su aceptación a la instalación de las mismas en el ordenador usado (incluyendo,
                  los dispositivos móviles y los navegadores) por éste para acceder y navegar a través del mismo así como a la recogida y el tratamiento de sus
                  datos de carácter personal de la manera y finalidades descritas en la Política de privacidad y de cookies de legal.partner.

                </p>

                <p><strong>¿Qué es una cookie?</strong></p>

                <p>
                  Una cookie es un fichero que se descarga en el ordenador del Usuario cuando accede a determinados sitios web, como por ejemplo, éste.
                  Las cookies permiten a dichos sitios web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación del Usuario
                  o los de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al Usuario.

                </p>

                <p><strong>¿Qué tipos de cookies utiliza este Sitio Web?</strong></p>

                <p>
                  Cookies de análisis: Son aquéllas que, bien tratadas por legal.partner o por terceros, permiten cuantificar el número de usuarios
                  y así realizar la medición y análisis estadístico de la utilización que éstos hacen del Sitio Web. Para ello se analiza su navegación
                  en el Sitio Web con el fin de mejorar la oferta de contenidos, productos o servicios que se muestran en el mismo.

                </p>

                <p>
                  Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso,
                  el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el
                  contenido editado o la frecuencia en la que se muestran los anuncios.

                </p>

                <p>
                  Cookies de publicidad comportamental: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que,
                  en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado.
                  Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación,
                  lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.

                </p>

                <p><strong>Cookies utilizadas:</strong></p>

                <ul>
                  <li>
                    Google Inc.: Google Analytics, herramienta de analítica ofrecida por el tercero Google Inc., sito en Estados Unidos de América.
                    Google Analytics utiliza cookies propias para notificar las interacciones de los usuarios en el Sitio Web, almacenando información de
                    identificación no personal.
                  </li>
                  <li>
                    Google Inc.: Doubleclick. DoubleClick usa las cookies para mejorar la publicidad. Entre otros fines, suelen utilizarse para segmentarla
                    según el contenido que sea relevante para los usuarios, mejorar los informes de rendimiento de las campañas y evitar mostrar anuncios
                    que los usuarios ya hayan visto.
                  </li>
                  <li>
                    Microsoft Inc.: Bing. Para comercializa de forma más eficaz producto o servicios,
                    para registrar la eficacia de publicidad y para registrar la finalización de tu transacción.
                  </li>
                  <li>legal.partner: Cookies de sesión de usuario y para gestión de la contratación de productos y servicios.</li>
                </ul>

                <p><strong>¿Cómo puedo bloquear o eliminar las cookies que utiliza este sitio web?</strong></p>

                <p>
                  El Usuario puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador
                  instalado en su ordenador.

                </p>

                <p>
                  Si el Usuario no desea que sus datos se recopilen con Google Analytics, puede instalar un complemento de inhabilitación para navegadores. Más información
                  {' '}
                  <a href="https://support.google.com/analytics/answer/181881?hl=es&amp;ref_topic=2919631">pinche aquí</a>
                  .
                </p>

                <p>El hecho de bloquear la instalación de las cookies de análisis descritas en esta política no impide la efectiva utilización del Sitio Web por parte del Usuario.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PolicyPrivacy);
